<template>
	<div class="grid" v-show="view_groups">
		<div class="col-12">
			<div class="card">
				<Toast/>
				<Toolbar class="mb-4">
					<template v-slot:start>
						<div class="my-2">
							<Button label="Novo" icon="pi pi-plus" class="p-button-success mr-2" @click="openNew" />
							<Button label="Apagar" icon="pi pi-trash" class="p-button-danger" @click="deleteGroupsDialog = true" :disabled="!selectedGroups || !selectedGroups.length" />
						</div>
					</template>

					<!-- <template v-slot:end>
						<FileUpload mode="basic" accept="image/*" :maxFileSize="1000000" label="Import" chooseLabel="Import" class="mr-2 inline-block" />
						<Button label="Export" icon="pi pi-upload" class="p-button-help" @click="exportCSV($event)"  />
					</template> -->
				</Toolbar>
				<div class="col-12" v-if="loading === true">
					<h4>Carregando Grupos... Aguarde!</h4>
					<lottie-animation
						:path="'animations/load_workspace.json'"
						:loop="true"
						:autoPlay="true"
						:speed="1"
						:width="wid"
						:height="hei"
					/>
				</div>
				<DataTable v-else ref="dt" :value="groups" v-model:selection="selectedGroups" dataKey="id" :paginator="true" :rows="10" :filters="filters" :rowHover="true" class="p-datatable-gridlines" :loading="loading"
							paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" :rowsPerPageOptions="[5,10,25]"
							currentPageReportTemplate="Mostrando {first} de {last} no total de {totalRecords} Permissões" responsiveLayout="scroll">
					<template #header>
						<div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
							<h5 class="m-0">Gerenciar Grupos</h5>
							<span class="block mt-2 md:mt-0 p-input-icon-left">
                                <i class="pi pi-search" />
                                <InputText v-model="filters['global'].value" placeholder="Search..." />
                            </span>
						</div>
					</template>
					<template #empty>
                        Não existe Grupos Cadastrados.
                    </template>
					<Column selectionMode="multiple" headerStyle="width: 3rem"></Column>
					<Column field="name" header="Nome" :sortable="true" headerStyle="width:25%; min-width:20rem;">
						<template #body="slotProps">
							<span class="p-column-title">Name</span>
							{{slotProps.data.name}}
						</template>
					</Column>
					<Column field="active" header="Status" :sortable="true" headerStyle="width:25%; min-width:20rem;">
						<template #body="slotProps">
							<span class="p-column-title">Active</span>
							<Tag v-if="slotProps.data.active === 1" class="mr-2" icon="pi pi-check" severity="success" value="Ativo"></Tag>
							<Tag v-else class="mr-2" icon="pi pi-times" severity="danger" value="Inativo"></Tag>
						</template>
					</Column>
					<Column field="createdAt" header="Criado em" :sortable="true" headerStyle="width:25%; min-width:20rem;">
						<template #body="slotProps">
							<span class="p-column-title">createdAt</span>
							{{slotProps.data.createdAt}}
						</template>
					</Column>
					<Column>
						<template #body="slotProps">
							<Button icon="pi pi-pencil" class="p-button-rounded p-button-success mr-2" @click="editGroup(slotProps.data)" />
							<Button icon="pi pi-trash" class="p-button-rounded p-button-warning mt-2" @click="confirmDeleteGroup(slotProps.data)" />
						</template>
					</Column>
				</DataTable>
				<Dialog v-model:visible="groupDialog" :style="{width: '750px'}" header="Nova Permissão" :modal="true" class="p-fluid">
					<div class="field">
						<label for="name">Nome da Permissão</label>
						<InputText id="name" v-model.trim="group.name" required="true" autofocus :class="{'p-invalid': submitted && !group.name}" />
					</div>
					<div class="field">
					<label for="active">Permissão Ativa?</label>
					</div>
					<div class="field">
					<InputSwitch v-model="switchValue" />
					</div>
				<div class="card">
				<h5>Tipos de Acesso dessa Permissão</h5>
					<PickList v-model="picklistValue" listStyle="height:250px" dataKey="role"> 
						<template #sourceheader>
							From
						</template>
						<template #targetheader>
							To
						</template>
						<template #item="slotProps">
							<div>{{slotProps.item.name}}</div>
						</template>
					</PickList>

					<!-- <li
						v-for="role in listroles"
						:key="role.id"
						:value="role"
					>
						<Checkbox name="option" :value="role.id" v-model="checkboxValue" />
						<label for="option">{{ role.name }} </label>
					</li> -->

				</div>
					<template #footer>
						<Button label="Cancelar" icon="pi pi-times" class="p-button-text" @click="hideDialog"/>
						<Button label="Salvar" icon="pi pi-check" class="p-button-text" @click="saveGroup" />
					</template>
				</Dialog>
				<Dialog v-model:visible="groupCreateDialog" :style="{width: '750px'}" header="Nova Permissão" :modal="true" class="p-fluid">
					<div class="field">
						<label for="name">Nome da Permissão</label>
						<InputText id="name" v-model.trim="group.name" required="true" autofocus :class="{'p-invalid': submitted && !gorup.name}" />
					</div>
					<div class="field">
					<label for="active">Permissão Ativa?</label>
					</div>
					<div class="field">
					<InputSwitch v-model="switchValue" />
					</div>
					<template #footer>
						<Button label="Cancelar" icon="pi pi-times" class="p-button-text" @click="hideCreateDialog"/>
						<Button label="Salvar" icon="pi pi-check" class="p-button-text" @click="createGroup" />
					</template>
				</Dialog>
				<Dialog v-model:visible="deleteGroupDialog" :style="{width: '450px'}" header="Confirmação" :modal="true">
					<div class="flex align-items-center justify-content-center">
						<i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
						<span v-if="group">Tem certeza de que deseja excluir a Permissão <b>{{group.name}}</b>?</span>
					</div>
					<template #footer>
						<Button label="Não" icon="pi pi-times" class="p-button-text" @click="deleteGroupDialog = false"/>
						<Button label="Sim" icon="pi pi-check" class="p-button-text" @click="deleteGroup" />
					</template>
				</Dialog>
				<Dialog v-model:visible="deleteGroupsDialog" :style="{width: '450px'}" header="Confirmação" :modal="true">
					<div class="flex align-items-center justify-content-center">
						<i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
						<span v-if="group">Tem certeza de que deseja excluir as Permissões selecionadas?</span>
					</div>
					<template #footer>
						<Button label="Não" icon="pi pi-times" class="p-button-text" @click="deleteGroupsDialog = false"/>
						<Button label="Sim" icon="pi pi-check" class="p-button-text" @click="deleteSelectedGroups" />
					</template>
				</Dialog>
			</div>
		</div>
	</div>
</template>

<script>
import {FilterMatchMode} from 'primevue/api';
import {api} from '../../service/services'
import router from '../../router';
import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue";
export default {
    name: 'Groups',
	components: {
      LottieAnimation
  },
	data() {
		return {
			groups: [],
			groupDialog: false,
			deleteGroupDialog: false,
			deleteGroupsDialog: false,
			group: {},
			listroles:[],
			selectedGroups: null,
			groupCreateDialog: false,
			filters: {},
			submitted: false,
			switchValue: false,
			view_groups: false,
			picklistValue: [[],[]],
			loading: false,
			wid: 500,
			hei: 600
		}
	},
	groupService: null,
	async created() {
		this.layoutCelular()
		await this.initFilters()
		this.loading = true
		await this.busca();		
		await this.getRoles();
		this.loading = false
		
	},
	mounted() {
		this.getGroup().then(data => this.products = data);
	},
	methods: {
		busca(){
			const response = JSON.parse(localStorage.getItem("pra"))
			const data = response.filter(
				(arry) => "view_group".includes(arry)
			)
			if (data.length > 0){
				this.view_groups = true
			} else {
				router.push("/error");
			}
		},
		layoutCelular(){
			var largura = window.innerWidth
				if(largura < 750) {
				this.wid = 300
				this.hei = 600
			}
		},
		editGroup(group) {
			this.group = {...group};
			this.groupDialog = true
			this.submitted = true
			let roles_id = []
			if(this.group.active === 1){
				this.switchValue = true
			} 						
			this.group.GroupsRoles.forEach((role) => {
				roles_id.push(role.role);
				});
			this.picklistValue[1] = roles_id
		},

		confirmDeleteGroup(group) {
			this.submitted = true
			this.group = group;
			this.deleteGroupDialog = true;
		},
		openNew() {
			this.group = {};
			this.submitted = true;
			this.groupCreateDialog = true;
			this.switchValue = true
		},
		hideCreateDialog() {
			this.groupCreateDialog = false;
			this.submitted = false;
			this.switchValue = false
			this.loading = false
		},
		hideDialog() {
			this.groupDialog = false;
			this.submitted = false;
			this.loading = false
			this.group = {}
			this.gourps = []
			this.getGroup()
			this.switchValue = false
		},
		initFilters() {
            this.filters = {
                'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
            }
        },
		async getGroup(){
			this.loading = true
			const response = await api.get("groups");
			const data = await response.data;
			this.groups = data
			this.loading = false
		},
		async getRoles(){
			const response = await api.get("roles");
			const data = await response.data;
			this.listroles = data
			this.picklistValue = [data,[]]
		},

		async createGroup() {
			this.loading = true
			try{
				if(this.group.name !== ""){
					if(this.switchValue === true) {
						this.group.active = 1
					} else {
						this.group.active = 0
					}
				await api.post("groups", {
						name: this.group.name,
						active: Number(this.group.active)
				})
				this.groupCreateDialog = false;
				this.hideDialog()
				this.$toast.add({severity:'success', summary: 'Sucesso', detail: 'Permissão Criada com Sucesso', life: 3000});
				}
			} catch (err) {
				this.groupCreateDialog = false;
				this.hideDialog()
				this.$toast.add({severity:'error', summary: 'Erro', detail: 'Não foi possível criar a Permissão ' + err, life: 3000});
			}
		},


		async saveGroup() {
			this.submitted = true;
			this.loading = true
			try{
				let roles_id = [];
				let id = this.group.id
				this.group.GroupsRoles.forEach((role) => {
					roles_id.push(role.role);
					});
				
				const equals = this.picklistValue[1].filter(
						(arr1Item) => !roles_id.includes(arr1Item));
				if (equals.length > 0){
					equals.forEach(async (add) =>{
							await api.post("groups/pr", {
								id_group: id,
								id_role: add.id
							});	
					})
				}
				const diff = roles_id.filter(
					(arr1Item) => !this.picklistValue[1].includes(arr1Item));
				if (diff.length > 0){
					diff.forEach(async (remove) =>{
						const dirole = this.group.GroupsRoles.filter(
						(item) => item.id_group === id && item.id_role === remove.id
						)
						await api.delete("groups/pr/delete/" + dirole[0].id);
					})				
				}
				await api.put("groups/" + id, this.group)
				this.groupDialog = false;
				this.hideDialog()
				this.$toast.add({severity:'success', summary: 'Sucesso', detail: 'Permissão Atualizada com Sucesso', life: 3000});
			}catch (err) {
				this.groupDialog = false;
				this.hideDialog()
				this.$toast.add({severity:'error', summary: 'Erro', detail: 'Não foi Possível Atualizar a Permissão ' + err, life: 3000});
			}
	},

		async deleteGroup() {
			this.deleteGroupDialog = false;
			this.loading = true
			try {
				await api.delete("groups/" + this.group.id)
				this.hideDialog()
				this.$toast.add({severity:'success', summary: 'Sucesso', detail: 'Permissão Apagada', life: 3000});
			}catch (err) {
				this.hideDialog()
				this.$toast.add({severity:'error', summary: 'Erro', detail: 'Permissão não Apagada', life: 3000});
			}
		},
	}

}
</script>