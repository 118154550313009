<template>
    <div class="absolute flex flex-row -mt-50 py-2">
        <Button v-tooltip="'Menu do Relatório'" :icon="icon" class="p-button-rounded p-button-success"
            @click="toggle(menu)" />
        <div class="flex flex-column bg-white overlay_menu mt-2 ml-2" v-if="menu">
            <Button label="Tela Cheia" icon="pi pi-window-maximize"
                class="p-button-rounded p-button-success p-button-text" v-on:click="fullScreen" />
            <Button label="Imprimir" icon="pi pi-print" class="p-button-rounded p-button-success p-button-text"
                v-on:click="reportPrint" />
            <Button label="Atualizar" icon="pi pi-refresh" class="p-button-rounded p-button-success p-button-text"
                v-on:click="reportRefresh" />
        </div>
    </div>
    <main class="flex flex-1">
        <aside v-if="asideActive === true" class="w-4rem mt-7 justify-content-center">
            <div class="flex py-2 " v-for="data in reportsSelected" :key="data.report.id">
                <Button v-if="idSelected === data.report.id" icon="pi pi-chart-bar"
                    class="p-button-raised p-button-secondary mr-2 mb-2" v-tooltip="data.report.namemenu" />
                <Button v-else icon="pi pi-chart-bar" class="mr-2 mb-2" v-tooltip="data.report.namemenu"
                    @click="getReport(data.report)" />
            </div>
            <div class="flex m-0 p-0">
                <Button icon="pi pi-cog" class="p-button-rounded p-button-success mr-2 mb-2"
                    v-tooltip="'Editar Relatórios'" @click="reportDialog" />
            </div>
        </aside>

        <div class="flex-1 min-h-screen border-left-2" id="reportContainer"></div>
    </main>
    <Dialog v-model:visible="addReport" :style="{ width: '80vw' }" :closable="false" header="Relatórios Disponíveis"
        :modal="true">
        <DialogReportHome :reports="allReports" @newReport="pushReport" />
        <template #footer>
            <Button label="Fechar" icon="pi pi-times" class="p-button-text" @click="hideDialog" />
        </template>
    </Dialog>
</template>
<script>
import { api } from '../../service/services';
import * as pbi from "powerbi-client";
import * as pbimodels from "powerbi-models";
import DialogReportHome from '../Embeded/DialogReportHome.vue'
export default {
    name: 'ReportInHome',
    components: {
        DialogReportHome
    },
    data() {
        return {
            config: {
                viewMode: "view",
                filters: false,
                nav: false,
            },
            report: {},
            filters: [],
            idSelected: "",
            addReport: false,
            reportsSelected: [],
            menu: false,
            icon: "pi pi-bars"
        }
    },
    props: {
        reports: Array,
        allReports: Array,
        celular: Boolean,
        asideActive: Boolean,
    },
    async created() {
        this.reportsSelected = this.reports
        await this.getReport(this.reportsSelected[0].report)
    },
    methods: {
        async getFilters() {
            const response = await api.get("filters")
            const data = response.data
            const dataUser = data.filter(
                (item) => item.id_user === window.localStorage.getItem("id_ri")
            )
            this.filters = dataUser
        },
        async getReport(report) {
            var models = pbimodels;
            var config = {}
            await this.getFilters();
            const filters = [];
            this.filters.map((filter) => {
                const condicao = []
                filter.BodyFilter.forEach((i) => {
                    if (i.format === "Numeric") {
                        i.values = parseInt(i.values)
                    }
                    condicao.push({
                        operator: i.operator,
                        value: i.value
                    })
                })
                filters.push({
                    $schema: "http://powerbi.com/product/schema#advanced",
                    target: {
                        table: filter.table,
                        column: filter.column,
                    },
                    logicalOperator: filter.operator,
                    conditions: condicao,
                    filterType: models.FilterType.Advanced,
                });
            });

            const token = await api.get("token");
            const tmj = token.data

            if (report.nav === 1) {
                this.config.nav = true
            } else {
                this.config.nav = false
            }

            if (report.filters === 1) {
                this.config.filters = true
            } else {
                this.config.filters = false
            }

            config = {
                type: "report",
                id: report.id,
                embedUrl: "https://app.powerbi.com/reportEmbed?reportId=" + report.id,
                accessToken: tmj,
                tokenType: models.TokenType.Aad,
                permissions: models.Permissions.All,
                viewMode: 0,
                settings: {
                    filterPaneEnabled: this.config.filters,
                    navContentPaneEnabled: this.config.nav,
                    bars: {
                        actionBar: {
                            visible: false
                        }
                    },
                    panes: {
                        bookmarks: {
                            visible: false
                        }
                    },
                    layoutType: models.LayoutType.Custom,
                    customLayout: {
                        displayOption: models.DisplayOption.FitToWidth,
                    },
                },
                filters: filters,
            };

            var configMobile = {
                type: "report",
                id: report.id,
                embedUrl: "https://app.powerbi.com/reportEmbed?reportId=" + report.id,
                accessToken: tmj,
                tokenType: models.TokenType.Aad,
                permissions: models.Permissions.All,
                viewMode: 0,
                settings: {
                    filterPaneEnabled: this.config.filters,
                    navContentPaneEnabled: this.config.nav,
                    bars: {
                        actionBar: {
                            visible: false
                        }
                    },
                    panes: {
                        bookmarks: {
                            visible: false
                        }
                    },
                    layoutType: models.LayoutType.MobilePortrait
                },
                filters: filters,
            };

            var reportContainer = document.getElementById("reportContainer");

            let powerbi = new pbi.service.Service(
                pbi.factories.hpmFactory,
                pbi.factories.wpmpFactory,
                pbi.factories.routerFactory
            );

            var layout = {}
            if (this.celular === true) {
                layout = configMobile
            } else {
                layout = config
            }

            powerbi.reset(reportContainer);
            this.report = powerbi.embed(reportContainer, layout);
            this.idSelected = this.report.config.id
            await this.logs(report.id)

        },
        reportDialog() {
            this.addReport = true
        },
        hideDialog() {
            this.addReport = false
            if (this.reportsSelected.length > 0) {
                this.$emit('isReports', true)
            } else {
                this.$emit('isReports', false)
            }
        },
        pushReport(report) {
            const filtro = this.reportsSelected.filter(
                item => item.id === report.id
            )
            if (filtro.length > 0) {
                for (let i = 0; i < this.reportsSelected.length; i++) {
                    if (this.reportsSelected[i].id === report.id) {
                        this.reportsSelected.splice(i, 1)
                    }
                }
            } else {
                this.reportsSelected.push(report)
            }
        },
        fullScreen() {
            this.report.fullscreen();
            this.toggle(this.menu)
        },

        async reportPrint() {
            try {
                await this.report.print();
                this.toggle(this.menu)
            }
            catch (errors) {
                console.log(errors);
            }
        },
        async reportRefresh() {
            try {
                await this.report.refresh();
                this.toggle(this.menu)
            }
            catch (errors) {
                console.log(errors);
            }
        },
        toggle(event) {
            this.menu = !event
            if (event === true) {
                this.icon = "pi pi-bars"
            } else {
                this.icon = "pi pi-times"
            }
        },
        async logs(report) {
            await api.post("logs", {
                company: process.env.VUE_APP_EMPRESA_API || "ri",
                user_id: this.$store.state.user.uuid,
                page: "Home",
                action: "Acessou Relatorio",
                description: `O Usuário ${this.$store.state.user.name} acessou o relatório ${report}`
            })
        }
    }
}
</script>