<template>
    <div class="absolute flex flex-column -mt-50 pl-2 py-2">
        <Button v-tooltip="'Menu do Relatório'" type="button" :icon="icon" @click="toggle(menu)" aria-haspopup="true" aria-controls="overlay_menu"/>
        <div class="flex flex-column bg-white overlay_menu mt-2" v-if="menu">
            <Button label="Tela Cheia" icon="pi pi-window-maximize" class="p-button-rounded p-button-success p-button-text" v-on:click="fullScreen" />
            <Button label="Imprimir" icon="pi pi-print" class="p-button-rounded p-button-success p-button-text" v-on:click="reportPrint" />
            <Button label="Atualizar" icon="pi pi-refresh" class="p-button-rounded p-button-success p-button-text" v-on:click="reportRefresh" />
        </div>
    </div>
    <main class="flex flex-1">
        <div class="flex-1 min-h-screen" id="reportContainerDialog"></div>
    </main>
</template>
<script>
import {api} from '../../service/services';
import * as pbi from "powerbi-client";
import * as pbimodels from "powerbi-models";

export default {
    name: 'ReportInHome',
    components:{
        
    },
    data(){
        return{
            config: {
                viewMode: "view",
                filters: false,
                nav: false,
            },
            report: {},
            filters: [],
            idSelected: "",
            addReport: false,
            menu: false,
            icon: "pi pi-bars",
        }
    },
    props: {
        reports: Array,
        celular: Boolean,
    },
    async created(){
        await this.getReport(this.reports[0].report)
    },
    methods: {
        
        async getFilters() {
            const response = await api.get("filters")
            const data = response.data
            const dataUser = data.filter(
            (item) => item.id_user === window.localStorage.getItem("id_ri")
            )
            this.filters = dataUser
        },
        async getReport(report){
            var models = pbimodels;
            var config = {}
            await this.getFilters();
            const filters = [];
            this.filters.map((filter) => {
                const condicao = []
                filter.BodyFilter.forEach((i) =>{
                    if (i.format === "Numeric") {
                    i.values = parseInt(i.values)
                    }
                    condicao.push({
                    operator: i.operator,
                    value: i.value
                    })
                })
                filters.push({
                    $schema: "http://powerbi.com/product/schema#advanced",
                    target: {
                        table: filter.table,
                        column: filter.column,
                    },
                    logicalOperator: filter.operator,
                    conditions: condicao,
                    filterType: models.FilterType.Advanced,
                    });
                });

                const token = await api.get("token");
                const tmj = token.data

                if(report.nav === 1) {
                    this.config.nav = true
                } else {
                     this.config.nav = false
                }
                
                if(report.filters === 1) {
                    this.config.filters = true
                } else {
                     this.config.filters = false
                }

                config = {
                    type: "report",
                    id: report.id,
                    embedUrl: "https://app.powerbi.com/reportEmbed?reportId=" + report.id,
                    accessToken: tmj,
                    tokenType: models.TokenType.Aad,
                    permissions: models.Permissions.All,
                    viewMode: 0,
                    settings: {
                        filterPaneEnabled: this.config.filters,
                        navContentPaneEnabled: this.config.nav,
                        bars: {
                            actionBar: {
                                visible: false
                                }
                        },
                        panes: {
                            bookmarks: {
                                visible: false
                            }
                        },
                        layoutType: models.LayoutType.Custom,
                        customLayout: {
                            displayOption: models.DisplayOption.FitToWidth,
                        },
                    },
                    filters: filters,
                };
                
                var configMobile = {
                    type: "report",
                    id: report.id,
                    embedUrl: "https://app.powerbi.com/reportEmbed?reportId=" + report.id,
                    accessToken: tmj,
                    tokenType: models.TokenType.Aad,
                    permissions: models.Permissions.All,
                    viewMode: 0,
                    settings: {
                        filterPaneEnabled: this.config.filters,
                        navContentPaneEnabled: this.config.nav,
                        bars: {
                            actionBar: {
                                visible: false
                            }
                        },
                        panes: {
                            bookmarks: {
                                visible: false
                            }
                        },
                        layoutType: models.LayoutType.MobilePortrait
                    },
                    filters: filters,
                };

                var reportContainer = document.getElementById("reportContainerDialog");

                let powerbi = new pbi.service.Service(
                    pbi.factories.hpmFactory,
                    pbi.factories.wpmpFactory,
                    pbi.factories.routerFactory
                );

                var layout = {}
                if(this.celular === true) {
                    layout = configMobile
                }else{
                    layout = config
                }

                powerbi.reset(reportContainer);
                this.report = powerbi.embed(reportContainer, layout);
                this.idSelected = this.report.config.id
        },
        fullScreen() {
            this.report.fullscreen();
        },

        async reportPrint() {
            try {
                await this.report.print();
            } 
            catch (errors) {
                console.log(errors);
            }
        },
            async reportRefresh() {
            try {
                await this.report.refresh();
            } 
            catch (errors) {
                console.log(errors);
            }
        },
        toggle(event) {
            this.menu = !event
            if(event === true){
                this.icon = "pi pi-bars"
            } else {
                this.icon = "pi pi-times"
            }
            
        },
    }
}
</script>