<template>
	<div class="grid" v-show="view_permissions">
		<div class="col-12">
			<div class="card">
				<Toast/>
				<Toolbar class="mb-4">
					<template v-slot:start>
						<div class="my-2">
							<Button label="Novo" icon="pi pi-plus" class="p-button-success mr-2" @click="openNew" />
							<Button label="Apagar" icon="pi pi-trash" class="p-button-danger" @click="deletePermissionsDialog = true" :disabled="!selectedPermissions || !selectedPermissions.length" />
						</div>
					</template>

					<!-- <template v-slot:end>
						<FileUpload mode="basic" accept="image/*" :maxFileSize="1000000" label="Import" chooseLabel="Import" class="mr-2 inline-block" />
						<Button label="Export" icon="pi pi-upload" class="p-button-help" @click="exportCSV($event)"  />
					</template> -->
				</Toolbar>
				<div class="col-12" v-if="loading === true">
					<h4>Carregando Permissões... Aguarde!</h4>
					<lottie-animation
						:path="'animations/load_permission.json'"
						:loop="true"
						:autoPlay="true"
						:speed="1"
						:width="wid"
						:height="hei"
					/>
				</div>
				<DataTable v-else ref="dt" :value="permissions" v-model:selection="selectedPermissions" dataKey="id" :paginator="true" :rows="10" :filters="filters" :rowHover="true" class="p-datatable-gridlines" :loading="load"
							paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" :rowsPerPageOptions="[5,10,25]"
							currentPageReportTemplate="Mostrando {first} de {last} no total de {totalRecords} Permissões" responsiveLayout="scroll">
					<template #header>
						<div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
							<h5 class="m-0">Gerenciar Permissões</h5>
							<span class="block mt-2 md:mt-0 p-input-icon-left">
                                <i class="pi pi-search" />
                                <InputText v-model="filters['global'].value" placeholder="Search..." />
                            </span>
						</div>
					</template>
					<template #empty>
                        Não existe Permissões Cadastradas.
                    </template>
					<Column selectionMode="multiple" headerStyle="width: 3rem"></Column>
					<Column field="name" header="Nome" :sortable="true" headerStyle="width:25%; min-width:20rem;">
						<template #body="slotProps">
							<span class="p-column-title">Name</span>
							{{slotProps.data.name}}
						</template>
					</Column>
					<Column field="active" header="Status" :sortable="true" headerStyle="width:25%; min-width:20rem;">
						<template #body="slotProps">
							<span class="p-column-title">Active</span>
							<Tag v-if="slotProps.data.active === 1" class="mr-2" icon="pi pi-check" severity="success" value="Ativo"></Tag>
							<Tag v-else class="mr-2" icon="pi pi-times" severity="danger" value="Inativo"></Tag>
						</template>
					</Column>
					<Column field="createdAt" header="Criado em" :sortable="true" headerStyle="width:25%; min-width:20rem;">
						<template #body="slotProps">
							<span class="p-column-title">createdAt</span>
							{{slotProps.data.createdAt}}
						</template>
					</Column>
					<Column>
						<template #body="slotProps">
							<Button icon="pi pi-pencil" class="p-button-rounded p-button-success mr-2" @click="editPermission(slotProps.data)" />
							<Button icon="pi pi-trash" class="p-button-rounded p-button-warning mt-2" @click="confirmDeletePermission(slotProps.data)" />
						</template>
					</Column>
				</DataTable>
				<Dialog v-model:visible="permissionDialog" :style="{width: '750px'}" header="Nova Permissão" :modal="true" class="p-fluid">
					<div class="border-round border-1 surface-border p-4" v-if="load === true">
						<div class="flex mb-3">
							<div>
								<Skeleton width="10rem" class="mb-2"></Skeleton>
								<Skeleton width="5rem" class="mb-2"></Skeleton>
								<Skeleton width="5rem" class="mb-2"></Skeleton>
								<Skeleton height=".5rem"></Skeleton>
							</div>
						</div>
						<Skeleton width="100%" height="150px"></Skeleton>
						<div class="flex justify-content-between mt-3">
							<Skeleton width="4rem" height="2rem"></Skeleton>
							<Skeleton width="4rem" height="2rem"></Skeleton>
						</div>
					</div>
					<div v-else> 
						<div class="field">
							<label for="name">Nome da Permissão</label>
							<InputText id="name" v-model.trim="permission.name" required="true" autofocus :class="{'p-invalid': submitted && !permission.name}" />
						</div>
						<div class="field">
							<label for="active">Permissão Ativa?</label>
						</div>
						<div class="field">
							<InputSwitch v-model="switchValue" />
						</div>
						<div class="card col-12">
							<span class="p-buttonset ">
								<Button class="p-button-success" label="Sistema" icon="pi pi-chart-bar" @click="selectedSistema"/>
								<Button class="p-button-info" label="Relatório" icon="pi pi-chart-line" @click="selectedRelatorio"/>
								<Button v-show="view_financeiro" class="p-button-secondary" label="Financeiro" icon="pi pi-database" @click="selectedFinanceiro"/>
								<Button v-show="view_rh" class="p-button-warning" label="Rh" icon="pi pi-users" @click="selectedRh"/>
							</span>
						</div>
						<div class="flex flex-row md:flex-column justify-content-center" v-if="pickSelectedSistema === true">
							<h5>Tipos de Acesso dessa Permissão</h5>
							<div class="card">
								<h5>Sistema</h5>
								<PickList v-model="picklistSistema" listStyle="height:200px" dataKey="role"> 
									<template #sourceheader>
										Sem Acesso
									</template>
									<template #targetheader>
										Com Acesso
									</template>
									<template #item="slotProps">
										<div>{{slotProps.item.name}}</div>
									</template>
								</PickList>
							</div>
						</div>
						<div class="flex flex-row md:flex-column justify-content-center" v-if="pickSelectedRelatorio === true">
							<h5>Tipos de Acesso dessa Permissão</h5>
							<div class="card">
								<h5>Relatorio</h5>
								<PickList v-model="picklistRelatorio" listStyle="height:200px" dataKey="role"> 
									<template #sourceheader>
										Sem Acesso
									</template>
									<template #targetheader>
										Com Acesso
									</template>
									<template #item="slotProps">
										<div>{{slotProps.item.name}}</div>
									</template>
								</PickList>
							</div>
						</div>
						<div class="flex flex-row md:flex-column justify-content-center" v-if="pickSelectedFinanceiro === true" v-show="view_financeiro">
							<h5>Tipos de Acesso dessa Permissão</h5>
							<div class="card">
								<h5>Financeiro</h5>
								<PickList v-model="picklistFinanceiro" listStyle="height:200px" dataKey="role"> 
									<template #sourceheader>
										Sem Acesso
									</template>
									<template #targetheader>
										Com Acesso
									</template>
									<template #item="slotProps">
										<div>{{slotProps.item.name}}</div>
									</template>
								</PickList>
							</div>
						</div>
						<div class="flex flex-row md:flex-column justify-content-center" v-if="pickSelectedRh === true" v-show="view_rh">
							<h5>Tipos de Acesso dessa Permissão</h5>
							<div class="card">
								<h5>Rh</h5>
								<PickList v-model="picklistRh" listStyle="height:200px" dataKey="role"> 
									<template #sourceheader>
										Sem Acesso
									</template>
									<template #targetheader>
										Com Acesso
									</template>
									<template #item="slotProps">
										<div>{{slotProps.item.name}}</div>
									</template>
								</PickList>
							</div>
						</div>
					</div>
					<template #footer>
						<Button label="Cancelar" icon="pi pi-times" class="p-button-text" @click="hideDialog"/>
						<Button label="Salvar" icon="pi pi-check" class="p-button-text" @click="savePermission" />
					</template>
				</Dialog>
				<Dialog v-model:visible="permissionCreateDialog" :style="{width: '750px'}" header="Nova Permissão" :modal="true" class="p-fluid">
					<div class="field">
						<label for="name">Nome da Permissão</label>
						<InputText id="name" v-model.trim="permission.name" required="true" autofocus :class="{'p-invalid': submitted && !permission.name}" />
					</div>
					<div class="field">
					<label for="active">Permissão Ativa?</label>
					</div>
					<div class="field">
					<InputSwitch v-model="switchValue" />
					</div>
					<template #footer>
						<Button label="Cancelar" icon="pi pi-times" class="p-button-text" @click="hideCreateDialog"/>
						<Button label="Salvar" icon="pi pi-check" class="p-button-text" @click="createPermission" />
					</template>
				</Dialog>
				<Dialog v-model:visible="deletePermissionDialog" :style="{width: '450px'}" header="Confirmação" :modal="true">
					<div class="flex align-items-center justify-content-center">
						<i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
						<span v-if="permission">Tem certeza de que deseja excluir a Permissão <b>{{permission.name}}</b>?</span>
					</div>
					<template #footer>
						<Button label="Não" icon="pi pi-times" class="p-button-text" @click="deletePermissionDialog = false"/>
						<Button label="Sim" icon="pi pi-check" class="p-button-text" @click="deletePermission" />
					</template>
				</Dialog>
				<Dialog v-model:visible="deletePermissionsDialog" :style="{width: '450px'}" header="Confirmação" :modal="true">
					<div class="flex align-items-center justify-content-center">
						<i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
						<span v-if="permission">Tem certeza de que deseja excluir as Permissões selecionadas?</span>
					</div>
					<template #footer>
						<Button label="Não" icon="pi pi-times" class="p-button-text" @click="deletePermissionsDialog = false"/>
						<Button label="Sim" icon="pi pi-check" class="p-button-text" @click="deleteSelectedPermissions" />
					</template>
				</Dialog>
			</div>
		</div>
	</div>
</template>

<script>
import {FilterMatchMode} from 'primevue/api';
import {api} from '../../service/services'
import router from '../../router';
import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue";
export default {
    name: 'Permissions',
	components: {
      LottieAnimation
  },
	data() {
		return {
			permissions: [],
			permissionDialog: false,
			deletePermissionDialog: false,
			deletePermissionsDialog: false,
			permission: {},
			listroles:[],
			selectedPermissions: null,
			permissionCreateDialog: false,
			filters: {},
			submitted: false,
			switchValue: false,
			view_permissions: false,
			picklistSistema: [[],[]],
			picklistRelatorio: [[],[]],
			picklistFinanceiro: [[],[]],
			picklistRh: [[],[]],
			view_financeiro: false,
			view_rh: false,
			loading: false,
			wid: 500,
			hei: 600,
			load: false,
			pickSelectedSistema: true,
			pickSelectedRelatorio: false,
			pickSelectedFinanceiro: false,
			pickSelectedRh: false,
		}
	},
	permissionService: null,
	async created() {
		this.layoutCelular()
		await this.initFilters()
		this.loading = true
		await this.busca();
		await this.getPermission()
		this.loading = false
	},
	mounted() {
		this.getPermission().then(data => this.products = data);
	},
	methods: {
		async busca(){
			const response = JSON.parse(localStorage.getItem("pra"))
			const client = await api.getClient()
			const user = await api.getUserCurrent()
			const data = response.filter(
				(arry) => "view_permission".includes(arry)
			)
			if (data.length > 0){
				this.view_permissions = true
			} else {
				router.push("/error");
			}
			if(client.financeiro === true || user.admin === 1){
				this.view_financeiro = true
			}
			if(client.rh === true || user.admin === 1){
				this.view_rh = true
			}
		},
		layoutCelular(){
			var largura = window.innerWidth
				if(largura < 750) {
				this.wid = 400
				this.hei = 600
			}
		},
		async editPermission(permission) {
			this.permission = {...permission};
			this.permissionDialog = true
			this.load = true
			await this.getRoles();
			this.selectedSistema()
			let roles_id = []
			if(this.permission.active === 1){
				this.switchValue = true
			} 						
			this.permission.PermissionsRoles.forEach((role) => {
				roles_id.push(role.role);
				});
			const sistema0 = this.listroles.filter(
				item => 
					item.category === "Sistema"
			)
			const relatorio0 = this.listroles.filter(
				item => 
					item.category === "Relatorio"
			)
			const financeiro0 = this.listroles.filter(
				item => 
					item.category === "Financeiro"
			)
			const rh0 = this.listroles.filter(
				item => 
					item.category === "Rh"
			)
			const sistema1 = roles_id.filter(
				item => item.category === "Sistema"
			)
			const relatorio1 = roles_id.filter(
				item => item.category === "Relatorio"
			)
			const financeiro1 = roles_id.filter(
				item => item.category === "Financeiro"
			)
			const rh1 = roles_id.filter(
				item => item.category === "Rh"
			)
			
			this.picklistSistema[0] = sistema0
			this.picklistRelatorio[0] = relatorio0
			this.picklistFinanceiro[0] = financeiro0
			this.picklistRh[0] = rh0

			this.picklistSistema[1] = sistema1
			this.picklistRelatorio[1] = relatorio1
			this.picklistFinanceiro[1] = financeiro1
			this.picklistRh[1] = rh1
			this.load = false
		},

		confirmDeletePermission(permission) {
			this.permission = permission;
			this.deletePermissionDialog = true;
		},
		openNew() {
			this.permission = {};
			this.submitted = false;
			this.permissionCreateDialog = true;
			this.switchValue = true
		},
		hideCreateDialog() {
			this.permissionCreateDialog = false;
			this.submitted = false;
			this.switchValue = false
			this.permission = {};
		},
		hideDialog() {
			this.permissionDialog = false;
			this.submitted = false;
			this.permission = {};
			this.picklistSistema[0] = []
			this.picklistRelatorio[0] = []
			this.picklistFinanceiro[0] = []
			this.picklistRh[0] = []
			this.picklistSistema[1] = []
			this.picklistRelatorio[1] = []
			this.picklistFinanceiro[1] = []
			this.picklistRh[1] = []
		},
		initFilters() {
            this.filters = {
                'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
            }
        },
		async getPermission(){
			const response = await api.get("permissions");
			const data = await response.data;
			this.permissions = data
		},
		async getRoles(){
			const response = await api.get("roles/filter");
			const data = await response.data;
			this.listroles = data
		},

		async createPermission() {
			this.permissionCreateDialog = false;
			this.load = true
			try{
				if(this.permission.name !== ""){
					if(this.switchValue === true) {
						this.permission.active = 1
					} else {
						this.permission.active = 0
					}
				await api.post("permissions", {
						name: this.permission.name,
						active: Number(this.permission.active)
				})
				this.$toast.add({severity:'success', summary: 'Sucesso', detail: 'Permissão Criada com Sucesso', life: 3000});
				}
			} catch (err) {
				this.$toast.add({severity:'error', summary: 'Erro', detail: 'Não foi possível criar a Permissão ' + err, life: 3000});
			}
			this.permission = {};
			this.permissions = []
			await this.getPermission()
			this.load = false
		},


		async savePermission() {
			this.permissionDialog = false;
			this.load = true
			try{
				this.submitted = true;
				let roles_id = [];
				let id = this.permission.id
				this.permission.PermissionsRoles.forEach((role) => {
					roles_id.push(role.role);
				});
				
				const picklistValue = this.picklistSistema[1]
					.concat(this.picklistRelatorio[1], 
							this.picklistFinanceiro[1], 
							this.picklistRh[1])

				const equals = picklistValue.filter(
						(arr1Item) => !roles_id.includes(arr1Item));
				if (equals.length > 0){
					equals.forEach(async (add) =>{
							await api.post("permissions/pr", {
								id_permission: id,
								id_role: add.id
							});	
					})
				}
				const diff = roles_id.filter(
					(arr1Item) => !picklistValue.includes(arr1Item));
				if (diff.length > 0){
					diff.forEach(async (remove) =>{
						const dirole = this.permission.PermissionsRoles.filter(
						(item) => item.id_permission === id && item.id_role === remove.id
						)
						await api.delete("permissions/pr/delete/" + dirole[0].id);
					})				
				}
				await api.put("permissions/" + id, this.permission)
				this.$toast.add({severity:'success', summary: 'Sucesso', detail: 'Permissão Atualizada com Sucesso', life: 3000});
			}catch (err) {
				this.$toast.add({severity:'error', summary: 'Erro', detail: 'Não foi Possível Atualizar a Permissão ' + err, life: 3000});
			}
			this.permission = {};
			this.permissions = []
			await this.hideDialog()
			await this.getPermission()
			this.load = false
	},

		async deletePermission() {
			this.deletePermissionDialog = false;
			this.load = true
			try {
				await api.delete("permissions/" + this.permission.id)
				this.$toast.add({severity:'success', summary: 'Sucesso', detail: 'Permissão Apagada', life: 3000});
			}catch (err) {
				this.$toast.add({severity:'error', summary: 'Erro', detail: 'Permissão não Apagada', life: 3000});
			}
			this.permission = {};
			this.permissions = []
			await this.getPermission()
			this.load = false
		},
		selectedSistema(){
			this.pickSelectedSistema = true
			this.pickSelectedRelatorio = false
			this.pickSelectedFinanceiro = false
			this.pickSelectedRh = false
		},
		selectedRelatorio(){
			this.pickSelectedSistema = false
			this.pickSelectedRelatorio = true
			this.pickSelectedFinanceiro = false
			this.pickSelectedRh = false
		},
		selectedFinanceiro(){
			this.pickSelectedSistema = false
			this.pickSelectedRelatorio = false
			this.pickSelectedFinanceiro = true
			this.pickSelectedRh = false
		},
		selectedRh(){
			this.pickSelectedSistema = false
			this.pickSelectedRelatorio = false
			this.pickSelectedFinanceiro = false
			this.pickSelectedRh = true
		},
	}

}
</script>