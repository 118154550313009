<template>
	<div class="grid" v-show="view_config_workspaces">
		<div class="col-12" >
			<div class="card">
				<Toast/>
				<Toolbar class="mb-4">
					<template v-slot:start>
						<div class="my-2">
							<Button v-if="!visible" label="Sincronizar" icon="pi pi-sync" class="p-button-success mr-2" @click="syncWorkspaces" />
							<Button v-else :disabled="true" label="Sincronizando..." icon="pi pi-sync" class="p-button-success mr-2"  />
							<!-- <Button label="Inativar" icon="pi pi-trash" class="p-button-danger" @click="deleteWorkspacesDialog = true" :disabled="!selectedWorkspaces || !selectedWorkspaces.length" /> -->
						</div>
					</template>

					<!-- <template v-slot:end>
						<FileUpload mode="basic" accept="image/*" :maxFileSize="1000000" label="Import" chooseLabel="Import" class="mr-2 inline-block" />
						<Button label="Export" icon="pi pi-upload" class="p-button-help" @click="exportCSV($event)"  />
					</template> -->
				</Toolbar>
				<DataTable ref="dt" :value="workspaces" v-model:selection="selectedWorkspaces" dataKey="id" :paginator="true" :rows="10" :filters="filters" :loading="loading" :rowHover="true"
							paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" :rowsPerPageOptions="[5,10,25]"
							currentPageReportTemplate="Mostrando {first} de {last} no total de {totalRecords} Workspaces" responsiveLayout="scroll" class="p-datatable-gridlines">
					<template #header>
						<div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
							<h5 class="m-0">Gerenciar Workspaces</h5>
							<span class="block mt-2 md:mt-0 p-input-icon-left">
                                <i class="pi pi-search" />
                                <InputText v-model="filters['global'].value" placeholder="Search..." />
                            </span>
						</div>
					</template>
					<template #empty>
                        Não existe Workspaces Cadastradas.
                    </template>
					<Column selectionMode="multiple" headerStyle="width: 3rem"></Column>
					<Column field="namemenu" header="Nome" :sortable="true" headerStyle="width:25%; min-width:20rem;">
						<template #body="slotProps">
							<span class="p-column-title">Name</span>
							{{slotProps.data.namemenu}}
						</template>
					</Column>
					<Column field="active" header="Status" :sortable="true" headerStyle="width:15%; min-width:10rem;">
						<template #body="slotProps">
							<span class="p-column-title">Active</span>
							<Tag v-if="slotProps.data.active === 1" class="mr-2" icon="pi pi-check" severity="success" value="Ativo"></Tag>
							<Tag v-else class="mr-2" icon="pi pi-times" severity="danger" value="Inativo"></Tag>
						</template>
					</Column>
					<Column field="default" header="Padrao" :sortable="true" headerStyle="width:15%; min-width:10rem;">
						<template #body="slotProps">
							<span class="p-column-title">Padrão</span>
							<Tag v-if="slotProps.data.default === true" class="mr-2" icon="pi pi-check" severity="success" value="Padrão"></Tag>
							<Tag v-else class="mr-2" icon="pi pi-times" severity="warn" value=""></Tag>
						</template>
					</Column>
					<Column field="createdAt" header="Criado em" :sortable="true" headerStyle="width:25%; min-width:20rem;">
						<template #body="slotProps">
							<span class="p-column-title">createdAt</span>
							{{slotProps.data.createdAt}}
						</template>
					</Column>
					<Column>
						<template #body="slotProps">
							<Button icon="pi pi-pencil" placeholder="Top" v-tooltip.top="'Editar Workspace'" class="p-button-rounded p-button-success mr-2" @click="editWorkspace(slotProps.data)" />
							<Button icon="pi pi-trash" placeholder="Top" v-tooltip.top="'Excluir Workspace'" class="p-button-rounded p-button-warning mt-2 mr-2" @click="confirmDeleteWorkspace(slotProps.data)" />
							<Button icon="pi pi-sync" placeholder="Top" v-tooltip.top="'Sincronizar Relatórios'" class="p-button-rounded p-button-primary mt-2" @click="syncReports(slotProps.data)" />
						</template>
					</Column>
				</DataTable>
				<Dialog v-model:visible="workspaceDialog" :style="{width: '600px'}" header="Editar Workspace" :modal="true" class="p-fluid">
					<div class="field col-12 md:col-12">
						<label for="name">Nome da Workspace PBI</label>
						<InputText :disabled="visible" id="name" v-model.trim="workspace.name" required="true" autofocus :class="{'p-invalid': submitted && !workspace.name}" />
					</div>
					<div class="field col-12 md:col-12">
						<label for="namemenu">Nome Workspace Site</label>
						<InputText id="namemenu" v-model.trim="workspace.namemenu" required="true" autofocus :class="{'p-invalid': submitted && !workspace.namemenu}" />
					</div>
					<div class="field-checkbox mb-3">
					<div class="field-checkbox mb-3">
						<InputSwitch v-model="switchValue" />
						<label for="active">Workspace Ativa?</label>
					</div>
					<div class="field-checkbox mb-3 ml-3">
						<InputSwitch v-model="switchDefault" />
						<label for="active">Workspace Padrão</label>
					</div>
					</div>
					<template #footer>
						<Button label="Cancelar" icon="pi pi-times" class="p-button-text" @click="hideWorkspaceDialog"/>
						<Button label="Salvar" icon="pi pi-check" class="p-button-text" @click="saveWorkspace" />
					</template>
				</Dialog>
				<Dialog v-model:visible="etapa1Delete" :style="{width: '600px'}" header="Confirmação" :modal="true">
					<div class="flex align-items-center justify-content-center">
						<i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
						<span v-if="workspace">A exclusão da Workspace <b>{{workspace.namemenu}}</b> só pode ser feita
									pelo site PowerBi Service! Por aqui só conseguimos Inativar a Workspace <b>{{workspace.namemenu}}</b>! <br>
									Deseja Inativar a Workspace <b>{{workspace.namemenu}}</b>? </span>
					</div>
					<template #footer>
						<Button label="Não" icon="pi pi-times" class="p-button-text" @click="fechaEtapa1Delete"/>
						<Button label="Sim" icon="pi pi-check" class="p-button-text" @click="verifyWorkspace" />
					</template>
				</Dialog>
				<Dialog v-model:visible="etapa2Delete" :style="{width: '600px'}" header="Confirmação" :modal="true">
					<div class="flex align-items-center justify-content-center">
						<i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
						<span v-if="workspace">A Workspace <b>{{workspace.namemenu}}</b> esta marcada como Padrão!<br> 
								Deseja realmente Inativar a Workspace <b>{{workspace.namemenu}}</b>? </span>
					</div>
					<template #footer>
						<Button label="Não" icon="pi pi-times" class="p-button-text" @click="fechaEtapa1Delete"/>
						<Button label="Sim" icon="pi pi-check" class="p-button-text" @click="escolha" />
					</template>
				</Dialog>
				<Dialog v-model:visible="choiceWorkspace" :style="{width: '450px'}" header="Escolhendo Nova Workspace Padrão" :modal="true" class="p-fluid">
					<div class="field col-12 md:col-12">
						<label for="permissions">Selecione uma nova Workspace como Padrão</label>
						<Dropdown id="id" v-model="dropdownValue" :options="dropdownValues" optionLabel="name" placeholder="Workspace Cadastradas" required="true" />
					</div>
					<template #footer>
						<Button label="Ok" icon="pi pi-check" class="p-button-text" @click="newDefault" />
					</template>
				</Dialog>
				
			</div>
		</div>
	</div>
</template>

<script>
import router from '../../router';
import {api} from '../../service/services';
import {FilterMatchMode} from 'primevue/api';
export default {
    name: 'ConfigWorkspaces',
	data(){
		return {
			view_config_workspaces: false,
			workspaces: null,
			workspaceDialog: false,
			etapa1Delete: false,
			etapa2Delete: false,
			deleteWorkspacesDialog: false,
			workspaceCreateDialog: false,
			workspace: {},
			selectedWorkspaces: [],
			filters: {},
			visible: false,
			switchValue: false,
			switchDefault: false,
			submitted: false,
			choiceWorkspace: false,
			dropdownValues: [],
			dropdownValue: {},
			loading: false
		}
	},

	created() { 
		this.busca()
		this.initFilters()
		this.getWorkspace()
		},
	
	methods:{
		busca(){
			const response = JSON.parse(localStorage.getItem("pra"))
			const data = response.filter(
				(arry) => "view_config_workspace".includes(arry)
			)
			if (data.length > 0){
				this.view_config_workspaces = true
			} else {
				router.push("/error");
			}
		},
		initFilters() {
            this.filters = {
                'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
            }
        },
		async getWorkspace(){
			this.loading = true
			const response = await api.get("workspaces");
			const data = await response.data;
			this.workspaces = data
			this.loading = false
		},
		async syncWorkspaces() {
			this.loading = true
			this.submitted = true
			this.visible = true
			
			try{
				const token = await api.get("token");
				const tmj = token.data

				if(!this.workspaces.length < 1){
					await api.post("workspaces",{
						tmj: tmj
					})
					this.workspaces = null
				} else {
					await api.post("workspaces/sync",{
						tmj: tmj
					})
					this.workspaces = null
				}				
				this.$toast.add({severity:'success', summary: 'Sucesso', detail: 'Sincronismo feito com Sucesso', life: 3000});
				await this.getWorkspace()
				this.submitted = false
				this.loading = false
				this.visible = false
			}catch (err) {
				await this.getWorkspace()
				this.workspaces = null
				this.submitted = false
				this.loading = false
				this.visible = false
				this.$toast.add({severity:'error', summary: 'Erro', detail: 'Erro no Sincronismo ' + err, life: 3000});
			}

		},
		async saveWorkspace(){
			this.submitted = true
			if(this.switchValue === true){
				this.workspace.active = 1
			}else{
				this.workspace.active = 0
			}
			if(this.switchDefault === true){
				const qtde = this.workspaces.filter(
				(item) => item.default === true)

				if(qtde.length === 0 || qtde[0].id === this.workspace.id) {
					try{
					await api.put("workspaces/" + this.workspace.id, {
						namemenu: this.workspace.namemenu,
						active: this.workspace.active,
						padrao: this.switchDefault
					})
					this.hideWorkspaceDialog()
					this.submitted = false
					this.$toast.add({severity:'success', summary: 'Sucesso', detail: 'Workspace Atualizada com Sucesso', life: 3000});
				}catch (err){
					this.hideWorkspaceDialog()
					this.submitted = false
					this.$toast.add({severity:'error', summary: 'Erro', detail: 'Erro ao Atualizar dados da Workspace ' + err, life: 3000});
				} 
				}else {
					this.hideWorkspaceDialog()
					this.submitted = false
					this.$toast.add({severity:'error', summary: 'Erro', detail: 'Não pode ter 2 Workspace marcada como Padrão. Desmarque a que esta como Padrão e Atualiza novamente', life: 5000});
				}
			} else {
				try{
					await api.put("workspaces/" + this.workspace.id, {
						namemenu: this.workspace.namemenu,
						active: this.workspace.active,
						padrao: this.switchDefault
					})
					this.hideWorkspaceDialog()
					this.submitted = false
					this.$toast.add({severity:'success', summary: 'Sucesso', detail: 'Workspace Atualizada com Sucesso', life: 3000});
				}catch (err){
					this.hideWorkspaceDialog()
					this.submitted = false
					this.$toast.add({severity:'error', summary: 'Erro', detail: 'Erro ao Atualizar dados da Workspace ' + err, life: 3000});
				} 
			}
		},

		async saveWorkspaceConfirm(workspace) {
			this.submitted = true
			try{
				await api.put("workspaces/" + workspace.id, {
					namemenu: workspace.namemenu,
					active: workspace.active,
					padrao: true
				})
				this.hideWorkspaceDialog()
				this.submitted = false
				this.$toast.add({severity:'success', summary: 'Sucesso', detail: 'Workspace Atualizada com Sucesso', life: 3000});
			}catch (err){
				this.hideWorkspaceDialog()
				this.submitted = false
				this.$toast.add({severity:'error', summary: 'Erro', detail: 'Erro ao Atualizar dados da Workspace ' + err, life: 3000});
			}
		},

		editWorkspace(workspace) {
			this.workspace = {...workspace};
			this.submitted = true
			this.workspaceDialog = true;
			this.visible = true
			this.switchDefault = workspace.default
			
			if(workspace.active === 1){
				this.switchValue = true
			}
		},
		hideWorkspaceDialog(){
			this.workspaceDialog = false;
			this.visible = false
			this.switchValue = false
			this.switchDefault = false
			this.submitted = false
		},
		confirmDeleteWorkspace(workspace){
			this.workspace = workspace
			this.submitted = true
			this.etapa1Delete = true
		},
		fechaEtapa1Delete(){
			this.workspace = {}
			this.submitted = false
			this.etapa1Delete = false
			this.etapa2Delete = false
			this.choiceWorkspace = false
		},
		inativaWorkspace(){
			try{
				api.put("workspaces/" + this.workspace.id, {
					namemenu: this.workspace.namemenu,
					active: 0,
					padrao: this.workspace.default
				})
				this.fechaEtapa1Delete()
				this.$toast.add({severity:'success', summary: 'Sucesso', detail: 'Workspace Inativada com Sucesso', life: 3000});
			}catch (err) {
				this.fechaEtapa1Delete()
				this.$toast.add({severity:'error', summary: 'Erro', detail: 'Erro ao Inativar a Workspace ' + err, life: 3000});
			}	
		},
		verifyWorkspace(){
			if(this.workspace.default === true){
				this.etapa1Delete = false
				this.etapa2Delete = true
			} else {
				this.inativaWorkspace()
			}
		},
		async escolha(){
			this.etapa2Delete = false
			this.dropdownValue = {}
			this.dropdownValues = []
			this.choiceWorkspace = true
			this.dropdownValue = {}
			const response = await api.get("workspaces")
			const data = await response.data
			const work = data.filter(
				item => item.active === 1 &&
						item.id !== this.workspace.id
			)
			this.dropdownValues = work
		},
		async newDefault(){			
			try{
				await api.put("workspaces/" + this.workspace.id, {
					namemenu: this.workspace.namemenu,
					active: 0,
					padrao: false
				})
				await api.put("workspaces/" + this.dropdownValue.id, {
					namemenu: this.dropdownValue.namemenu,
					active: this.dropdownValue.active,
					padrao: true
				})
				this.fechaEtapa1Delete()
				this.$toast.add({severity:'success', summary: 'Sucesso', detail: 'Workspaces Atualizadas com Sucesso', life: 3000});
			}catch (err) {
				this.fechaEtapa1Delete()
				this.$toast.add({severity:'error', summary: 'Erro', detail: 'Erro ao Atualizar dados das Workspaces ' + err, life: 3000});
			}
		},
		async syncReports(workspace){
			try{
				this.loading = true
				const token = await api.get("token");
				const tmj = token.data

				await api.post("datasets/syncUnique", {
					tmj: tmj,
					workspace: workspace.id
				})

				await api.post("reports/syncUnique", {
					tmj: tmj,
					workspace: workspace.id
				})
				this.$toast.add({severity:'success', summary: 'Sucesso', detail: 'Sincronismo feito com Sucesso', life: 3000});
				this.loading = false
			}catch (err) {
				this.loading = false
				this.$toast.add({severity:'error', summary: 'Erro', detail: 'Erro no Sincronismo ' + err, life: 3000});
			}
		},
	}
}
</script>