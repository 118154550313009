<template>
	<div class="grid" v-show="view_config_dashboards">
		<div class="col-12" >
			<div class="card">
				<Toast/>
				<Toolbar class="mb-4">
					<template v-slot:start>
						<div class="my-2">
							<Button v-if="!visible" label="Sincronizar" icon="pi pi-sync" class="p-button-success mr-2" @click="escolha" />
							<Button v-else :disabled="true" label="Sincronizando..." icon="pi pi-sync" class="p-button-success mr-2"  />
							<!-- <Button label="Inativar" icon="pi pi-trash" class="p-button-danger" @click="deleteDashboardsDialog = true" :disabled="!selectedDashboards || !selectedDashboards.length" /> -->
						</div>
					</template>

					<!-- <template v-slot:end>
						<FileUpload mode="basic" accept="image/*" :maxFileSize="1000000" label="Import" chooseLabel="Import" class="mr-2 inline-block" />
						<Button label="Export" icon="pi pi-upload" class="p-button-help" @click="exportCSV($event)"  />
					</template> -->
				</Toolbar>
				<DataTable ref="dt" :value="dashboards" v-model:selection="selectedDashboards" dataKey="id" :paginator="true" :rows="10" :filters="filters" :rowHover="true" class="p-datatable-gridlines" :loading="loading"
							paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" :rowsPerPageOptions="[5,10,25]"
							currentPageReportTemplate="Mostrando {first} de {last} no total de {totalRecords} Dashboards" responsiveLayout="scroll">
					<template #header>
						<div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
							<h5 class="m-0">Gerenciar Paineis</h5>
							<span class="block mt-2 md:mt-0 p-input-icon-left">
                                <i class="pi pi-search" />
                                <InputText v-model="filters['global'].value" placeholder="Search..." />
                            </span>
						</div>
					</template>
					<template #empty>
                        Não existe Paineis Cadastrados.
                    </template>
					<Column selectionMode="multiple" headerStyle="width: 3rem"></Column>
					<Column field="namemenu" header="Nome" :sortable="true" headerStyle="width:25%; min-width:20rem;">
						<template #body="slotProps">
							<span class="p-column-title">Name</span>
							{{slotProps.data.namemenu}}
						</template>
					</Column>
					<Column field="active" header="Status" :sortable="true" headerStyle="width:15%; min-width:10rem;">
						<template #body="slotProps">
							<span class="p-column-title">Active</span>
							<Tag v-if="slotProps.data.active === 1" class="mr-2" icon="pi pi-check" severity="success" value="Ativo"></Tag>
							<Tag v-else class="mr-2" icon="pi pi-times" severity="danger" value="Inativo"></Tag>
						</template>
					</Column>
					<Column field="createdAt" header="Criado em" :sortable="true" headerStyle="width:25%; min-width:20rem;">
						<template #body="slotProps">
							<span class="p-column-title">createdAt</span>
							{{slotProps.data.createdAt}}
						</template>
					</Column>
					<Column>
						<template #body="slotProps">
							<Button icon="pi pi-pencil" class="p-button-rounded p-button-success mr-2" @click="editDashboard(slotProps.data)" />
							<Button icon="pi pi-trash" class="p-button-rounded p-button-warning mt-2" @click="deleteDialog(slotProps.data)" />
						</template>
					</Column>
				</DataTable>
				<Dialog v-model:visible="dashboardDialog" :style="{width: '700px'}" header="Editar Painel" :modal="true" class="p-fluid">
					<div class="field col-12 md:col-12">
						<label for="name">Nome do Painel PBI</label>
						<InputText :disabled="visible" id="name" v-model.trim="dashboard.name" required="true" :class="{'p-invalid': submitted && !dashboard.name}" />
					</div>
					<div class="field col-12 md:col-12">
						<label for="namemenu">Nome do Painel no Site</label>
						<InputText id="namemenu" v-model.trim="dashboard.namemenu" required="true" autofocus :class="{'p-invalid': submitted && !dashboard.namemenu}" />
					</div>
					<div class="field-checkbox mb-3">
					<div class="field-checkbox mb-3">
						<InputSwitch v-model="switchValue" />
						<label for="active">Ativo</label>
					</div>
					</div>
					<template #footer>
						<Button label="Cancelar" icon="pi pi-times" class="p-button-text" @click="hideEditDashboard"/>
						<Button label="Salvar" icon="pi pi-check" class="p-button-text" @click="saveDashboard" />
					</template>
				</Dialog>
				<Dialog v-model:visible="choiceWorkspace" :style="{width: '450px'}" header="Escolhendo a Workspace" :modal="true" class="p-fluid">
					<div class="field col-12 md:col-12">
						<label for="permissions">Selecione uma Workspace</label>
						<Dropdown id="id" v-model="dropdownValue" :options="dropdownValues" optionLabel="name" placeholder="Workspace Cadastradas" required="true" />
					</div>
					<template #footer>
						<Button label="Cancelar" icon="pi pi-times" class="p-button-text" @click="hideChoiceWorkspace"/>
						<Button label="Criar" icon="pi pi-check" class="p-button-text" @click="createDashboards" />
						<Button label="Sincronizar" icon="pi pi-sync" class="p-button-text" @click="syncDashboards" />
					</template>
				</Dialog>
				<Dialog v-model:visible="deleteDashboardsDialog" :style="{width: '600px'}" header="Confirmação" :modal="true">
					<div class="flex align-items-center justify-content-center">
						<i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
						<span v-if="dashboard">A exclusão do Painel <b>{{dashboard.namemenu}}</b> só pode ser feita
									pelo site PowerBi Service! Por aqui só conseguimos Inativar o Painel <b>{{dashboard.namemenu}}</b>! <br>
									Deseja Inativar o Painel <b>{{dashboard.namemenu}}</b>? </span>
					</div>
					<template #footer>
						<Button label="Não" icon="pi pi-times" class="p-button-text" @click="hideDeleteDialog"/>
						<Button label="Sim" icon="pi pi-check" class="p-button-text" @click="deleteDashboard" />
					</template>
				</Dialog>
				
			</div>
		</div>
	</div>
</template>

<script>
import router from '../../router';
import {api} from '../../service/services';
import {FilterMatchMode} from 'primevue/api';
export default {
    name: 'ConfigDashboards',
	data(){
		return {
			view_config_dashboards: false,
			deleteDashboardsDialog: false,
			selectedDashboards: false,
			dashboards: null,
			dashboard: {},
			submitted: false,
			dashboardDialog: false,
			visible: false,
			switchValue: false,
			choiceWorkspace: false,
			dropdownValues: [],
			dropdownValue: {},
			filters: {},
			loading: false
		}
	},

	created() { 
		this.busca()
		this.initFilters()
		this.getDashboard()
		this.getWorkspace()
		},
	
	methods:{
		busca(){
			const response = JSON.parse(localStorage.getItem("pra"))
			const data = response.filter(
				(arry) => "view_config_dashboard".includes(arry)
			)
			if (data.length > 0){
				this.view_config_dashboards = true
			} else {
				router.push("/error");
			}
		},
		async syncDashboards(){
			try{
				const token = await api.get("token");
				const tmj = token.data

				await api.post("dashboards/sync", {
					tmj: tmj
				})
				this.submitted = false
				this.dashboards = null
				this.getDashboard()
				this.choiceWorkspace = false
				this.visible = false
				this.$toast.add({severity:'success', summary: 'Sucesso', detail: 'Sincronismo feito com Sucesso', life: 3000});
			}catch (err) {
				this.submitted = false
				this.visible = false
				this.choiceWorkspace = false
				this.$toast.add({severity:'error', summary: 'Erro', detail: 'Erro no Sincronismo ' + err, life: 3000});
			}
		},
		async createDashboards(){
			try{
				const token = await api.get("token");
				const tmj = token.data

				await api.post("dashboards", {
					workspace_id: this.dropdownValue.id ,
					tmj: tmj
				})
				this.submitted = false
				this.dashboards = null
				this.getDashboard()
				this.choiceWorkspace = false
				this.visible = false
				this.$toast.add({severity:'success', summary: 'Sucesso', detail: 'Sincronismo feito com Sucesso', life: 3000});
			}catch (err) {
				this.submitted = false
				this.choiceWorkspace = false
				this.visible = false
				this.$toast.add({severity:'error', summary: 'Erro', detail: 'Erro no Sincronismo ' + err, life: 3000});
			}

		},
		
		initFilters() {
            this.filters = {
                'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
            }
        },
		async getDashboard(){
			this.loading = true
			const response = await api.get("dashboards");
			const data = await response.data;
			this.dashboards = data
			this.loading = false
		},
		async getWorkspace(){
			const response = await api.get("workspaces");
			const data = await response.data;
			const ativo = data.filter(
				(item) => item.active === 1
			)
			this.dropdownValues = ativo
		},
		hideChoiceWorkspace(){
			this.submitted = false
			this.dropdownValue = {}
			this.choiceWorkspace = false
			this.visible = false
		},
		escolha(){
			this.visible = true
			this.choiceWorkspace = true
			const workspace = this.dropdownValues.filter(
				(item) => item.default === true
			)
			this.dropdownValue = workspace[0]
		},
		editDashboard(dashboard){
			this.dashboardDialog = true,
			this.submitted = true
			this.visible = true
			this.dashboard = dashboard
			if(dashboard.active === 1){
				this.switchValue = true
			}
		},
		hideEditDashboard(){
			this.dashboardDialog = false
			this.submitted = false
			this.switchValue = false
			this.visible = false
			this.dashboard = {}
		},
		async saveDashboard(){
			this.submitted = true
			if(this.switchValue === true){
				this.dashboard.active = 1
			}else{
				this.dashboard.active = 0
			}
			try{
				await api.put("dashboards/" + this.dashboard.id, {
					namemenu: this.dashboard.namemenu,
					active: this.dashboard.active
				})
				this.hideEditDashboard()
				this.submitted = false
				this.dashboards = null
				this.getDashboard()
				this.$toast.add({severity:'success', summary: 'Sucesso', detail: 'Conjunto de Dados Atualizado com Sucesso', life: 3000});
			}catch (err){
				this.hideEditDashboard()
				this.submitted = false
				this.dashboards = null
				this.getDashboard()
				this.$toast.add({severity:'error', summary: 'Erro', detail: 'Erro ao Atualizar dados do Conjunto de Dados ' + err, life: 3000});
			} 
			
		},
		async deleteDashboard(){
			try{
				await api.put("dashboards/" + this.dashboard.id, {
					namemenu: this.dashboard.namemenu,
					active: 0,
				})
				this.hideDeleteDialog()
				this.submitted = false
				this.dashboards = null
				this.getDashboard()
				this.$toast.add({severity:'success', summary: 'Sucesso', detail: 'Fluxo de Dados Atualizado com Sucesso', life: 3000});
			}catch (err){
				this.hideDeleteDialog()
				this.submitted = false
				this.dashboards = null
				this.getDashboard()
				this.$toast.add({severity:'error', summary: 'Erro', detail: 'Erro ao Atualizar dados do Fluxo de Dados ' + err, life: 3000});
			} 
			
		},

		deleteDialog(dashboard){
			this.dashboard = dashboard
			this.deleteDashboardsDialog = true
		},

		hideDeleteDialog(){
			this.dashboard = {}
			this.deleteDashboardsDialog = false
		}
	}
}
</script>