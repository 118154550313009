<template>
  <EmbededDashboard :id="this.$route.params.id" />
</template>

<script>
import EmbededDashboard from "../../components/Embeded/EmbededDashboard.vue";

export default {
  name: "Dashboard",
  components: {
    EmbededDashboard,
  },
};
</script>

<style>
</style>