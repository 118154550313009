<template>
	<div class="grid" style="justify-content: center" v-show="view_config_azure">
		<Toast ref="alert" />
		<div class="col-10" >
			<div class="card">
				<h5>Configuração de Dados Azure</h5>
					<div class="grid p-fluid mt-5" style="justify-content: center">
						<div class="field col-12 md:col-10">
							<h6>E-mail Admin do Azure</h6>
							<span class="p-input-icon-left">
								<i class="pi pi-user" />
								<InputText type="text" placeholder="Usuário" v-model="EmailAdmin"/>
							</span>
						</div>
						<div class="field col-12 md:col-10">
							<h6>Senha do Admin Azure</h6>
							<span class="p-input-icon-left">
								<i class="pi pi-key" />
								<InputText type="password" placeholder="Senha" v-model="PassAdmin"/>
							</span>	
						</div>
						<div class="field col-12 md:col-10">
							<h6>Digite o Client ID Gerado no Azure</h6>
							<span class="p-input-icon-left">
								<i class="pi pi-check-circle" />
								<InputText type="text" placeholder="Client ID do Azure" v-model="ClientID"/>
							</span>
						</div>
					</div>
					<div>
					<Button v-on:click="save" label="Salvar" class="mt-2 mr-2 mb-5" v-if="!loading"></Button>
					<Button label="Salvando..." class="ml-5 mt-2 mr-2 mb-5" v-else :disabled="true"></Button>
					<Tag v-if="connect" class="mr-2 mb-5" severity="success" value="Conectado no Azure com Sucesso"></Tag>
					<Tag v-else class="mr-2 mb-5 p-2 ml-2" severity="danger" value="Não conectado com Azure"></Tag>
					<Button v-if="!connect" v-on:click="validatetoken" label="Validar Token" class="p-button-success p-button-text mb-4" />
					</div>
			</div>
		</div>
	</div>
</template>

<script>
import {api} from '../../service/services'
import { mapState } from "vuex";
import router from '../../router';
export default {

    name: 'ConfigAzure',
	data() {
		return{
			loading: false,
			EmailAdmin: "",
			PassAdmin: "",
			ClientID: "",
			connect: false,
			view_config_azure: false
		}
	},
	computed: mapState(["client"]),
  async created() {
    await this.getAzureData();
    await api.google();
	await this.busca()
  },

  methods:{
		busca(){
			const response = JSON.parse(localStorage.getItem("pra"))
			const data = response.filter(
				(arry) => "view_config_azure".includes(arry)
			)
			if (data.length > 0){
				this.view_config_azure = true
			} else {
				router.push("/error");
			}
		},
	
	async getAzureData() {
      const response = await api.get("/azure-config");
      const data = await response.data;
		this.EmailAdmin = data.user_name,
		this.PassAdmin = data.password,
		this.ClientID = data.client_id,
		this.connect = data.connect
    },

	async save(){
	try {
		this.loading = true;
		const response = await api.get("/azure-config");
		const data = await response.data;

		if (!data) {
			await api.post("/azure-config", {
            user_name: this.EmailAdmin,
            password: this.PassAdmin,
            client_id: this.ClientID,
			connect: false
          });

          this.$toast.add({severity:'success', summary: 'Salvo com Sucesso', detail: "Suas Credenciais do Azure foram salva com Sucesso", life: 3000});
          this.loading = false;
		} else{

		if (
			data.user_name === this.EmailAdmin &&
			data.password === this.PassAdmin &&
			data.client_id === this.ClientID) {
			
			this.$toast.add({severity:'success', summary: 'Salvo com Sucesso', detail: "Suas Credenciais do Azure foram salva com Sucesso", life: 3000});
			this.loading = false
			
		} else {

         await api.put("/azure-config/" + data.id, {
            user_name: this.EmailAdmin,
            password: this.PassAdmin,
            client_id: this.ClientID,
			connect: false
          });

          this.$toast.add({severity:'success', summary: 'Salvo com Sucesso', detail: "Suas Credenciais do Azure foram salva com Sucesso", life: 3000});
          this.loading = false;
		}
		}
        } catch (err) {
		this.loading = false;
          this.$toast.add({severity:'error', summary: 'Mensagem de Erro', detail: err, life: 3000});
        }
	},
	async validatetoken(){
		try{
			const response = await api.put("/token")
			const data = response.data
			if(data){
				this.connect = true
				const responseGet = await api.get("/azure-config")
				const dataGet = responseGet.data
				await api.put("/azure-config/" + dataGet.id, {
					connect: this.connect
				});
				this.$toast.add({severity:'success', summary: 'Token Validado', detail: "Seu token foi gerado com sucesso", life: 3000});
			}
		}catch (err){
			this.connect = false
			this.$toast.add({severity:'error', summary: 'Token não Validado', detail: err, life: 3000});
		}
	},

  }

}
</script>