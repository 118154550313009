<template>
	<div class="grid" v-show="view_dashboards">
		<div class="col-12">
			<div class="card">
				<div class="col-12" v-if="loading === true">
					<h4>Carregando Paineis... Aguarde!</h4>
					<lottie-animation
						:path="'animations/load_dashboard.json'"
						:loop="true"
						:autoPlay="true"
						:speed="1"
						:width="wid"
						:height="hei"
					/>
				</div>
				<DataView v-else :value="dashboards" :layout="layout" :paginator="true" :rows="12" :Loading="loading">
					<template #header>
						<div class="grid grid-nogutter">
							<div class="col-6 text-left">
								<h4>Listagem de Paineis</h4>
								<!-- <Dropdown v-model="sortKey" :options="sortOptions" optionLabel="label" placeholder="Classificar por" @change="onSortChange($event)"/> -->
							</div>
							<div class="col-6 text-right">								
								<DataViewLayoutOptions v-show="!celular" v-model="layout" />
							</div>
						</div>
					</template>
					<template #list="slotProps">
						<div class="col-12" v-show="!celular">
							<div class="flex flex-column md:flex-row align-items-center p-3 w-full">
								<router-link :to="{ name: 'Dashboard', params: { id: slotProps.data.id }}">
									<img :src="'images/views/dashboards-icon.png'" :alt="slotProps.data.dashboard.namemenu" class="my-4 md:my-0 w-5 md:w-3rem shadow-2 mr-5"/>
								</router-link>
								<div class="flex-1 text-center md:text-left">
									<router-link :to="{ name: 'Dashboard', params: { id: slotProps.data.dashboard.id }}">
										<div class="flex-1 text-center md:text-left">
										<div class="font-regular text-2xl" >{{slotProps.data.dashboard.namemenu}}</div>
										</div>
									</router-link>
								</div>
								<div class="flex flex-row md:flex-column justify-content-between w-full md:w-auto align-items-center md:align-items-end mt-5 md:mt-0" >
									<span class="text-2xl font-semibold mb-2 align-self-center md:align-self-end"></span>
									<Button icon="pi pi-eye" :disabled="false" class="mb-2" v-on:click="viewDashboard(slotProps.data.id)"></Button>
								</div>
							</div>
						</div>
					</template>

					<template #grid="slotProps">
						<div class="col-12 md:col-3">
							<div class="card m-1 border-1 surface-border">
								<div class="text-center">
									<router-link :to="{ name: 'Dashboard', params: { id: slotProps.data.dashboard.id }}">
										<img :src="'images/views/dashboards-icon.png'" :alt="slotProps.data.namemenu" class="w-2 shadow-2 my-2 mx-0"/>
										<div class="text-2x1 font-regular" >{{slotProps.data.dashboard.namemenu}}</div>
									</router-link>
								</div>
								<div class="flex align-items-center justify-content-between mt-3">
									<Button icon="pi pi-eye" :disabled="false" class="p-button-rounded p-button-help p-button-text mr-2 mb-2" v-on:click="viewDashboard(slotProps.data.dashboard.id)" v-show="edit_dashboards"></Button>
								</div>
							</div>
						</div>
					</template>
				</DataView>
			</div>
		</div>
	</div>
</template>

<script>
import router from '../../router';
import {api} from '../../service/services';
import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue";
export default {
    name: 'Dashboards',
	components: {
      LottieAnimation
	},
	data(){
		return {
			view_dashboards: false,
			layout: 'grid',
			dashboards: null,
			loading: false,
			edit_dashboards: false,
			celular: false,
			wid: 600,
			hei: 700
			
		}
	},

	async created() { 
		await this.layoutCelular()
		this.loading = true
		await this.busca()
		await this.getDashboard()
		await this.layoutCelular()
		this.loading = false
		},
	
	methods:{
		busca(){
			const response = JSON.parse(localStorage.getItem("pra"))
			const data = response.filter(
				(arry) => "view_dashboard".includes(arry)
			)
			if (data.length > 0){
				this.view_dashboards = true
			} else {
				router.push("/error");
			}
		},
		async getDashboard(){
			this.loading = true
			
			const response = await api.get("users/" + window.localStorage.getItem("id_ri"));
			const data = await response.data.DashboardsUsers;
			
			data.sort(function (a,b) { 
				return (a.dashboard.namemenu > b.dashboard.namemenu) ? 1 : ((b.dashboard.namemenu > a.dashboard.namemenu) ? -1 : 0)
			})
			
			const ativo = data.filter(
				(item) => item.dashboard.active === 1
			)
			this.dashboards = ativo
		},

		viewDashboard(id) {
			this.$router.replace({ name: 'Dashboard', params: { id: id}});
		},

		layoutCelular(){
			var largura = window.innerWidth
				if(largura < 750) {
				this.celular = true
				this.wid = 300
				this.hei = 600
			}
		}
	}
}

</script>