<template>
	<div class="grid" v-show="view_lanc_metas">
		<div class="col-12">
			<div class="card">
				<Toast/>
				<Toolbar class="mb-4">
					<template v-slot:start>
						<div class="my-2">
							<Button label="Novo" icon="pi pi-plus" class="p-button-success mr-2" @click="openNewDialog" />
							<Button label="Apagar" icon="pi pi-trash" class="p-button-danger" @click="dialogConfirmDeletes = true" :disabled="!selectedMetas || !selectedMetas.length" />
						</div>
					</template>
				</Toolbar>
				<div class="col-12" v-if="loading === true">
					<h4>Carregando Lançamentos de Metas... Aguarde!</h4>
					<lottie-animation
						:path="'animations/load_permission.json'"
						:loop="true"
						:autoPlay="true"
						:speed="1"
						:width="wid"
						:height="hei"
					/>
				</div>
				<DataTable v-else :value="metas" :paginator="true" class="p-datatable-customers" showGridlines :rows="15"
                dataKey="id" v-model:filters="filters1" filterDisplay="menu" :loading="load" responsiveLayout="scroll"
				v-model:selection="selectedMetas" paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" :rowsPerPageOptions="[15,30,50]"
							currentPageReportTemplate="Mostrando {first} de {last} no total de {totalRecords} Lançamentos"
                :globalFilterFields="['mesref','filial','metacupom','metalucroliquid','percentcresc', 'createdAt']">
					<template #header>
						<div class="flex justify-content-between">
							<Button type="button" icon="pi pi-filter-slash" label="Limpar" class="p-button-outlined" @click="clearFilter()"/>
							<span class="p-input-icon-left">
								<i class="pi pi-search" />
								<InputText v-model="filters1['global'].value" placeholder="Procurar...." />
							</span>
						</div>
					</template>
					<template #empty>
						Não existe Metas Lançadas
					</template>
					<template #loading>
						Carregando Lançamentos. Por favor aguarde.
					</template>
					<Column selectionMode="multiple" headerStyle="width: 3rem"></Column>
					<Column field="mesref" header="Mes/Ano" :sortable="true" dataType="date" style="min-width:12rem">
						<template #body="{data}">
							{{formatDateMeRef(data.mesref)}}
						</template>
						<template #filter="{filterModel}">
							<Calendar v-model="filterModel.value" placeholder="mm/yyyy" inputId="monthpicker"  view="month" :showIcon="true" :showButtonBar="true" dateFormat="mm/yy" />
						</template>
					</Column>
					<Column field="filial" header="Filial" :sortable="true" dataType="numeric" style="min-width:12rem">
						<template #body="{data}">
							{{data.filial}}
						</template>
						<template #filter="{filterModel}">
							<InputNumber v-model="filterModel.value" locale="pt-BR" />
						</template>
					</Column>
					<Column field="metacupom" header="Cupom" :sortable="true" dataType="numeric" style="min-width:12rem">
						<template #body="{data}">
							{{data.metacupom}}
						</template>
						<template #filter="{filterModel}">
							<InputNumber v-model="filterModel.value" locale="pt-BR" />
						</template>
					</Column>
					<Column field="metalucroliquid" :sortable="true" header="Lucro Liquido" dataType="numeric" style="min-width:12rem">
						<template #body="{data}">
							{{formatCurrency(data.metalucroliquid)}}
						</template>
						<template #filter="{filterModel}">
							<InputNumber v-model="filterModel.value" mode="currency" currency="BRL" locale="pt-BR" />
						</template>
					</Column>
					<Column field="percentcresc" :sortable="true" header="% Crescimento Cupom" dataType="numeric" style="min-width:12rem">
						<template #body="{data}">
							{{data.percentcresc + "%"}} 
						</template>
						<template #filter="{filterModel}">
							<InputNumber v-model="filterModel.value" locale="pt-BR" />
						</template>
					</Column>
					<Column field="percentcrescll" :sortable="true" header="% Crescimento Lucro" dataType="numeric" style="min-width:12rem">
						<template #body="{data}">
							{{data.percentcrescll + "%"}} 
						</template>
						<template #filter="{filterModel}">
							<InputNumber v-model="filterModel.value" locale="pt-BR" />
						</template>
					</Column>
					<Column field="createdAt" header="Criado em" :sortable="true" dataType="date" style="min-width:12rem">
						<template #body="{data}">
							{{formatDate(data.createdAt)}}
						</template>
						<template #filter="{filterModel}">
							<Calendar v-model="filterModel.value" dateFormat="dd/mm/yy" placeholder="dd/mm/yyyy" />
						</template>
					</Column>
					<Column>
						<template #body="slotProps">
							<Button icon="pi pi-pencil" class="p-button-rounded p-button-success mr-2" @click="openEditDialog(slotProps.data)" />
							<Button icon="pi pi-trash" class="p-button-rounded p-button-warning mt-2" @click="confirmDeleteLanc(slotProps.data)" />
						</template>
					</Column>
				</DataTable>
				<Dialog :header="header" :modal="true" :closable="false" :style="{width: '80vw'}" :breakpoints="{'960px': '50vw', '640px': '75vw'}"  v-model:visible="newDialog">
					<div class="flex col-12 flex-column w-full">
						<label for="data" class="ml-3 text-lg">Mes/Ano</label>
						<Calendar class="col-6 pb-4" inputId="monthpicker" v-model="meta.mesref" view="month" :showIcon="true" :showButtonBar="true" dateFormat="mm/yy" placeholder="Selecione Mes/Ano"/>
						<div class="grid p-fluid flex">
							<div class="field col-12 md:col-2">
								<label for="filial" class="text-lg">Filial</label>
							</div>
							<div class="field col-12 md:col-2 ml-2">
								<label for="cupom" class="text-lg">Meta Cupom</label>
							</div>
							<div class="field col-12 md:col-2">
								<label for="lucro" class="text-lg">Meta Lucro</label>
							</div>
							<div class="field col-12 md:col-2">
								<label for="percentual" class="text-lg">% Crescimento Cupom</label>
							</div>
							<div class="field col-12 md:col-2">
								<label for="percentualll" class="text-lg">% Crescimento Lucro</label>
							</div>
						</div>
						<div class="grid p-fluid flex" v-for="(field, index) in fields" v-bind:key="index">
							<div class="field col-12 md:col-2 mt-0 pt-0">
								<Dropdown v-model="field.filial" :key="index" :options="filiais" optionLabel="name"  placeholder="Selecione uma Filial"/>
							</div>
							<div class="field col-12 md:col-2 mt-0 pt-0">
								<InputNumber v-model="field.metacupom" :key="index" locale="pt-BR" placeholder="Digite a Meta de Cupom"/>
							</div>
							<div class="field col-12 md:col-2 mt-0 pt-0">
								<InputNumber v-model="field.metalucroliquid" :key="index" mode="currency" currency="BRL" locale="pt-BR" placeholder="Digite a Meta de Lucro" />
							</div>
							<div class="field col-12 md:col-2 mt-0 pt-0">
								<InputNumber v-model="field.percentcresc" :key="index" suffix="%" locale="pt-BR" placeholder="Digite o % de Crescimento Cupom"/>
							</div>
							<div class="field col-12 md:col-2 mt-0 pt-0">
								<InputNumber v-model="field.percentcrescll" :key="index" suffix="%" locale="pt-BR" placeholder="Digite o % de Crescimento Lucro"/>
							</div>
							<div class="field flex col-1 mt-0 pt-0">
								<Button v-if="field.filial !== ''" icon="pi pi-minus" class="p-button-danger ml-1" @click="excluirField(index)" />
							</div>
						</div>
						<div class="col-1 mt-0 pt-0">
							<Button icon="pi pi-plus" class="p-button-success" @click="addField" />
						</div>
					</div>
					<template #footer>
						<Button label="Salvar" icon="pi pi-times" class="p-button-text" @click="saveMeta"/>
						<Button label="Cancelar" icon="pi pi-check" class="p-button-text" @click="hideNewDialog" />
					</template>
				</Dialog>
				<Dialog :header="header" :modal="true" :closable="false" :style="{width: '60vw'}" :breakpoints="{'960px': '50vw', '640px': '75vw'}"  v-model:visible="editDialog">
					<div class="flex col-12 flex-column w-full">
						<label for="data" class="ml-2 text-lg">Mes/Ano</label>
						<Calendar class="col-6 pb-4" inputId="monthpicker" v-model="meta.mesref" view="month" :showIcon="true" :showButtonBar="true" dateFormat="mm/yy" placeholder="Selecione Mes/Ano"/>
						<div class="grid p-fluid flex">
							<div class="field col-12 md:col-2">
								<label for="filial" class="text-lg">Filial</label>
								<Dropdown v-model="selectedFilial" :options="filiais" optionLabel="name" />
							</div>
							<div class="field col-12 md:col-2 ml-2">
								<label for="cupom" class="text-lg">Meta Cupom</label>
								<InputNumber v-model="meta.metacupom" locale="pt-BR" />
							</div>
							<div class="field col-12 md:col-2">
								<label for="lucro" class="text-lg">Meta Lucro</label>
								<InputNumber v-model="meta.metalucroliquid" mode="currency" currency="BRL" locale="pt-BR" />
							</div>
							<div class="field col-12 md:col-2">
								<label for="percentual" class="text-lg">% Crescimento Cupom</label>
								<InputNumber v-model="meta.percentcresc" suffix="%" locale="pt-BR" />
							</div>
							<div class="field col-12 md:col-2">
								<label for="percentual" class="text-lg">% Crescimento Lucro</label>
								<InputNumber v-model="meta.percentcrescll" suffix="%" locale="pt-BR" />
							</div>
						</div>						
					</div>
					<template #footer>
						<Button label="Salvar" icon="pi pi-times" class="p-button-text" @click="updateLanc"/>
						<Button label="Cancelar" icon="pi pi-check" class="p-button-text" @click="hideEditDialog" />
					</template>
				</Dialog>
				<Dialog header="Confirmar Exclusão" :modal="true" :closable="false" :style="{width: '30vw'}" :breakpoints="{'960px': '50vw', '640px': '75vw'}"  v-model:visible="dialogConfirmDelete">
					<div class="flex align-items-center justify-content-center">
						<i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
						<span v-if="meta">Tem certeza de que deseja apagar esse Lançamento de Meta?</span>
					</div>
					<template #footer>
						<Button label="Não" icon="pi pi-times" class="p-button-text" @click="dialogConfirmDelete = false"/>
						<Button label="Sim" icon="pi pi-check" class="p-button-text" @click="deleteLanc" />
					</template>
				</Dialog>
				<Dialog header="Confirmar Exclusão" :modal="true" :closable="false" :style="{width: '30vw'}" :breakpoints="{'960px': '50vw', '640px': '75vw'}"  v-model:visible="dialogConfirmDeletes">
					<div class="flex align-items-center justify-content-center">
						<i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
						<span v-if="meta">Tem certeza de que deseja apagar os Lançamentos de Meta Selecionados?</span>
					</div>
					<template #footer>
						<Button label="Não" icon="pi pi-times" class="p-button-text" @click="dialogConfirmDeletes = false"/>
						<Button label="Sim" icon="pi pi-check" class="p-button-text" @click="deleteLancs" />
					</template>
				</Dialog>
			</div>
		</div>
	</div>
</template>

<script>
import {FilterMatchMode, FilterOperator} from 'primevue/api';
import {mapActions} from 'vuex'
import {api} from '../../service/services';
import router from '../../router';
import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue";
export default {
	name: 'LancMetas',
	components: {
      LottieAnimation
  },
	data() {
		return {
			view_lanc_metas: false,
			loading: false,
			load: false,
			wid: 500,
			hei: 600,
			selectedMetas: null,
			meta: {},
			fields: [],
			metas:[],
			filiais:[
				{name: "Filial 1" ,code: 1},
				{name: "Filial 2" ,code: 2}, 
				{name: "Filial 3" ,code: 3}, 
				{name: "Filial 4" ,code: 4}
			],
			selectedFilial: null,
			filters1: {},
			newDialog: false,
			editDialog: false,
			dialogConfirmDelete: false,
			dialogConfirmDeletes: false,
			header: "",
		}
	},
	async created() {
		this.layoutCelular()
		this.loading = true
		await this.getMetas()
		this.initFilters();
		this.metas = this.$store.state.metas.metas
		this.busca()
		this.loading = false
	},
	
	methods: {
		...mapActions('metas', [
			'getMetas'
		]),
		
		busca(){
			const response = JSON.parse(localStorage.getItem("pra"))
			const data = response.filter(
				(arry) => "view_lanc_metas".includes(arry)
			)
			if (data.length > 0){
				this.view_lanc_metas = true
			} else {
				router.push("/error");
			}
		},
		layoutCelular(){
			var largura = window.innerWidth
				if(largura < 750) {
				this.wid = 300
				this.hei = 600
			}
		},
		initFilters() {
			this.filters1 = {
				'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
				'mesref': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.DATE_IS}]},
				'createdAt': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.DATE_IS}]},
				'filial': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.EQUALS}]},
				'metacupom': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.EQUALS}]},
				'metalucroliquid': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.EQUALS}]},
				'percentcresc': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.EQUALS}]},
				'percentcrescll': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.EQUALS}]}
			}
		},
		clearFilter(){
            this.initFilters1();
        },
		formatCurrency(value) {
		return Number(value).toLocaleString('pt-br', {
			style: 'currency',
			currency: 'BRL',
			});
		},
		
		formatDate(value) {
            return value.toLocaleDateString('pt-BR', {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
            });
        },
		formatDateMeRef(value) {
            return value.toLocaleDateString('pt-BR', {
                month: '2-digit',
                year: 'numeric',
            });
        },

		formatDateCreate(data) {
			if (data.length === 10) {
				return data
			} else {
				return data.substr(8,2) + '/' + data.substr(5,2) + '/' + data.substr(0,4);
			}
		},
		openNewDialog(){
			this.fields = []
			this.newDialog = true
			this.header = "Novo Lançamento de Metas"
			this.meta = {}
			this.addField()
		},
		hideNewDialog(){
			this.newDialog = false
			this.header = ""
		},
		openEditDialog(meta){
			this.meta = meta
			const data = this.filiais.filter(i => i.code === meta.filial)
			this.selectedFilial = data[0]
			this.header = "Editando Lançamento de Meta - Criado em: " + this.formatDate(meta.createdAt)
			this.editDialog = true
		},
		hideEditDialog(){
			this.editDialog = false
			this.header = ""
			this.meta = {}
			this.selectedFilial = null
		},
		addField(){
			this.fields.push({
				filial: "",
				metacupom: 0,
				metalucroliquid: 0,
				percentcresc: 0,
				percentcrescll: 0
			});	
		},
		excluirField(index){
			if(this.fields.length === 1){
				this.$toast.add({severity:'warn', summary: 'Alerta de Sistema', detail: 'Não é possível excluir a unica linha do lançamento!', life: 3000});
				return
			}
			var array = this.fields
			var valor = array[index]
			var posicao = array.indexOf(valor)
			if(posicao > -1) {
				array.splice(posicao,1)
			}
		},
		async saveMeta(){
			if(this.meta.mesref === undefined){
				this.$toast.add({severity: 'warn', summary: 'Falta de Preenchimento', detail: 'Por favor selecione uma Mês/Ano para o Lançamento!', life: 5000});
				return
			}
			
			this.fields.forEach(async (i) => {
				if(i.filial.code === undefined ||
					i.metacupom === undefined ||
					i.metalucroliquid === undefined ||
					i.percentcresc === undefined ||
					i.percentcrescll === undefined 
				){
					this.$toast.add({severity: 'warn', summary: 'Falta de Preenchimento', detail: 'Você deve preencher todos os campos do Formulário!', life: 5000});
					return
				}
				const data = {
					mesref: this.meta.mesref,
					filial: i.filial.code,
					metacupom: i.metacupom,
					metalucroliquid: i.metalucroliquid,
					percentcresc: i.percentcresc,
					percentcrescll: i.percentcrescll
				}
				const resp = await api.post("lancmetas", data)
				const newMeta = await resp.data
				newMeta.createdAt = new Date(newMeta.createdAt)
				newMeta.mesref = new Date(newMeta.mesref)
				this.$store.commit('metas/ADD_META', newMeta)
				this.$toast.add({severity:'success', summary: 'Sucesso', detail: 'Lançamento criado com sucesso!', life: 5000});
				this.hideNewDialog()
			})			
		},
		async updateLanc(){
			if(this.meta.mesref === undefined ||
				this.selectedFilial.code === undefined ||
				this.meta.metacupom === undefined ||
				this.meta.metalucroliquid === undefined ||
				this.meta.percentcresc === undefined ||
				this.meta.percentcrescll === undefined
			) {
				this.$toast.add({severity: 'warn', summary: 'Você deve preencher todos os campos do Formulário!', life: 5000});
				return
			}

			const data = {
					mesref: this.meta.mesref,
					filial: this.selectedFilial.code,
					metacupom: this.meta.metacupom,
					metalucroliquid: this.meta.metalucroliquid,
					percentcresc: this.meta.percentcresc,
					percentcrescll: this.meta.percentcrescll,
				}
			const resp = await api.put("lancmetas/" + this.meta.id, data)
			const updateMeta = await resp.data
			updateMeta.createdAt = new Date(updateMeta.createdAt)
			updateMeta.mesref = new Date(updateMeta.mesref)
			this.$store.commit('metas/UPDATE_META', updateMeta)
			this.$toast.add({severity:'success', summary: 'Sucesso', detail: 'Lançamento atualizado com sucesso!', life: 5000});
			this.hideEditDialog()
		},
		deleteLanc(){
			this.dialogConfirmDelete = false
			api.delete("lancmetas/" + this.meta.id)
			this.$store.commit('metas/DELETE_META', this.meta)
			this.$toast.add({severity:'success', summary: 'Sucesso', detail: 'Lançamento apagado com sucesso!', life: 5000});
		},
		deleteLancs(){
			this.dialogConfirmDeletes = false
			this.selectedMetas.forEach((i) => {
				api.delete("lancmetas/" + i.id)
				this.$store.commit('metas/DELETE_META', i)
			})
			this.$toast.add({severity:'success', summary: 'Sucesso', detail: 'Lançamento apagado com sucesso!', life: 5000});
		},
		confirmDeleteLanc(meta){
			this.meta = meta
			this.dialogConfirmDelete = true
		},

	}

}
</script>