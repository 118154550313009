<template>
  <div>
    <Toast ref="alert" />
    <div v-if="loading === true"
      class="surface-card flex align-items-center justify-content-center min-h-screen min-w-screen overflow-hidden">
      <lottie-animation :path="'animations/load_user.json'" :loop="true" :autoPlay="true" :speed="1" :width="wid"
        :height="hei" />
    </div>
    <div v-else
      class="surface-ground flex align-items-center imgback justify-content-center min-h-screen min-w-screen overflow-hidden">
      <div class="flex flex-column align-items-center justify-content-center">
        <div
          style="border-radius: 56px; padding: 0.3rem; background: linear-gradient(180deg, #00ACA9 10%, rgba(41, 219, 101, 0) 30%)">
          <div class="w-full surface-card py-8 px-5 sm:px-8" style="border-radius: 53px;">
            <div class="text-center mb-5">
              <img :src="this.urlimage" alt="Imagem" height="150" class="mb-3" />
            </div>

            <div>
              <label for="email1" class="block text-900 text-xl font-medium mb-2">Email</label>
              <InputText id="email1" type="text" placeholder="Digite seu E-mail" class="w-full mb-5"
                style="padding: 1rem" v-model="user.email" v-on:keyup.enter="login" />

              <label for="password1" class="block text-900 font-medium text-xl mb-2">Password</label>
              <Password id="password1" v-model="user.password" placeholder="Digite sua Senha" :toggleMask="true"
                class="w-full mb-3" inputClass="w-full" :feedback="false" :inputStyle="{ padding: '1rem' }"
                v-on:keyup.enter="login"></Password>

              <div class="flex align-items-center justify-content-between mb-5 gap-5">
                <a class="font-medium no-underline ml-2 text-right cursor-pointer" style="color: var(--primary-color)"
                  v-on:click="forgetPass">Esqueceu a Senha?</a>
              </div>
              <Button v-if="!loading" label="Acessar" class="w-full p-3 text-xl" style="background: #00ACA9"
                v-on:click="login"></Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { api } from '../service/services';
import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue";
export default {
  name: "Login",
  components: {
    LottieAnimation
  },
  data() {
    return {
      roles: [],
      urlimage: api.getUrl() + "/images/client/" + process.env.VUE_APP_EMPRESA_IMAGE,
      user: {
        email: "",
        password: "",
      },
      loading: false,
      wid: 600,
      hei: 700
    }
  },
  computed: {
    logoColor() {
      if (this.$appState.darkTheme) return 'white';
      return 'dark';
    }
  },
  async created() {
  },

  methods: {
    forgetPass() {
      this.$router.replace({ name: "Forgetpass" });
    },
    async login() {
      try {
        this.loading = true;
        if (this.user.email === "" || this.user.password === "") {
          let inputs = document.querySelectorAll("input");
          inputs[0].style.borderColor = "red";
          inputs[1].style.borderColor = "red";
          this.$toast.add({ severity: 'error', summary: 'Mensagem de Erro', detail: 'Por favor preencha os campos E-mail e Senha', life: 3000 });
          this.loading = false;
          return;
        }
        console.log("começou")
        const response = await this.$store.dispatch("loginUser", {
          email: this.user.email,
          password: this.user.password,
        });
        const data = await response.data;

        if (data.status === "error") {
          this.$toast.add({ severity: 'error', summary: 'Mensagem de Erro', detail: data.message, life: 3000 });
          this.loading = false;
          return;
        }

        let roles_id = [];
        data.regras.forEach((role) => {
          roles_id.push(role.role.role)
        }),

          window.localStorage.token_ri = "Bearer " + data.token;
        window.localStorage.id_ri = data.user.uuid;
        this.$store.commit("UPDATE_PAGEACCESS", roles_id);
        window.localStorage.setItem("pra", JSON.stringify(roles_id));
        await api.post("logs", {
          company: process.env.VUE_APP_EMPRESA_API || "ri",
          user_id: data.user.uuid,
          page: "Login",
          action: "Realizou login no sistema",
          description: `O Usuário ${data.user.name} realziou o login no sistema RI da Empresa ${process.env.VUE_APP_EMPRESA_API || "ri"}`
        })


        await this.$store.dispatch("getUser", data.user.uuid);

        if (data.user.firstaccess === 0) {
          this.loading = false
          this.$router.replace({ name: "Resetpass" });
          return;
        }

        this.$router.replace({ name: "Home" });
        //this.loading = false

      } catch (err) {
        this.$router.replace({ name: "Login" });
        this.loading = false;
        this.$toast.add({ severity: 'error', summary: 'Mensagem de Erro', detail: 'Erro ao Efetuar o Login' + err, life: 3000 });

      }
    },
  },
}
</script>

<style scoped>
body {
  margin: 0;
}

.imgback {
  height: 100vh;
  width: 100%;
  margin: 0px;
  padding: 0px;
  background-image: url("../assets/background.jpeg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  background-attachment: scroll;
}
</style>