<template>
	<div class="grid" v-show="view_users">
		<div class="col-12">
			<div class="card">
				<Toast />
				<Toolbar class="mb-4">
					<template v-slot:start>
						<div class="my-2">
							<Button label="Novo" icon="pi pi-plus" class="p-button-success mr-2" @click="openNew" />
							<Button label="Apagar" icon="pi pi-trash" class="p-button-danger"
								@click="deleteUsersDialog = true" :disabled="!selectedUsers || !selectedUsers.length" />
						</div>
					</template>

					<!-- <template v-slot:end>
						<FileUpload mode="basic" accept="image/*" :maxFileSize="1000000" label="Import" chooseLabel="Import" class="mr-2 inline-block" />
						<Button label="Export" icon="pi pi-upload" class="p-button-help" @click="exportCSV($event)"  />
					</template> -->
				</Toolbar>
				<div class="col-12" v-if="loading === true">
					<h4>Carregando Usuários... Aguarde!</h4>
					<lottie-animation :path="'animations/load_user.json'" :loop="true" :autoPlay="true" :speed="1"
						:width="wid" :height="hei" />
				</div>

				<DataTable v-else ref="dt" :value="users" v-model:selection="selectedUsers" dataKey="uuid"
					:paginator="true" :rows="10" :filters="filters" :rowHover="true" class="p-datatable-gridlines"
					:loading="load"
					paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
					:rowsPerPageOptions="[5, 10, 25]"
					currentPageReportTemplate="Mostrando {first} de {last} no total de {totalRecords} Usuários"
					responsiveLayout="scroll">
					<template #header>
						<div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
							<h5 class="m-0">Gerenciar Usuários</h5>
							<span class="block mt-2 md:mt-0 p-input-icon-left">
								<i class="pi pi-search" />
								<InputText v-model="filters['global'].value" placeholder="Search..." />
							</span>
						</div>
					</template>
					<template #empty>
						Não existe Usuários Cadastrados.
					</template>
					<Column selectionMode="multiple" headerStyle="width: 3rem"></Column>
					<Column field="name" header="Nome" :sortable="true" headerStyle="width:15%; min-width:10rem;">
						<template #body="slotProps">
							<img :alt="slotProps.data.name" :src="urlImg + slotProps.data.urlAvatar" width="32"
								style="vertical-align: middle; border-radius: 50%" />
							<span style="margin-left: .5em; vertical-align: middle"
								class="image-text">{{ slotProps.data.name }}</span>

							<!-- <span class="p-column-title">Name</span>
							{{slotProps.data.name}} -->
						</template>
					</Column>
					<Column field="email" header="Email" :sortable="true" headerStyle="width:20%; min-width:15rem;">
						<template #body="slotProps">
							<span class="p-column-title">E-mail</span>
							{{ slotProps.data.email }}
						</template>
					</Column>
					<Column field="active" header="Status" :sortable="true" headerStyle="width:10%; min-width:5rem;">
						<template #body="slotProps">
							<span class="p-column-title">Active</span>
							<Tag v-if="slotProps.data.active === 1" class="mr-2" icon="pi pi-check" severity="success"
								value="Ativo"></Tag>
							<Tag v-else class="mr-2" icon="pi pi-times" severity="danger" value="Inativo"></Tag>
						</template>
					</Column>
					<Column field="createdAt" header="Criado em" :sortable="true"
						headerStyle="width:15%; min-width:10rem;">
						<template #body="slotProps">
							<span class="p-column-title">createdAt</span>
							{{ slotProps.data.createdAt }}
						</template>
					</Column>
					<Column field="lastlogin" header="Ult Acesso" :sortable="true"
						headerStyle="width:15%; min-width:10rem;">
						<template #body="slotProps">
							<span class="p-column-title">lastlogin</span>
							{{ slotProps.data.lastlogin }}
						</template>
					</Column>
					<Column>
						<template #body="slotProps">
							<Button icon="pi pi-pencil" class="p-button-rounded p-button-success mr-2"
								@click="editUser(slotProps.data)" />
							<Button icon="pi pi-trash" class="p-button-rounded p-button-warning mt-2"
								@click="confirmDeleteUser(slotProps.data)" />
						</template>
					</Column>
				</DataTable>
				<Dialog v-model:visible="userCreateDialog" :style="{ width: '600px' }" header="Novo Usuário" :modal="true"
					class="p-fluid">
					<div class="field col-12 md:col-12">
						<h6>Nome do Usuário</h6>
						<span class="p-input-icon-left">
							<i class="pi pi-user" />
							<InputText type="text" placeholder="Digite o nome do usuário" id="name"
								v-model.trim="user.name" required="true" autofocus
								:class="{ 'p-invalid': submitted && !user.name }" />
						</span>
					</div>
					<div class="field col-12 md:col-12">
						<h6>E-mail do Usuário</h6>
						<span class="p-input-icon-left">
							<i class="pi pi-envelope" />
							<InputText type="text" placeholder="Digite o nome do usuário" id="email"
								v-model.trim="user.email" required="true" autofocus
								:class="{ 'p-invalid': submitted && !user.email }" />
						</span>
					</div>
					<div class="field">
						<h6>Permissão desse Usuário</h6>
						<Dropdown v-model="dropdownValue" :options="dropdownValues" optionLabel="name"
							placeholder="Selecione uma Permissão" required="true" />
					</div>
					<template #footer>
						<Button label="Cancelar" icon="pi pi-times" class="p-button-text" @click="hideCreateDialog" />
						<Button label="Salvar" icon="pi pi-check" class="p-button-text" @click="createUser" />
					</template>
				</Dialog>
				<Dialog v-model:visible="deleteUserDialog" :style="{ width: '450px' }" header="Confirmação" :modal="true">
					<div class="flex align-items-center justify-content-center">
						<i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
						<span v-if="user">Tem certeza de que deseja excluir o Usuário <b>{{ user.name }}</b>?</span>
					</div>
					<template #footer>
						<Button label="Não" icon="pi pi-times" class="p-button-text"
							@click="deleteUserDialog = false" />
						<Button label="Sim" icon="pi pi-check" class="p-button-text" @click="deleteUser" />
					</template>
				</Dialog>
				<Dialog v-model:visible="deleteUsersDialog" :style="{ width: '450px' }" header="Confirmação"
					:modal="true">
					<div class="flex align-items-center justify-content-center">
						<i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
						<span v-if="user">Tem certeza de que deseja excluir os Usuários selecionados?</span>
					</div>
					<template #footer>
						<Button label="Não" icon="pi pi-times" class="p-button-text"
							@click="deleteUsersDialog = false" />
						<Button label="Sim" icon="pi pi-check" class="p-button-text" @click="deleteSelectedUsers" />
					</template>
				</Dialog>
				<Dialog v-model:visible="userDialog" :style="{ width: '80vw' }" header="Editar Usuário" :modal="true"
					class="p-fluid">
					<div class="border-round border-1 surface-border p-4" v-if="load === true">
						<div class="flex mb-3">
							<Skeleton shape="circle" size="4rem" class="mr-2"></Skeleton>
							<div>
								<Skeleton width="10rem" class="mb-2"></Skeleton>
								<Skeleton width="5rem" class="mb-2"></Skeleton>
								<Skeleton height=".5rem"></Skeleton>
							</div>
						</div>
						<Skeleton width="100%" height="150px"></Skeleton>
						<div class="flex justify-content-between mt-3">
							<Skeleton width="4rem" height="2rem"></Skeleton>
							<Skeleton width="4rem" height="2rem"></Skeleton>
						</div>
					</div>
					<div v-else>
						<div class="flex align-items-center justify-content-center">
							<div class="field col-10 md:col-10">
								<img :alt="user.name" :src="urlImg + user.urlAvatar" width="128"
									style="vertical-align: middle; border-radius: 50%" />
							</div>
						</div>
						<div class="flex align-items-center justify-content-center">
							<div class="field col-5 md:col-5 mb-2">
								<div class="field col-10 md:col-10 mb-2">
									<label for="name">Nome do Usuário</label>
									<InputText id="name" v-model.trim="user.name" required="true" autofocus
										:class="{ 'p-invalid': submitted && !user.name }" />
								</div>
								<div class="field col-10 md:col-10 mb-2">
									<label for="email">E-mail do Usuario</label>
									<InputText :disabled="visible" id="email" v-model.trim="user.email" required="true"
										autofocus :class="{ 'p-invalid': submitted && !user.role }" />
								</div>
							</div>
							<div class="field col-5 md:col-5 mb-2">
								<div class="field col-10 md:col-10 mb-2">
									<label for="permissions">Permissão do Usuário</label>
									<Dropdown id="id" v-model="dropdownValue" :options="dropdownValues"
										optionLabel="name" placeholder="Permissão do Usuário" required="true" />
								</div>
								<div class="field col-10 md:col-10">
									<label for="permissions" v-show="false">Grupo de Relatorios</label>
									<Dropdown id="id" v-model="dropdownGroup" :options="dropdownGroups"
										optionLabel="name" placeholder="Grupo de Relatorio" v-show="false" />
								</div>
							</div>
						</div>
						<div class="flex align-items-center justify-content-left ml-8">
							<div class="field-checkbox mb-3">
								<div class="field-checkbox mb-3">
									<InputSwitch v-model="switchValue" />
									<label for="active">Usuário Ativo?</label>
								</div>
								<!-- <div class="field-checkbox mb-3 ml-3" v-show="superAdmin">
									<InputSwitch v-model="switchValueAdmin" />
									<label for="active">Usuário Super-Admin?</label>
								</div> -->
							</div>
						</div>
						<div class="card col-6">
							<span class="p-buttonset ">
								<Button class="p-button-success" label="Relatórios" icon="pi pi-chart-bar"
									@click="selectedRepot" />
								<Button class="p-button-info" label="Paineis" icon="pi pi-chart-line"
									@click="selectedDash" />
								<Button class="p-button-secondary" label="Conjunto de Dados" icon="pi pi-database"
									@click="selectedDataset" />
							</span>
						</div>
						<div class="flex flex-row md:flex-column justify-content-center"
							v-if="pickSelectedReport === true">
							<h5>Relatorios Permitidos para esse Usuário</h5>
							<div class="card">
								<h5>Desktop</h5>
								<PickList v-model="picklistReportDesktop" listStyle="height:200px" dataKey="role">
									<template #sourceheader>
										From
									</template>
									<template #targetheader>
										To
									</template>
									<template #item="slotProps">
										<div>{{ slotProps.item.namemenu }}</div>
									</template>
								</PickList>
							</div>
							<div class="card">
								<h5>Celular</h5>
								<PickList v-model="picklistReportCeluar" listStyle="height:200px" dataKey="role">
									<template #sourceheader>
										From
									</template>
									<template #targetheader>
										To
									</template>
									<template #item="slotProps">
										<div>{{ slotProps.item.namemenu }}</div>
									</template>
								</PickList>
							</div>
						</div>
						<div class="flex flex-row md:flex-column justify-content-center"
							v-else-if="pickSelectedDash === true">
							<h5>Paineis Permitidos para esse Usuário</h5>
							<div class="card">
								<h5>Desktop</h5>
								<PickList v-model="picklistDashboardDesktop" listStyle="height:200px" dataKey="role">
									<template #sourceheader>
										From
									</template>
									<template #targetheader>
										To
									</template>
									<template #item="slotProps">
										<div>{{ slotProps.item.namemenu }}</div>
									</template>
								</PickList>
							</div>
						</div>
						<div class="flex flex-row md:flex-column justify-content-center"
							v-else-if="pickSelectedDataset === true">
							<h5>Conjuntos de Dados Permitidos para esse Usuário</h5>
							<div class="card">
								<h5>Desktop</h5>
								<PickList v-model="picklistDatasetDesktop" listStyle="height:200px" dataKey="role">
									<template #sourceheader>
										From
									</template>
									<template #targetheader>
										To
									</template>
									<template #item="slotProps">
										<div>{{ slotProps.item.namemenu }}</div>
									</template>
								</PickList>
							</div>
						</div>
					</div>
					<template #footer>
						<Button label="Cancelar" icon="pi pi-times" class="p-button-text" @click="hideUserDialog" />
						<Button label="Salvar" icon="pi pi-check" class="p-button-text" @click="saveUser" />
						<Button label="Resetar Senha" icon="pi pi-key" class="p-button-text" v-show="resetpass"
							@click="hideUserDialog" />
					</template>
				</Dialog>
			</div>
		</div>
	</div>
</template>

<script>
import router from '../../router';
import { api } from '../../service/services';
import { FilterMatchMode } from 'primevue/api';
import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue";
export default {
	name: 'Users',
	components: {
		LottieAnimation
	},
	data() {
		return {
			view_users: false,
			superAdmin: false,
			users: null,
			loading: false,
			userDialog: false,
			deleteUserDialog: false,
			deleteUsersDialog: false,
			userCreateDialog: false,
			user: {},
			userCurrent: {},
			selectedUsers: [],
			filters: {},
			visible: false,
			resetpass: false,
			submitted: false,
			switchValue: false,
			switchValueAdmin: false,
			dropdownValues: [],
			dropdownValue: {},
			dropdownGroups: [],
			dropdownGroup: {},
			picklistReportDesktop: [[], []],
			picklistReportCeluar: [[], []],
			picklistDashboardDesktop: [[], []],
			picklistDatasetDesktop: [[], []],
			urlImg: api.getUrl() + "/images/",
			pickSelectedReport: true,
			pickSelectedDash: false,
			pickSelectedDataset: false,
			wid: 500,
			hei: 600,
			load: false
		}
	},

	async created() {
		this.layoutCelular()
		this.initFilters()
		this.loading = true
		this.busca()
		await this.getUser()
		await this.getPermission()
		this.loading = false
	},

	methods: {
		busca() {
			const response = JSON.parse(localStorage.getItem("pra"))
			const data = response.filter(
				(arry) => "view_user".includes(arry)
			)
			if (data.length > 0) {
				this.view_users = true
			} else {
				router.push("/error");
			}
		},
		layoutCelular() {
			var largura = window.innerWidth
			if (largura < 750) {
				this.wid = 300
				this.hei = 600
			}
		},
		async getUser() {
			const resp = await api.getUserCurrent()
			this.userCurrent = resp

			const response = await api.get("users");
			const data = await response.data;

			const filtro = data.filter(
				(item) => item.admin === 0
			)
			if (this.userCurrent.admin === 1) {
				this.users = data
				this.superAdmin = true
			} else {
				this.users = filtro
				this.superAdmin = false
			}
		},
		async getPermission() {
			const response = await api.get("permissions");
			const data = await response.data;
			this.dropdownValues = data
		},
		async getGroup() {
			const response = await api.get("groups");
			const data = await response.data;
			this.dropdownGroups = data
		},
		async getDashboards() {
			const response = await api.get("dashboards");
			const data = await response.data;
			const desktop = data.filter(
				(item) => item.active === 1
			)
			this.picklistDashboardDesktop[0] = desktop
		},
		async getDatasets() {
			const response = await api.get("datasets");
			const data = await response.data;
			const desktop = data.filter(
				(item) => item.active === 1
			)
			this.picklistDatasetDesktop[0] = desktop
		},
		async getReports() {
			const response = await api.get("reports");
			const data = await response.data;
			const desktop = data.filter(
				(item) => item.active === 1 && item.phone === 0
			)
			const celular = data.filter(
				(item) => item.active === 1 && item.phone === 1
			)
			this.picklistReportDesktop[0] = desktop
			this.picklistReportCeluar[0] = celular
		},
		initFilters() {
			this.filters = {
				'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
			}
		},
		openNew() {
			this.user = {};
			this.submitted = false;
			this.userCreateDialog = true;
			this.dropdownValue = {}
		},
		async editUser(user) {
			this.user = { ...user };
			this.userDialog = true;
			this.load = true
			this.visible = true
			await this.getReports()
			await this.getDashboards()
			await this.getDatasets()
			let reportD_id = []
			let reportC_id = []
			let dashboardD_id = []
			let datasetD_id = []

			this.user.ReportsUsers.forEach((report) => {
				if (report.report.phone === 0 && report.report.active === 1) {
					reportD_id.push(report.report)
				}
			})
			this.user.ReportsUsers.forEach((report) => {
				if (report.report.phone === 1 && report.report.active === 1) {
					reportC_id.push(report.report)
				}
			})
			this.user.DashboardsUsers.forEach((dashboard) => {
				if (dashboard.dashboard.active === 1) {
					dashboardD_id.push(dashboard.dashboard)
				}
			})
			this.user.DatasetsUsers.forEach((dataset) => {
				if (dataset.dataset.active === 1) {
					datasetD_id.push(dataset.dataset)
				}
			})

			const permission = this.dropdownValues.filter(
				(item) => item.id === user.id_permission
			)
			const group = this.dropdownGroups.filter(
				(item) => item.id_user === user.uuid
			)
			this.dropdownValue = permission[0]
			this.dropdownGroup = group[0]
			this.picklistReportDesktop[1] = reportD_id
			this.picklistReportCeluar[1] = reportC_id
			this.picklistDashboardDesktop[1] = dashboardD_id
			this.picklistDatasetDesktop[1] = datasetD_id
			if (this.user.active === 1) {
				this.switchValue = true
			}
			if (user.firstaccess === 1) {
				this.resetpass = true
			}
			if (user.admin === 1) {
				this.switchValueAdmin = true
			}
			this.load = false
		},
		confirmDeleteUser(user) {
			this.user = user;
			this.deleteUserDialog = true;
		},
		hideCreateDialog() {
			this.userCreateDialog = false;
			this.submitted = false;
		},
		hideUserDialog() {
			this.userDialog = false;
			this.visible = false;
			this.switchValueAdmin = false
			this.switchValue = false
			this.dropdownValue = {}
			this.resetpass = false
			this.picklistReportDesktop[1] = []
			this.picklistReportCeluar[1] = []
			this.picklistDashboardDesktop[1] = []
			this.picklistDatasetDesktop[1] = []
			this.pickSelectedReport = true,
				this.pickSelectedDash = false,
				this.pickSelectedDataset = false

		},

		async saveUser() {
			this.submitted = true;
			this.load = true
			try {
				let reportD_id = []
				let reportC_id = []
				let dashboardD_id = []
				let datasetD_id = []
				let id = this.user.uuid
				this.user.ReportsUsers.forEach((report) => {
					if (report.report.phone === 0) {
						reportD_id.push(report.report)
					}
				})
				this.user.ReportsUsers.forEach((report) => {
					if (report.report.phone === 1) {
						reportC_id.push(report.report)
					}
				})
				this.user.DashboardsUsers.forEach((dashboard) => {
					if (dashboard.dashboard.active === 1) {
						dashboardD_id.push(dashboard.dashboard)
					}
				})
				this.user.DatasetsUsers.forEach((dataset) => {
					if (dataset.dataset.active === 1) {
						datasetD_id.push(dataset.dataset)
					}
				})
				if (this.switchValue === true) {
					this.user.active = 1
				} else {
					this.user.active = 0
				}
				if (this.switchValueAdmin === true) {
					this.user.admin = 1
				} else {
					this.user.admin = 0
				}
				const equalsD = this.picklistReportDesktop[1].filter(
					(arr1Item) => !reportD_id.includes(arr1Item));
				if (equalsD.length > 0) {
					equalsD.forEach(async (add) => {
						await api.post("users/ur", {
							id_user: id,
							id_report: add.id
						});
					})
				}

				const equalsC = this.picklistReportCeluar[1].filter(
					(arr1Item) => !reportC_id.includes(arr1Item));
				if (equalsC.length > 0) {
					equalsC.forEach(async (add) => {
						await api.post("users/ur", {
							id_user: id,
							id_report: add.id
						});
					})
				}

				const equalsDashD = this.picklistDashboardDesktop[1].filter(
					(arr1Item) => !dashboardD_id.includes(arr1Item));
				if (equalsDashD.length > 0) {
					equalsDashD.forEach(async (add) => {
						await api.post("users/uda", {
							id_user: id,
							id_dashboard: add.id
						});
					})
				}

				const equalsDatasetD = this.picklistDatasetDesktop[1].filter(
					(arr1Item) => !datasetD_id.includes(arr1Item));
				if (equalsDatasetD.length > 0) {
					equalsDatasetD.forEach(async (add) => {
						await api.post("users/udt", {
							id_user: id,
							id_dataset: add.id
						});
					})
				}

				const diffD = reportD_id.filter(
					(arr1Item) => !this.picklistReportDesktop[1].includes(arr1Item));
				if (diffD.length > 0) {
					diffD.forEach(async (remove) => {
						const direportD = this.user.ReportsUsers.filter(
							(item) => item.id_user === id && item.id_report === remove.id
						)
						await api.delete("users/ur/" + direportD[0].id);
					})
				}

				const diffC = reportC_id.filter(
					(arr1Item) => !this.picklistReportCeluar[1].includes(arr1Item));
				if (diffC.length > 0) {
					diffC.forEach(async (remove) => {
						const direportC = this.user.ReportsUsers.filter(
							(item) => item.id_user === id && item.id_report === remove.id
						)
						await api.delete("users/ur/" + direportC[0].id);
					})
				}

				const diffDashboard = dashboardD_id.filter(
					(arr1Item) => !this.picklistDashboardDesktop[1].includes(arr1Item));
				if (diffDashboard.length > 0) {
					diffDashboard.forEach(async (remove) => {
						const didashboardD = this.user.DashboardsUsers.filter(
							(item) => item.id_user === id && item.id_dashboard === remove.id
						)
						await api.delete("users/uda/" + didashboardD[0].id);
					})
				}

				const diffDataset = datasetD_id.filter(
					(arr1Item) => !this.picklistDatasetDesktop[1].includes(arr1Item));
				if (diffDataset.length > 0) {
					diffDataset.forEach(async (remove) => {
						const didatasetD = this.user.DatasetsUsers.filter(
							(item) => item.id_user === id && item.id_dataset === remove.id
						)
						await api.delete("users/udt/" + didatasetD[0].id);
					})
				}

				await api.put("users/" + this.user.uuid, {
					name: this.user.name,
					permission_id: this.dropdownValue.id,
					active: this.user.active,
					admin: this.user.admin
				})

				this.$toast.add({ severity: 'success', summary: 'Sucesso', detail: 'Usuario Atualizado com Sucesso', life: 3000 });
				this.hideUserDialog()
				this.submitted = false;
				this.user = {}
				this.users = null
				await this.getUser()
			} catch (err) {
				this.$toast.add({ severity: 'error', summary: 'Erro', detail: 'Usuário não Atualizado ' + err, life: 3000 });
				this.hideUserDialog()
				this.submitted = false;
				this.user = {}
				this.users = null
				await this.getUser()
			}
			this.load = false
		},

		async createUser() {
			this.userCreateDialog = false;
			this.submitted = false;
			this.load = true
			try {
				await api.post("users", {
					name: this.user.name,
					email: this.user.email,
					permission_id: this.dropdownValue.id
				})
				this.$toast.add({ severity: 'success', summary: 'Sucesso', detail: 'Usuario Cadastrado com Sucesso', life: 3000 });
				this.user = {}
				this.users = null
				await this.getUser()
			} catch (err) {
				this.user = {}
				this.$toast.add({ severity: 'error', summary: 'Erro', detail: 'Usuário não Cadastrado ' + err, life: 3000 });
			}
			this.load = false
		},
		async deleteUser() {
			this.deleteUserDialog = false;
			this.load = true
			try {
				await api.delete("users/" + this.user.uuid)
				this.user = {};
				this.users = null
				this.$toast.add({ severity: 'success', summary: 'Sucesso', detail: 'Usuario Apagado com Sucesso', life: 3000 });
				await this.getUser()
			} catch (err) {
				this.user = {};
				this.users = null
				this.$toast.add({ severity: 'error', summary: 'Erro', detail: 'Usuário não foi Apagado ' + err, life: 3000 });
				await this.getUser()
			}
			this.load = false
		},
		async deleteSelectedUsers() {
			this.deleteUsersDialog = false;
			this.laod = true
			try {
				await this.selectedUsers.forEach(async (user) => {
					await api.delete("users/" + user.uuid)
				})
				this.selectedUsers = null;
				this.user = {};
				this.users = null
				this.$toast.add({ severity: 'success', summary: 'Sucesso', detail: 'Usuários apagados', life: 3000 });
				await this.getUser()
			} catch (err) {
				this.selectedUsers = null;
				this.user = {};
				this.users = null
				this.$toast.add({ severity: 'error', summary: 'Erro', detail: 'Usuários não Apagadas ' + err, life: 3000 });
				await this.getUser()
			}
			this.load = false
		},
		selectedRepot() {
			this.pickSelectedReport = true
			this.pickSelectedDash = false
			this.pickSelectedDataset = false
		},
		selectedDash() {
			this.pickSelectedReport = false
			this.pickSelectedDash = true
			this.pickSelectedDataset = false
		},
		selectedDataset() {
			this.pickSelectedReport = false
			this.pickSelectedDash = false
			this.pickSelectedDataset = true
		},
	}
}
</script>