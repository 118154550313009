<template>
	<div class="col-15"  v-show="view_dashboards">
		<Button v-if="!celular" label="Tela Cheia" icon="pi pi-window-maximize" class="p-button-rounded p-button-success p-button-text mr-2 mb-2" v-on:click="fullScreen" />
    <Button v-else icon="pi pi-window-maximize" class="p-button-rounded p-button-success p-button-text mr-2 mb-2" v-on:click="fullScreen" />
    <!-- <Button v-if="!celular" v-show="print_reports" label="Imprimir" icon="pi pi-print" class="p-button-rounded p-button-success p-button-text mr-2 mb-2" v-on:click="reportPrint" />
    <Button v-show="print_reports" v-else icon="pi pi-print" class="p-button-rounded p-button-success p-button-text mr-2 mb-2" v-on:click="reportPrint" /> -->
    <div id="dashboardContainer"></div>
	</div>
</template>
<script>
import router from '../../router';
import {api} from '../../service/services';
import * as pbi from "powerbi-client";
import * as pbimodels from "powerbi-models";
import { mapState } from "vuex";
export default {
    name: 'EmbededDashboard',
data(){
	return {
    view_dashboards: false,
		dashboard: {},
    print_reports: true,
    celular: false,
    pageView: "fitToWidth"
		};
  },
  props: {
    id: String,
  },
computed: mapState(["user"]),
async created() { 
	this.busca()
  this.layout()
	await this.getDashboards();
	},
	
methods:{
	busca(){
		const response = JSON.parse(localStorage.getItem("pra"))
		const data = response.filter(
			(arry) => "view_dashboard".includes(arry)
		)
		const data2 = response.filter(
			(arry) => "print_report".includes(arry)
		)
		if (data.length > 0){
			this.view_dashboards = true
		} else {
			router.push("/error");
		}
    if (data2.length > 0){
			this.print_reports = true
		}
	},

  async getDashboards(){
    var models = pbimodels;
        const token = await api.get("token");
				const tmj = token.data

      var config = {
        type: "dashboard",
        id: this.id,
        embedUrl: "https://app.powerbi.com/dashboardEmbed?dashboardId=" + this.id,
        accessToken: tmj,
        tokenType: models.TokenType.Aad,
        pageView: this.pageView, // choices are "actualSize", "fitToWidth" or "oneColumn"
      };

      var dashboardContainer = document.getElementById("dashboardContainer");

      let powerbi = new pbi.service.Service(
        pbi.factories.hpmFactory,
        pbi.factories.wpmpFactory,
        pbi.factories.routerFactory
      );
  
      powerbi.reset(dashboardContainer);
      this.dashboard = powerbi.embed(dashboardContainer, config);
  },

  fullScreen() {
    this.dashboard.fullscreen();
  },

  async reportPrint() {
      try {
        await this.dashboard.print();
      } 
      catch (errors) {
          console.log(errors);
      }
    },
    async reportRefresh() {
      try {
        await this.dashboard.refresh();
      } 
      catch (errors) {
          console.log(errors);
      }
    },
    layout(){
      var largura = window.innerWidth
      if(largura < 750) {
        this.celular = true
        this.pageView = "oneColumn"
      }
    }
  }
}

</script>
<style scoped lang="scss">

 #dashboardContainer {
    height: 100vh;
  }
</style>