<template>
	<div class="grid" v-show="view_datasets">
		<div class="col-12">
			<div class="card">
				<div class="col-12" v-if="loading === true">
					<h4>Carregando Conjuntos de Dados... Aguarde!</h4>
					<lottie-animation
						:path="'animations/load_dataset.json'"
						:loop="true"
						:autoPlay="true"
						:speed="1"
						:width="wid"
						:height="hei"
					/>
				</div>
				<DataView v-else :value="datasets" :layout="layout" :paginator="true" :rows="12" :Loading="loading">
					<template #header>
						<div class="grid grid-nogutter">
							<div class="col-6 text-left">
								<h4>Listagem de Conjuntos de Dados</h4>
								<!-- <Dropdown v-model="sortKey" :options="sortOptions" optionLabel="label" placeholder="Classificar por" @change="onSortChange($event)"/> -->
							</div>
							<div class="col-6 text-right">								
								<DataViewLayoutOptions v-show="!celular" v-model="layout" />
							</div>
						</div>
					</template>
					<template #list="slotProps">
						<div class="col-12" v-show="!celular">
							<div class="flex flex-column md:flex-row align-items-center p-3 w-full">
								<router-link :to="{ name: 'Dataset', params: { id: slotProps.data.id }}">
									<img :src="'images/views/datasets-icon.png'" :alt="slotProps.data.dataset.namemenu" class="my-4 md:my-0 w-5 md:w-3rem shadow-2 mr-5"/>
								</router-link>
								<div class="flex-1 text-center md:text-left">
									<router-link :to="{ name: 'Dataset', params: { id: slotProps.data.dataset.id }}">
										<div class="flex-1 text-center md:text-left">
										<div class="font-regular text-2xl" >{{slotProps.data.dataset.namemenu}}</div>
										</div>
									</router-link>
								</div>
								<div class="flex flex-row md:flex-column justify-content-between w-full md:w-auto align-items-center md:align-items-end mt-5 md:mt-0" >
									<span class="text-2xl font-semibold mb-2 align-self-center md:align-self-end"></span>
									<Button icon="pi pi-eye" :disabled="false" class="mb-2" v-on:click="viewDataset(slotProps.data.id)"></Button>
								</div>
							</div>
						</div>
					</template>

					<template #grid="slotProps">
						<div class="col-12 md:col-3">
							<div class="card m-1 border-1 surface-border">
								<div class="text-center">
									<router-link :to="{ name: 'Dataset', params: { id: slotProps.data.dataset.id }}">
										<img :src="'images/views/datasets-icon.png'" :alt="slotProps.data.namemenu" class="w-2 shadow-2 my-2 mx-0"/>
										<div class="text-2x1 font-regular" >{{slotProps.data.dataset.namemenu}}</div>
									</router-link>
								</div>
								<div class="flex align-items-center justify-content-between mt-3">
									<Button icon="pi pi-eye" :disabled="false" class="p-button-rounded p-button-help p-button-text mr-2 mb-2" v-on:click="viewDataset(slotProps.data.dataset.id)" v-show="edit_datasets"></Button>
								</div>
							</div>
						</div>
					</template>
				</DataView>
			</div>
		</div>
	</div>
</template>

<script>
import router from '../../router';
import {api} from '../../service/services';
import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue";
export default {
    name: 'Datasets',
	components: {
      LottieAnimation
	},
	data(){
		return {
			view_datasets: false,
			layout: 'grid',
			datasets: null,
			loading: false,
			edit_datasets: false,
			celular: false,
			wid: 600,
			hei: 700,
		}
	},

	async created() {
		this.layoutCelular()
		this.loading = true
		await this.busca()
		await this.getDataset()
		this.loading = false
		},
	
	methods:{
		busca(){
			const response = JSON.parse(localStorage.getItem("pra"))
			const data = response.filter(
				(arry) => "view_dataset".includes(arry)
			)
			if (data.length > 0){
				this.view_datasets = true
			} else {
				router.push("/error");
			}
		},
		async getDataset(){
			this.loading = true
			
			const response = await api.get("users/" + window.localStorage.getItem("id_ri"));
			const data = await response.data.DatasetsUsers;	
			
			data.sort(function (a,b) { 
				return (a.dataset.namemenu > b.dataset.namemenu) ? 1 : ((b.dataset.namemenu > a.dataset.namemenu) ? -1 : 0)
			})
			
			const ativo = data.filter(
				(item) => item.dataset.active === 1
			)
			this.datasets = ativo
		},

		viewDataset(id) {
			this.$router.replace({ name: 'Dataset', params: { id: id}});
		},

		layoutCelular(){
			var largura = window.innerWidth
				if(largura < 750) {
				this.celular = true
				this.wid = 300
				this.hei = 600
			}
		}
	}
}

</script>