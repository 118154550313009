<template>
	<div class="grid" v-show="view_reports">
		<div class="col-12">
			<div class="card">
				<Toast/>
				<div class="col-12" v-if="loading === true">
					<h4>Carregando Relatórios... Aguarde!</h4>
					<lottie-animation
						:path="'animations/load_report.json'"
						:loop="true"
						:autoPlay="true"
						:speed="1"
						:width="wid"
						:height="hei"
					/>
				</div>
				<DataView v-else :value="filterTable" :layout="layout" :paginator="true" :rows="12" :Loading="load">
					<template #header>
						<div class="grid grid-nogutter">
							<div class="col-8 flex text-left">
								<h4 class="mt-2">Listagem de Relatórios</h4>
								<span class="p-input-icon-left ml-3">
									<i class="pi pi-search" />
									<InputText v-model="search" placeholder="Procurar" size="small" />
								</span>
							</div>
							<div class="col-4 text-right">
								<DataViewLayoutOptions v-show="!celular" v-model="layout" />
							</div>
						</div>
					</template>
					<template #list="slotProps">
						<div class="col-12" v-show="!celular">
							<div class="flex flex-column md:flex-row align-items-center p-3 w-full">
								<router-link :to="{ name: 'Report', params: { id: slotProps.data.report.id }}">
									<img :src="'images/views/reports-icon.png'" :alt="slotProps.data.report.namemenu" class="my-4 md:my-0 w-5 md:w-3rem shadow-2 mr-5"/>
								</router-link>
								<div class="flex-1 text-center md:text-left">
									<router-link :to="{ name: 'Report', params: { id: slotProps.data.report.id }}">
										<div class="flex-1 text-center md:text-left">
										<div class="font-regular text-2xl" >{{slotProps.data.report.namemenu}}</div>
										</div>
									</router-link>
									<div class="flex align-items-center justify-content-start py-1 my-1">
										<div class="flex align-items-center">
											<InputSwitch v-model="slotProps.data.initial" v-on:change="addHome(slotProps.data)" />
										</div>
										<span class="px-2">Tela Inicial</span>
									</div>
								</div>
								<div class="flex flex-row md:flex-column justify-content-between w-full md:w-auto align-items-center md:align-items-end mt-5 md:mt-0" >
									<span class="text-2xl font-semibold mb-2 align-self-center md:align-self-end"></span>
									<Button icon="pi pi-eye" :disabled="false" class="mb-2" v-on:click="viewReport(slotProps.data.report.id)"></Button>
								</div>
							</div>
						</div>
					</template>

					<template #grid="slotProps">
						<div class="col-12 md:col-3">
							<div class="card m-1 border-1 surface-border">
								<div class="text-center">
									<router-link :to="{ name: 'Report', params: { id: slotProps.data.report.id }}">
										<img :src="'images/views/reports-icon.png'" :alt="slotProps.data.report.namemenu" class="w-2 shadow-2 my-2 mx-0"/>
										<div class="text-2x1 font-regular" >{{slotProps.data.report.namemenu}}</div>
									</router-link>
								</div>
								<div class="flex align-items-center justify-content-between mt-3">
									<div class="flex align-items-center justify-content-start">
										<div class="flex align-items-center">
											<InputSwitch v-model="slotProps.data.initial" v-on:change="addHome(slotProps.data)" />
										</div>
										<span class="px-2">Tela Inicial</span>
									</div>
								</div>
							</div>
						</div>
					</template>
				</DataView>
			</div>
		</div>
	</div>
</template>

<script>
import router from '../../router';
import {api} from '../../service/services';
import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue";
export default {
    name: 'Reports',
	components: {
      LottieAnimation
  },
	data(){
		return {
			view_reports: false,
			layout: 'grid',
			reports: null,
			search: '',
			loading: false,
			edit_reports: false,
			celular: false,
			wid: 600,
			hei: 700,
			switchValue: false,
			load: false
		}
	},

	async created() { 
		this.layoutCelular()
		this.loading = true
		this.busca()
		await this.getReport()
		this.loading = false
		},
	computed: {
		filterTable() {
			let searchTerm = this.search.toLowerCase();
			return this.reports.filter(obj => obj.report.namemenu.toLowerCase().indexOf(searchTerm) > -1);
		}
	},
	methods:{
		busca(){
			const response = JSON.parse(localStorage.getItem("pra"))
			const data = response.filter(
				(arry) => "view_report".includes(arry)
			)
			const data1 = response.filter(
				(arry) => "edit_report".includes(arry)
			)
			if (data.length > 0){
				this.view_reports = true
			} else {
				router.push("/error");
			}
			if (data1.length > 0){
				this.edit_reports = true
			}
		},
		async getReport(){
			this.loading = true
			let largura = window.innerWidth
			const response = await api.get("users/" + window.localStorage.getItem("id_ri"));
			const data = await response.data.ReportsUsers;
			let datafiltred = [] 
			data.map((e) => {
				var obj = {
					report: e.report,
					initial: e.initial,
					id: e.id
				}
				datafiltred.push(obj)				
			})
			datafiltred.sort(function (a,b) { 
				return (a.report.namemenu > b.report.namemenu) ? 1 : ((b.report.namemenu > a.report.namemenu) ? -1 : 0)
			})
						
			const ativoD = datafiltred.filter(
				(item) => item.report.active === 1 && item.report.phone === 0
			)
			const ativoC = datafiltred.filter(
				(item) => item.report.active === 1 && item.report.phone === 1
			)
			if(largura < 750){
				this.reports = ativoC
			} else {
				this.reports = ativoD
			}
			this.loading = false
		},

		viewReport(id) {
			this.$router.replace({ name: 'Report', params: { id: id}});
		},

		layoutCelular(){
			var largura = window.innerWidth
				if(largura < 750) {
				this.celular = true
				this.wid = 300
				this.hei = 600
			}
		},
	
		async addHome(report){
			this.load = true
			try{
				await api.put("users/ur/" + report.id,{
					initial: report.initial
				})
				if(report.initial === false){
					this.$toast.add({severity:'success', summary: 'Sucesso', detail: 'O Relatório ' + report.report.namemenu + ' Removido com Sucesso da Pagina Inicial!', life: 5000});
				} else{
					this.$toast.add({severity:'success', summary: 'Sucesso', detail: 'O Relatório ' + report.report.namemenu + ' Adicionado com Sucesso da Pagina Inicial!', life: 5000});
				}
			}catch (err) {
				this.$toast.add({severity:'error', summary: 'Erro', detail: 'Erro ao adicionar o Relatório ' + report.report.namemenu + ' da Pagina Inicial!' + err, life: 5000});
			}
			this.load = false
		}
	}
}

</script>