<template>
    <Login v-if="$route.path === '/login'"/>
    <Forgetpass v-else-if="$route.path === '/forgetpass'" />
    <Resetpass v-else-if="$route.path === '/resetpass'" />
    <Error v-else-if="$route.path === '/error'" />
    <App v-else @change-theme="changeTheme" />
</template>

<script>
import EventBus from './AppEventBus';
import App from './App';
import Login from './pages/Login';
import Forgetpass from './pages/Forgetpass';
import Resetpass from './pages/Resetpass';
import Error from './pages/Error';

export default {
    methods: {
        changeTheme(event) {
            let themeElement = document.getElementById('theme-link');
            themeElement.setAttribute('href', themeElement.getAttribute('href').replace(this.$appState.theme, event.theme));
            this.$appState.theme = event.theme;
            this.$appState.darkTheme = event.dark;
            EventBus.emit('change-theme', event);

            if (event.theme.startsWith('md')) {
                this.$primevue.config.ripple = true;
            }
        }
    },
    components: {
        App,
        Login,
        Forgetpass,
        Resetpass,
        Error,
    }
}
</script>