<template>
	<div>
		<div
			class="surface-ground flex align-items-center imgback justify-content-center min-h-screen min-w-screen overflow-hidden">
			<div class="flex flex-column align-items-center justify-content-center">
				<div
					style="border-radius: 56px; padding: 0.3rem; background: linear-gradient(180deg, #00ACA9 10%, rgba(41, 219, 101, 0) 30%)">
					<div class="w-full surface-card py-8 px-5 sm:px-8" style="border-radius: 53px">
						<div class="text-center mb-5">
							<img :src="this.urlImg" alt="Image" height="150" class="mb-3" />
						</div>
						<label for="text" class="block text-900 font-medium text-xl text-center mb-8">
							Olá {{ user.name }}, precisamos que você cadastre uma nova senha.
						</label>
						<div>
							<Password id="password" v-model.trim="password" placeholder="Digite sua Nova Senha"
								:toggleMask="true" class="w-full mb-3" inputClass="w-full" :feedback="false"
								:inputStyle="{ padding: '1rem' }" v-on:keyup.enter="savePass"></Password>

							<Password id="password1" v-model.trim="confirmaPassword" placeholder="Confirme sua Nova Senha"
								:toggleMask="true" class="w-full mb-3" inputClass="w-full" :feedback="false"
								:inputStyle="{ padding: '1rem' }" v-on:keyup.enter="savePass"></Password>

							<Button v-if="!loading" label="Salvar" class="w-full p-3 text-xl" style="background: #00ACA9"
								v-on:click="savePass"></Button>
							<Button v-else :disabled="true" label="Salvando..." class="w-full p-3 text-xl"
								style="background: #00ACA9"></Button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import {api} from '../service/services';
export default {
    name: 'Resetpass',
data(){
	return {
		user: {},
		password: "",
		confirmaPassword: "",
		submitted: false,
		loading: false,
		urlImg: api.getUrl() + "/images/"
		};
  },

 created() { 
	this.getUser()

	},
methods:{
	
	hideDialogPass(){
		this.submitted = false
		this.user = {}
		this.getUser()
		this.loading = false
		this.password = ""
		this.confirmaPassword = ""
	},
	async getUser(){
		const response = await api.get("users/" + window.localStorage.getItem("id_ri"))
		const data = response.data
		this.user = data
	},
	async savePass(){
		this.loading = true
		let id = window.localStorage.getItem("id_ri")
		if( this.password !== this.confirmaPassword){
			this.$toast.add({severity:'error', summary: 'Erro', detail: 'As Senhas digitadas são diferentes, por favor tente novamente', life: 4000});
			this.hideDialogPass()
		} else{
			try{
				await api.put("users/mypass/" + id,{
					name: this.user.name,
					email: this.user.email,
					password: this.password
				})
				this.$toast.add({severity:'success', summary: 'Sucesso', detail: 'Sua senha foi alterada com Sucesso!', life: 4000});
				this.hideDialogPass()
				this.$router.replace({ name: "Home" });
			}catch (err){
				this.$toast.add({severity:'error', summary: 'Erro', detail: 'Não foi Possível alterar sua senha ' + err, life: 4000});
				this.hideDialogPass()
			}
		}
	},

}


}
</script>

<style scoped>
body{
  margin: 0;
}

.imgback {
  height: 100vh;
  width: 100%;
  margin: 0px;
  padding: 0px;
  background-image: url("../assets/background.jpeg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  background-attachment: scroll;
}

</style>