<template>
	<div class="layout-topbar">
		<button class="p-link layout-menu-button layout-topbar-button" @click="onMenuToggle">
			<i class="pi pi-bars"></i>
		</button>

		<!-- <button class="p-link layout-topbar-menu-button layout-topbar-button"
			v-styleclass="{ selector: '@next', enterClass: 'hidden', enterActiveClass: 'scalein', 
			leaveToClass: 'hidden', leaveActiveClass: 'fadeout', hideOnOutsideClick: true}">
			<i class="pi pi-ellipsis-v"></i>
		</button> -->		
		<ul class="layout-topbar-menu hidden lg:flex origin-top" v-if="!celular">
			<li>
				<button class="p-link layout-topbar-button" v-on:click="home" >
					<i class="pi pi-home"></i>
				</button>
			</li>
			<li>
				<button class="p-link layout-topbar-button" v-on:click="myaccount" >
					<i class="pi pi-user"></i>
				</button>
			</li>
			<li>
				<button class="p-link layout-topbar-button" v-on:click="logoffUser">
					<i class="pi pi-sign-out"></i>
				</button>
			</li>
		</ul>
		<MegaMenu :model="nestedMenuitems" v-else />
	</div>
</template>

<script>
import {  mapActions } from "vuex";
export default {
	data(){
		return{
		celular: false,
		nestedMenuitems: [
					{
						icon:'pi pi-fw pi-cog',
						items:[
							[
							{
								label: 'Configurações',
								items: [
									{
										label: 'Home',
										icon:'pi pi-fw pi-home',
										to: "/"
									}, 
									{
										label: 'Minha Conta',
										icon:'pi pi-fw pi-user',
										to: "/myaccount"
									}, 
									{
										label: 'Sair',
										icon: 'pi pi-sign-out',
										to: '/login'
									}]
							},
							]
						]
					},
				],
		}
	},

	async created(){
		await this.layoutCelular()
	},

	computed: 
		{
		darkTheme() {
			return this.$appState.darkTheme;
		}
	},
    methods: {
	...mapActions([
      "logoffUser",
    ]),
	
        onMenuToggle(event) {
            this.$emit('menu-toggle', event);
        },
		onTopbarMenuToggle(event) {
            this.$emit('topbar-menu-toggle', event);
        },
		topbarImage() {
			return this.$appState.darkTheme ? 'images/logo_ri.png' : 'images/logo_ri.png';
		},
		myaccount(){
			this.$router.replace({ name: "MyAccount" });
		},
		home(){
			this.$router.replace({ name: "Home" });
		},
		layoutCelular(){
			var largura = window.innerWidth
				if(largura < 750) {
				this.celular = true
			}
		},
    },
	
}
</script>