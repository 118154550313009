<template>
    <div :class="containerClass" @click="onWrapperClick">
        <AppTopBar @menu-toggle="onMenuToggle" />
        <div class="layout-sidebar" @click="onSidebarClick">
            <AppMenu :model="menu" @menuitem-click="onMenuItemClick" :v-show="view === false" />
        </div>

        <div class="layout-main-container">
            <div class="layout-main">
                <router-view />
            </div>
        </div>

        <AppConfig :layoutMode="layoutMode" @layout-change="onLayoutChange" @change-theme="changeTheme" />
        <transition name="layout-mask">
            <div class="layout-mask p-component-overlay" v-if="mobileMenuActive"></div>
        </transition>
    </div>
</template>

<script>
import AppTopBar from './AppTopbar.vue';
import AppMenu from './AppMenu.vue';
import AppConfig from './AppConfig.vue';
import { api } from './service/services'

export default {
    emits: ['change-theme'],
    data() {
        return {
            layoutMode: 'overlay',
            staticMenuInactive: false,
            overlayMenuActive: false,
            mobileMenuActive: false,
            view_dashboards: false,
            view_datasets: false,
            view_reports: false,
            view_importleads: false,
            view_users: false,
            view_permissions: false,
            view_roles: false,
            view_filters: false,
            view_lanc_metas: false,
            view_groups: false,
            view_clientconfig: false,
            view_config_dashboards: false,
            view_config_datasets: false,
            view_config_dataflows: false,
            view_config_reports: false,
            view_config_workspaces: false,
            view_config_azure: false,
            view_config: false,
            view_config_site: false,
            view_config_dados: false,
            token: '',

            menu: [],
            rotas: [
                "view_dashboard",
                "view_dataset",
                "view_report",
                "view_importleads",
                "view_user",
                "view_permission",
                "view_filter",
                "view_role",
                "view_lanc_metas",
                "view_group",
                "view_config_site",
                "view_config_dashboard",
                "view_config_dataset",
                "view_config_dataflow",
                "view_config_report",
                "view_config_workspace",
                "view_config_azure",
                "view_config",
            ]
        }
    },

    watch: {
        $route() {
            this.menuActive = false;
            this.$toast.removeAllGroups();
        }
    },
    created() {
        this.busca()
    },

    methods: {
        async busca() {
            const resp = await api.getUserCurrent()
            this.$store.commit("UPDATE_USER", resp)
            const response = JSON.parse(localStorage.getItem("pra"))
            this.$store.commit("UPDATE_PAGEACCESS", response)
            const parts = window.localStorage.token_ri.split(' ');
            this.token = parts[1];
            const subdomain = this.getSubdomain()
            //const permission = await api.get("permissions/" + resp.id_permission)

            if (response !== null) {
                const data = response.filter(
                    (arry) => this.rotas.includes(arry)
                )
                if (data.length > 0) {
                    if (data.includes("view_dashboard") || resp.admin === 1) {
                        this.view_dashboards = true
                    }
                    if (data.includes("view_dataset") || resp.admin === 1) {
                        this.view_datasets = true
                    }
                    if (data.includes("view_report") || resp.admin === 1) {
                        this.view_reports = true
                    }
                    if ((data.includes("view_importleads") || resp.admin === 1) && subdomain === 'p7') {
                        this.view_importleads = true
                    }
                    // começa a parte de configurações
                    if (data.includes("view_user") || resp.admin === 1) {
                        this.view_users = true
                        this.view_config = true,
                            this.view_config_site = true
                    }
                    if (data.includes("view_permission") || resp.admin === 1) {
                        this.view_permissions = true
                        this.view_config = true,
                            this.view_config_site = true
                    }
                    if (data.includes("view_filter") || resp.admin === 1) {
                        this.view_filters = true
                        this.view_config = true,
                            this.view_config_site = true
                    }
                    if (data.includes("view_role") || resp.admin === 1) {
                        this.view_roles = true
                        this.view_config = true,
                            this.view_config_site = true
                    }
                    if (data.includes("view_group") || resp.admin === 1) {
                        this.view_groups = true
                        this.view_config = true,
                            this.view_config_site = true
                    }
                    if (data.includes("view_config_site") || resp.admin === 1) {
                        this.view_clientconfig = true
                        this.view_config = true,
                            this.view_config_site = true
                    }
                    if ((data.includes("view_lanc_metas") || resp.admin === 1) && subdomain === 'fm'){
                        this.view_lanc_metas = true
                        this.view_config = true,
                            this.view_config_site = true
                    }
                    if (data.includes("view_config_dashboard") || resp.admin === 1) {
                        this.view_config_dashboards = true
                        this.view_config = true
                        this.view_config_dados = true
                    }
                    if (data.includes("view_config_dataset") || resp.admin === 1) {
                        this.view_config_datasets = true
                        this.view_config = true
                        this.view_config_dados = true
                    }
                    if (data.includes("view_config_dataflow") || resp.admin === 1) {
                        this.view_config_dataflows = true
                        this.view_config = true
                        this.view_config_dados = true
                    }
                    if (data.includes("view_config_report") || resp.admin === 1) {
                        this.view_config_reports = true
                        this.view_config = true
                        this.view_config_dados = true
                    }
                    if (data.includes("view_config_workspace") || resp.admin === 1) {
                        this.view_config_workspaces = true
                        this.view_config = true
                        this.view_config_dados = true
                    }
                    if (data.includes("view_config_azure") || resp.admin === 1) {
                        this.view_config_azure = true
                        this.view_config = true
                        this.view_config_dados = true
                    }
                    this.menu =
                        [{
                            label: 'Home', view: true,
                            items: [
                                { label: 'Home', icon: 'pi pi-fw pi-home', to: '/', view: true },
                                { label: "Paineis", icon: "pi pi-fw pi-chart-line", to: "/dashboards", view: this.view_dashboards },
                                { label: "Conjutos de Dados", icon: "pi pi-fw pi-database", to: "/datasets", view: this.view_datasets },
                                { label: 'Relatorios', icon: 'pi pi-fw pi-chart-bar', to: '/reports', view: this.view_reports },
                                { label: 'Importar Leads', icon: 'pi pi-fw pi-users', url: 'https://importaleadsp7.vercel.app/auth?access_token=' + this.token, target: '_blank', view: this.view_importleads }
                            ]
                        },
                        {
                            label: 'Configurações', view: this.view_config,
                            items: [
                                {
                                    label: 'Site', icon: 'pi pi-fw pi-cog', view: this.view_config_site,
                                    items: [
                                        { label: 'Usuarios', icon: 'pi pi-fw pi-user', to: '/users', view: this.view_users },
                                        { label: 'Permissoes', icon: 'pi pi-fw pi-eye', to: '/permissions', view: this.view_permissions },
                                        { label: 'Filtros', icon: 'pi pi-fw pi-filter', to: '/filters', view: this.view_filters },
                                        //{ label: 'Regras de Acesso', icon: 'pi pi-fw pi-key', to: '/roles', view: this.view_roles },
                                        //{ label: 'Grupo de Relatórios', icon: 'pi pi-fw pi-share-alt', to: '/groups', view: this.view_groups },
                                        //{ label: 'Configurações Site', icon: 'pi pi-fw pi-sitemap', to: '/clientconfig', view: this.view_clientconfig },
                                        { label: 'Lançamento de Metas', icon: 'pi pi-fw pi-table', to: '/lancmetas', view: this.view_lanc_metas },
                                    ]
                                },
                                {
                                    label: 'Dados', icon: 'pi pi-fw pi-cog', view: this.view_config_dados,
                                    items: [
                                        { label: 'Paineis', icon: 'pi pi-fw pi-chart-line', to: '/configdashboards', view: this.view_config_dashboards },
                                        { label: 'Conjuntos de Dados', icon: 'pi pi-fw pi-database', to: '/configdatasets', view: this.view_config_datasets },
                                        { label: 'Fluxos de Dados', icon: 'pi pi-fw pi-database', to: '/configdataflows', view: this.view_config_dataflows },
                                        { label: 'Relatorios', icon: 'pi pi-fw pi-chart-bar', to: '/configreports', view: this.view_config_reports },
                                        { label: 'Workspaces', icon: 'pi pi-fw pi-desktop', to: '/configworkspaces', view: this.view_config_workspaces },
                                        { label: 'Configurações Azure', icon: 'pi pi-fw pi-microsoft', to: '/configazure', view: this.view_config_azure },
                                    ]
                                }
                            ]
                        },
                        ]
                } else {
                    this.$toast.add({ severity: 'error', summary: 'Erro', detail: 'Você não tem acesso a essa pagina!', life: 3000 });
                }
            }
        },

        onWrapperClick() {
            if (!this.menuClick) {
                this.overlayMenuActive = false;
                this.mobileMenuActive = false;
            }

            this.menuClick = false;
        },
        onMenuToggle() {
            this.menuClick = true;

            if (this.isDesktop()) {
                if (this.layoutMode === 'overlay') {
                    if (this.mobileMenuActive === true) {
                        this.overlayMenuActive = true;
                    }

                    this.overlayMenuActive = !this.overlayMenuActive;
                    this.mobileMenuActive = false;
                }
                else if (this.layoutMode === 'static') {
                    this.staticMenuInactive = !this.staticMenuInactive;
                }
            }
            else {
                this.mobileMenuActive = !this.mobileMenuActive;
            }

            event.preventDefault();
        },
        onSidebarClick() {
            this.menuClick = true;
        },
        onMenuItemClick(event) {
            if (event.item && !event.item.items) {
                this.overlayMenuActive = false;
                this.mobileMenuActive = false;
            }
        },
        onLayoutChange(layoutMode) {
            this.layoutMode = layoutMode;
        },
        addClass(element, className) {
            if (element.classList)
                element.classList.add(className);
            else
                element.className += ' ' + className;
        },
        removeClass(element, className) {
            if (element.classList)
                element.classList.remove(className);
            else
                element.className = element.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
        },
        isDesktop() {
            return window.innerWidth >= 992;
        },
        isSidebarVisible() {
            if (this.isDesktop()) {
                if (this.layoutMode === 'static')
                    return !this.staticMenuInactive;
                else if (this.layoutMode === 'overlay')
                    return this.overlayMenuActive;
            }

            return true;
        },
        changeTheme(event) {
            this.$emit('change-theme', event);
        },
        getSubdomain() {
            const hostname = window.location.hostname;
            const parts = hostname.split('.');
            if (parts.length > 2) {
                return parts[0];
            } else {
                return null; // Não há subdomínio
            }
        }
    },
    computed:

    {
        containerClass() {
            return ['layout-wrapper', {
                'layout-overlay': this.layoutMode === 'overlay',
                'layout-static': this.layoutMode === 'static',
                'layout-static-sidebar-inactive': this.staticMenuInactive && this.layoutMode === 'static',
                'layout-overlay-sidebar-active': this.overlayMenuActive && this.layoutMode === 'overlay',
                'layout-mobile-sidebar-active': this.mobileMenuActive,
                'p-input-filled': this.$primevue.config.inputStyle === 'filled',
                'p-ripple-disabled': this.$primevue.config.ripple === false
            }];
        },
        logo() {
            return (this.$appState.darkTheme) ? "images/logo_ri.png" : "images/logo_ri.png";
        },
    },


    beforeUpdate() {
        if (this.mobileMenuActive)
            this.addClass(document.body, 'body-overflow-hidden');
        else
            this.removeClass(document.body, 'body-overflow-hidden');
    },


    components: {
        'AppTopBar': AppTopBar,
        'AppMenu': AppMenu,
        'AppConfig': AppConfig,
    }
}
</script>

<style lang="scss">
@import './App.scss';
</style>
