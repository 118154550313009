<template>
	<div class="flex flex-col">
		<Toast/>
		<div v-if="loading" class="surface-card flex align-items-center justify-content-center min-h-screen min-w-screen overflow-hidden">
			<lottie-animation
			:path="'animations/load_user.json'"
			:loop="true"
			:autoPlay="true"
			:speed="1"
			:width="wid"
			:height="hei"
			/>
		</div>
		<ReportInHome 
			v-else-if="isReport && !loading"
			:reports="reportSelected" 
			:asideActive="true"
			:allReports="reports"
			@isReports="isReports"
		/>
		<div v-else class="flex-1">
			<div class="col-12 md:flex-column" >
				<div class="flex flex-row md:flex-column align-items-center justify-content-center mt-5 pt-8">
					<h1 style="text-align:center">Bem vindo a Relatorios Inteligentes</h1>
				</div>
				<div class="flex flex-row md:flex-column align-items-center justify-content-center mt-2 pt-8 mb-2 pb-8">
					<img alt="Logo da Empresa" :src="urlimage" :width="widImg" style="vertical-align: center" />
				</div>
			</div>
			<div class="flex align-items-center justify-content-center">
				<Button 
					v-tooltip="'Clique para adicionar Relatórios na Pagina Inicial'" 
					icon="pi pi-plus" 
					label="Adicionar Relatório" 
					class="p-button-raised p-button-success mr-2 mb-2"
					@click="reportDialog"
				/>
			</div>
		</div>
	</div>
	<Dialog v-model:visible="addReport" :style="{width: '80vw'}" header="Relatórios Disponíveis" :modal="true">
		<DialogReportHome 
			:reports="reports"
			@newReport="pushReport"
		/>
		<template #footer>
			<Button label="Fechar" icon="pi pi-times" class="p-button-text" @click="hideDialog"/>
		</template>
	</Dialog>	
</template>

<script>
import { api } from '../../service/services';
import ReportInHome from '../../components/Embeded/ReportInHome.vue'
import DialogReportHome from '../../components/Embeded/DialogReportHome.vue'
import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue";

export default {
    name: 'Home',
	components: {
		ReportInHome,
		LottieAnimation,
		DialogReportHome,
	},
	data() {
		return {
			urlimage: api.getUrl() + "/images/client/" + process.env.VUE_APP_EMPRESA_IMAGE,
			widImg: 500,
			wid: 600,
			hei: 700,
			reportSelected:[],
			reports: [],
			loading: false,
			isReport: false,
			addReport: false,
		}
	},
	async created(){
		this.loading = true
		await this.getReport()
		var largura = window.innerWidth
		if(largura < 750){
			this.wid = 300
		}
		this.loading = false
	},
	methods:{
		async getReport(){
			const response = await api.get("users/" + window.localStorage.getItem("id_ri"));
			const data = await response.data.ReportsUsers;
			data.forEach((item) =>{
				var obj = {
					report: item.report,
					initial: item.initial,
					id: item.id
				}
				this.reports.push(obj)
				this.reportSelected = this.reports.filter(
					item => item.initial === true
				)
			})
			if(this.reportSelected.length > 0){
				this.isReport = true
			} else {
				this.isReport = false
			}
		},
		reportDialog(){
			this.addReport = true
		},
		
		async hideDialog(){
			this.addReport = false
			if(this.reportSelected.length > 0){
				this.isReport = true
			} else {
				this.isReport = false
			}
		},
		pushReport(report){
            const filtro = this.reportSelected.filter(
                item => item.id === report.id
            )
            if(filtro.length > 0) {
                for(let i = 0; i < this.reportSelected.length; i++){
                if(this.reportSelected[i].id === report.id){
                    this.reportSelected.splice(i, 1)
                }
            }
            } else{
                this.reportSelected.push(report)
            }
        },
		isReports(data){
			this.isReport = data;
		}
	}
}
</script>

