<template>
    <DataTable ref="dt" :value="reports" dataKey="id" :paginator="true" :rows="15" :filters="filters" :rowHover="true" class="p-datatable-gridlines" :loading="load"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" :rowsPerPageOptions="[5,15,25]"
        currentPageReportTemplate="Mostrando {first} de {last} no total de {totalRecords} Relatórios" responsiveLayout="scroll">
        <template #header>
            <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
                <h5 class="m-0"></h5>
                <span class="block mt-2 md:mt-0 p-input-icon-left">
                    <i class="pi pi-search" />
                    <InputText v-model="filters['global'].value" placeholder="Procurar..." />
                </span>
            </div>
        </template>
        <template #empty>
            Não existe Relatórios Disponíveis.
        </template>
        <Column field="name" header="Nome" :sortable="true" headerStyle="width:70%; min-width:15rem;">
            <template #body="slotProps">
                <span class="p-column-title">Name</span>
                {{slotProps.data.report.namemenu}}
            </template>
        </Column>
        <Column field="active" header="Tela Inicial" :sortable="true" headerStyle="width:20%; min-width:15rem;">
            <template #body="slotProps">
                <InputSwitch v-model="slotProps.data.initial" v-on:change="addHome(slotProps.data)" />
            </template>
        </Column>
        <Column>
            <template #body="slotProps">
                <Button label="Abrir" icon="pi pi-eye" class="p-button-raised p-button-success mr-2" @click="viewReportDialog(slotProps.data)" />
            </template>
        </Column>
    </DataTable>
    <Dialog v-model:visible="viewReport" :style="{width: '90vw'}" :closable="false" :header="headerReportDialog" :modal="true">
			<ReportInDialog :reports="viewReportSelected"/>
		<template #footer>
			<Button label="Fechar" icon="pi pi-times" class="p-button-text" @click="viewHideDialog"/>
		</template>
	</Dialog>
</template>
<script>
import {FilterMatchMode} from 'primevue/api';
import { api } from '../../service/services';
import ReportInDialog from './ReportInDialog.vue'
export default {
    name: 'DialogReportHome',
    components: {
        ReportInDialog
    },
    data(){
        return{
            filters:{},
            load: false,
            viewReport: false,
			headerReportDialog: "",
			viewReportSelected: [],
        }
    },
    props: {
        reports: Array
    },
    async created(){
        this.initFilters()
    },
    methods: {
       initFilters() {
            this.filters = {
                'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
            }
        },
        viewReportDialog(report){
			this.viewReportSelected.push(report)
			this.headerReportDialog = report.report.namemenu
			this.viewReport = true
		},
        viewHideDialog(){
			this.viewReportSelected = []
			this.headerReportDialog = ""
			this.viewReport = false
		},
        async addHome(report){
			this.load = true
			try{
				await api.put("users/ur/" + report.id,{
					initial: report.initial
				})
                this.$emit('newReport', report)
				if(report.initial === false){		
					this.$toast.add({severity:'success', summary: 'Sucesso', detail: 'O Relatório ' + report.report.namemenu + ' Removido com Sucesso da Pagina Inicial!', life: 5000});
				} else{
					this.$toast.add({severity:'success', summary: 'Sucesso', detail: 'O Relatório ' + report.report.namemenu + ' Adicionado com Sucesso da Pagina Inicial!', life: 5000});
				}
			}catch (err) {
				this.$toast.add({severity:'error', summary: 'Erro', detail: 'Erro ao adicionar o Relatório ' + report.report.namemenu + ' da Pagina Inicial!' + err, life: 5000});
			}
			this.load = false
		}
    }
}
</script>