<template>
	<div class="grid" style="justify-content: center" v-show="view_config_client">
		<Toast ref="alert" />
		<div class="col-12">
			<div class="card">
				<Toast />
				<h5>Configuração do Site</h5>
				<div class="col-12 flex">
					<div class="grid p-fluid flex flex-column col-6 mr-1" style="justify-content: start">
						<div class="field">
							<h6>Nome do Cliente</h6>
							<span class="p-input-icon-left">
								<i class="pi pi-home" />
								<InputText type="text" placeholder="Digite o Nome que deseja No site"
									v-model="client.name" />
							</span>
						</div>
						<div class="field">
							<h6>Logo do Cliente</h6>
							<span class="p-input-icon-left">
								<i class="pi pi-search-plus" />
								<InputText type="text" placeholder="Aqui vai a logo do Cliente" v-model="client.logo_url" />
							</span>
						</div>
						<div class="field">
							<h6>Fazer Login no Facebook para Acesso a conta de Anuncio</h6>
							<facebook-login class="button" appId="687102250237192" @login="onLogin" @logout="onLogout"
								@sdk-loaded="sdkLoaded" version="v18.0">
							</facebook-login>
						</div>
						<div class="field">
							<div class="field-checkbox mb-3">
								<InputSwitch v-model="switchValue" />
								<label for="active">Ja teve Acesso</label>
							</div>
							<div class="field-checkbox mb-3">
								<InputSwitch v-model="switchFinanceiro" />
								<label for="active">Modulo Financeiro</label>
							</div>
							<div class="field-checkbox mb-3">
								<InputSwitch v-model="switchRh" />
								<label for="active">Modulo Rh</label>
							</div>
							<div class="field-checkbox mb-3">
								<InputSwitch v-model="switchOportunidade" />
								<label for="active">Modulo Oportunidade</label>
							</div>
						</div>
						<div class="field">
							<Button v-on:click="saveClient" label="Salvar" class="mt-2 mr-2 mb-5" v-if="!loading"></Button>
							<Button label="Salvando..." class="ml-5 mt-2 mr-2 mb-5" v-else :disabled="true"></Button>
						</div>
					</div>
					<div class="grid p-fluid flex flex-column col-6 mr-1 justify-content-start align-items-center">
						<div class="field">
							<img :alt="client.name" :src='client.logo_url' height="300" style="vertical-align: middle" />
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import router from '../../router';
import {api} from '../../service/services';
export default {
    name: 'ClientConfig',
	data(){
		return {
			view_config_client: false,
			client: [],
			loading: false,
			switchValue: false,
			switchFinanceiro: false,
			switchOportunidade: false,
			switchRh: false,
		}
	},

	created() { 
		this.busca()
		this.getClient()
		},
	
	methods:{

		busca(){
			const response = JSON.parse(localStorage.getItem("pra"))
			const data = response.filter(
				(arry) => "view_config_site".includes(arry)
			)
			if (data.length > 0){
				this.view_config_client = true
			} else {
				router.push("/error");
			}
		},
		async getClient(){
			const response = await api.get("client")
			const data = response.data
			this.client = data
			if(data.firstaccess === 1 ){
				this.switchValue = true
			}
			this.switchFinanceiro = data.financeiro
			this.switchOportunidade = data.oportunidade
			this.switchRh = data.rh
		},
		async saveClient(){
			this.loading = true
			if(this.client){
				try{
					await api.put("client/" + this.client.id, {
						name: this.client.name,
						financeiro: this.switchFinanceiro,
						oportunidade: this.switchOportunidade,
						rh: this.switchRh
					})
					this.$toast.add({severity:'success', summary: 'Sucesso', detail: 'Dados Atualizado com Sucesso', life: 3000});
				}catch (err) {
					this.$toast.add({severity:'error', summary: 'Erro', detail: 'Não foi possível atualizar os Dados ' + err, life: 3000});
			}
			}else{
				try{
					await api.post("client", {
						name: this.clientName,
						logo_url: this.logo
					})
					this.$toast.add({severity:'success', summary: 'Sucesso', detail: 'Dados Atualizado com Sucesso', life: 3000});
				}catch (err) {
					this.$toast.add({severity:'error', summary: 'Erro', detail: 'Não foi possível atualizar os Dados ' + err, life: 3000});
				}
			}
			this.client = []
			this.getClient()
			this.loading = false
		}
	}
}
</script>