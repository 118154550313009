import { api } from "../../../service/services";

const state = () => ({
    metas:[]
})

const getters = {}

const actions = {
    async getMetas(context){
        const resp = await api.get("lancmetas")
        const data = await resp.data
        data.forEach(meta => meta.createdAt = new Date(meta.createdAt))
        data.forEach(meta => meta.updatedAt = new Date(meta.updatedAt))
        data.forEach(meta => meta.mesref = new Date(meta.mesref))
        context.commit("UPDATE_METAS",data)
      },
}

const mutations = {
    UPDATE_METAS(state, payload) {
        state.metas = payload;
    },
    UPDATE_META(state, payload){
        const index = state.metas.findIndex(item => item.id === payload.id)
        state.metas.splice(index,1,payload)
    },
    ADD_META(state, payload){
        state.metas.unshift(payload)
    },
    DELETE_META(state, payload){
        const index = state.metas.findIndex(i => i.id === payload.id)
        state.metas.splice(index,1)
    }
}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}