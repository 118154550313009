import { api } from "../service/services";
import { createStore } from 'vuex'
import router from '../router';
//Modules do Modulo Metas
import metas from "./modules/Metas/meta"
//Modoles do Modulo RH

export default createStore({
  state: {
    logged: false,
    user: {},
    pageaccess: [],
  },
  mutations: {
    UPDATE_PAGEACCESS(state, payload){
      state.pageaccess = payload
    },
    UPDATE_LOGIN(state, payload) {
      state.logged = payload;
    },
    UPDATE_USER(state, payload) {
      state.user = Object.assign(state.user, payload);
    },
    
  },
  actions: {
    async getUser(context, payload) {
      try {
        const response = await api.get('users/' + payload);
        const data = await response.data;
        context.commit("UPDATE_USER", data);
        context.commit("UPDATE_LOGIN", true);
      } catch {
        context.commit("UPDATE_LOGIN", false);
      }
    },
    async loginUser(context, payload) {
      return await api.post('auth', {
        email: payload.email,
        password: payload.password
      });
    },
    logoffUser(context) {
      context.commit("UPDATE_USER", {});
      context.commit("UPDATE_PAGEACCESS", []);
      window.localStorage.removeItem("token_ri");
      window.localStorage.removeItem("id_ri");
      window.localStorage.removeItem("pra");
      router.push("/login");
      context.commit("UPDATE_LOGIN", false);
    },
  },
  modules: {
    //Modelos do Modulo Metas
    metas,
  }
});