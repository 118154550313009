<template>
  <EmbededDataset :id="this.$route.params.id" />
</template>

<script>
import EmbededDataset from "../../components/Embeded/EmbededDataset.vue";

export default {
  name: "Dataset",
  components: {
    EmbededDataset,
  },
};
</script>

<style>
</style>