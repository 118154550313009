import { createRouter, createWebHistory } from 'vue-router';
import Home from '../src/views/main/Home.vue'
import Dashboards from '../src/views/main/Dashboards.vue'
import Dashboard from '../src/views/Embeded/Dashboard.vue'
import Reports from '../src/views/main/Reports.vue'
import Report from '../src/views/Embeded/Report.vue'
import Datasets from './views/main/Dataset.vue'
import Dataset from '../src/views/Embeded/Dataset.vue'
import Filters from '../src/views/main/Filters.vue'
import Permissions from '../src/views/main/Permissions.vue'
import Groups from '../src/views/main/Groups.vue'
import Users from '../src/views/main/Users.vue'
import Roles from '../src/views/main/Roles.vue'
import LancMetas from '../src/views/main/LancMetas.vue'
import MyAccount from '../src/views/main/MyAccount.vue'
import ConfigAzure from '../src/views/main/ConfigAzure.vue'
import ConfigDashboards from '../src/views/main/ConfigDashboards.vue'
import ConfigDatasets from '../src/views/main/ConfigDatasets.vue'
import ConfigDataflows from '../src/views/main/ConfigDataflows.vue'
import ConfigReports from '../src/views/main/ConfigReports.vue'
import ConfigWorkspaces from '../src/views/main/ConfigWorkspaces.vue'
import ClientConfig from '../src/views/main/ConfigClient.vue'

import Erro from '../src/pages/Error.vue'
import Login from '../src/pages/Login.vue'
import Forgetpass from '../src/pages/Forgetpass.vue'
import Resetpass from '../src/pages/Resetpass.vue'
import {api} from '../src/service/services'

const routes = [
    {
        path: '/*',
        name: 'Login',
        component: Login,
    },
    {
        path: '/login',
        name: 'Login',
        component: Login,
    },
    {
        path: '/forgetpass',
        name: 'Forgetpass',
        component: Forgetpass,
    },
    {
        path: '/',
        name: 'Home',
        component: Home,
        meta: {auth: true},
        beforeEnter: (to, from, next) => {
            guard(to, from, next);
        },
    },
    {
        path: '/resetpass',
        name: 'Resetpass',
        component: Resetpass,
        meta: {auth: true},
        beforeEnter: (to, from, next) => {
            guard(to, from, next);
        },
    }, 
    {
        path: '/error',
        name: 'Error',
        component: Erro,
        meta: {auth: true},
        beforeEnter: (to, from, next) => {
            guard(to, from, next);
        },
    },    
    {
        path: '/datasets',
        name: 'Datasets',
        component: Datasets,
        meta: {auth: true},
        beforeEnter: (to, from, next) => {
            guard(to, from, next);
        },
    },
    {
        path: '/datasets/:id',
        name: 'Dataset',
        component: Dataset,
        meta: {auth: true},
        beforeEnter: (to, from, next) => {
            guard(to, from, next);
        },
    },
    {
        path: '/dashboards',
        name: 'Dashboards',
        component: Dashboards,
        meta: {auth: true},
        beforeEnter: (to, from, next) => {
            guard(to, from, next);
        },
    },
    {
        path: '/dashboards/:id',
        name: 'Dashboard',
        component: Dashboard,
        meta: {auth: true},
        beforeEnter: (to, from, next) => {
            guard(to, from, next);
        },
    },
    {
        path: '/reports',
        name: 'Reports',
        component: Reports,
        meta: {auth: true},
        beforeEnter: (to, from, next) => {
            guard(to, from, next);
        },
    },
    {
        path: '/reports/:id',
        name: 'Report',
        component: Report,
        meta: {auth: true},
        beforeEnter: (to, from, next) => {
            guard(to, from, next);
        },
    },
    {
        path: '/filters',
        name: 'Filters',
        component: Filters,
        meta: {auth: true},
        beforeEnter: (to, from, next) => {
            guard(to, from, next);
        },
    },
    {
        path: '/permissions',
        name: 'Permissions',
        component: Permissions,
        meta: {auth: true},
        beforeEnter: (to, from, next) => {
            guard(to, from, next);
        },
    },
    {
        path: '/groups',
        name: 'Groups',
        component: Groups,
        meta: {auth: true},
        beforeEnter: (to, from, next) => {
            guard(to, from, next);
        },
    },
    {
        path: '/users',
        name: 'Users',
        component: Users,
        meta: {auth: true},
        beforeEnter: (to, from, next) => {
            guard(to, from, next);
        },
    },
    {
        path: '/roles',
        name: 'Roles',
        component: Roles,
        meta: {auth: true},
        beforeEnter: (to, from, next) => {
            guard(to, from, next);
        },
    },
    {
        path: '/lancmetas',
        name: 'LancMetas',
        component: LancMetas,
        meta: {auth: true},
        beforeEnter: (to, from, next) => {
            guard(to, from, next);
        },
    },
    {
        path: '/myaccount',
        name: 'MyAccount',
        component: MyAccount,
        meta: {auth: true},
        beforeEnter: (to, from, next) => {
            guard(to, from, next);
        },
    },
    {
        path: '/configdashboards',
        name: 'ConfigDashboards',
        component: ConfigDashboards,
        meta: {auth: true},
        beforeEnter: (to, from, next) => {
            guard(to, from, next);
        },
    },
    {
        path: '/configdatasets',
        name: 'ConfigDatasets',
        component: ConfigDatasets,
        meta: {auth: true},
        beforeEnter: (to, from, next) => {
            guard(to, from, next);
        },
    },
    {
        path: '/configdataflows',
        name: 'ConfigDataflows',
        component: ConfigDataflows,
        meta: {auth: true},
        beforeEnter: (to, from, next) => {
            guard(to, from, next);
        },
    },
    {
        path: '/configreports',
        name: 'ConfigReports',
        component: ConfigReports,
        meta: {auth: true},
        beforeEnter: (to, from, next) => {
            guard(to, from, next);
        },
    },
    {
        path: '/configworkspaces',
        name: 'ConfigWorkspaces',
        component: ConfigWorkspaces,
        meta: {auth: true},
        beforeEnter: (to, from, next) => {
            guard(to, from, next);
        },
    },
    {
        path: '/clientconfig',
        name: 'ClientConfig',
        component: ClientConfig,
        meta: {auth: true},
        beforeEnter: (to, from, next) => {
            guard(to, from, next);
        },
    },
    {
        path: '/configazure',
        name: 'ConfigAzure',
        component: ConfigAzure,
        meta: {auth: true},
        beforeEnter: (to, from, next) => {
            guard(to, from, next);
        },
    },        
    
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

const guard = async function(to, from, next) {
    try {
     await api.validateToken();
     next();
    } catch {
      next('/login');
    }
   };

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.auth)) {
      if (!window.localStorage.token_ri) {
        next("/login");
      } else {
        next();
      }
    } else {
      next();
    }
  });

export default router;
