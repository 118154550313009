<template>
	<div class="grid p-fluid mt-2 flex align-items-center justify-content-center overflow-hidden" >
		<div class="grid justify-content-center p-2 lg:p-0">
			<div class="card p-fluid mt-2 align-items-center justify-content-center" style="width:80vw; height:85vh;">
				<Toast/>
				<div class="grid justify-content-center mt-5 p-2 lg:p-0">
					<h2>Minha Conta</h2>
				</div>
				<div class="grid p-fluid mt-5" style="justify-content: center">						
					<div class="field col-12 md:col-12 mt-5 flex align-items-center justify-content-center" >
						<img :alt="user.name" :src='this.urlImg + user.urlAvatar' width="128" style="vertical-align: middle; border-radius: 50%" />
					</div>
					<div class="field col-12 md:col-12 mt-2 flex align-items-center justify-content-center" >
						<input type="file" ref="file" @change="onSelect" />
						<div v-show="file !== ''" class="field col-12 md:col-2">
							<Button v-on:click="onSubmit" label="Upload" class="p-button-raised p-button-success mt-2 mr-2 mb-2"></Button>	
						</div>
					</div>
					<div class="field col-8 md:col-8 mt-2 align-items-center justify-content-center">
						<h5>Seu nome</h5>
						<span class="p-input-icon-left">
							<i class="pi pi-user" />
							<InputText type="text" placeholder="Nome completo" v-model="user.name"/>
						</span>
						<h5>Seu Email</h5>
						<span class="p-input-icon-left">
							<i class="pi pi-inbox" />
							<InputText :disabled="true" type="text" placeholder="E-amil Cadastrado" v-model="user.email"/>
						</span>
						<div class="field col-12 md:col-3">
							<Button v-on:click="alterPass(user)" label="Alterar Senha" class="p-button-raised p-button-success mt-2 mr-2 mb-2"></Button>	
						</div>
						<div class="field md:col-5 m-auto align-items-center justify-content-center">
							<Button v-on:click="saveUser" label="Salvar" class="mt-2 mr-2 mb-5" v-if="!loading"></Button>
							<Button label="Salvando..." class="ml-5 mt-2 mr-2 mb-5" v-else :disabled="true"></Button>
						</div>
					</div>
						
					</div>
					<Dialog v-model:visible="dialogPass" :style="{width: '450px'}" header="Alterar Senha" :modal="true" class="p-fluid">
					<div class="flex align-items-center justify-content-center">
						<div class="field col-10 md:col-12 mb-2">
							<div class="field col-10 md:col-10 mb-2">
								<label for="password">Digite a Nova Senha</label>
								<Password id="password" inputClass="w-full" v-model.trim="password" :feedback="false" placeholder="Digite sua Nova Senha" :toggleMask="true" required="true" autofocus :class="{'p-invalid': submitted && !password}" />
							</div>
							<div class="field col-10 md:col-10 mb-2">
								<label for="password">Confirme a Nova Senha</label>
								<Password id="confirmaPassword" inputClass="w-full" v-model.trim="confirmaPassword" :feedback="false" placeholder="Digite a Senha Novamente" :toggleMask="true" required="true" autofocus :class="{'p-invalid': submitted && !confirmaPassword}" />
							</div>
						</div>
					</div>
					<template #footer>
						<Button label="Cancelar" icon="pi pi-times" class="p-button-text" @click="hideDialogPass"/>
						<Button label="Salvar" icon="pi pi-check" class="p-button-text" @click="savePass" />
					</template>
				</Dialog>
			</div>
		</div>
	</div>
</template>

<script>
import {api} from '../../service/services';
export default {
    name: 'MyAccount',
data(){
	return {
		dialogPass: false,
		user: {},
		password: "",
		confirmaPassword: "",
		submitted: false,
		loading: false,
		file: "",
		urlImg: api.getUrl() + "/images/"
		};
  },

 created() { 
	this.getUser()

	},
methods:{
	
	alterPass(user){
		this.user = {...user}
		this.dialogPass = true
		this.submitted = true
	},
	hideDialogPass(){
		this.dialogPass = false
		this.submitted = false
		this.user = {}
		this.getUser()
		this.loading = false
		this.password = ""
		this.confirmaPassword = ""
		this.file = "" 
	},
	async getUser(){
		const response = await api.get("users/" + window.localStorage.getItem("id_ri"))
		const data = response.data
		this.user = data
	},
	async savePass(){
		this.loading = true
		let id = window.localStorage.getItem("id_ri")
		if( this.password !== this.confirmaPassword){
			this.$toast.add({severity:'error', summary: 'Erro', detail: 'As Senhas digitadas são diferentes, por favor tente novamente', life: 4000});
			this.hideDialogPass()
		} else{
			try{
				await api.put("users/mypass/" + id,{
					name: this.user.name,
					email: this.user.email,
					password: this.password
				})
				this.$toast.add({severity:'success', summary: 'Sucesso', detail: 'Sua senha foi alterada com Sucesso!', life: 4000});
				this.hideDialogPass()
			}catch (err){
				this.$toast.add({severity:'error', summary: 'Erro', detail: 'Não foi Possível alterar sua senha ' + err, life: 4000});
				this.hideDialogPass()
			}
		}
	},

	async saveUser(){
		this.loading = true
		let id = window.localStorage.getItem("id_ri")
			try{
				await api.put("users/myname/" + id,{
					name: this.user.name,					
				})
				this.$toast.add({severity:'success', summary: 'Sucesso', detail: 'Seus Dados foram alterados com Sucesso!', life: 4000});
				this.hideDialogPass()
			}catch (err){
				this.$toast.add({severity:'error', summary: 'Erro', detail: 'Não foi Possível alterar seus Dados ' + err, life: 4000});
				this.hideDialogPass()
			}
	},

	onSelect() {
      const allowedTypes = ["image/jpeg", "image/jpg", "image/png"];
      const file = this.$refs.file.files[0];

      if (!allowedTypes.includes(file.type)) {
        this.$toast.add({severity:'error', summary: 'Erro', detail: 'Somente Imagens são permitidas! ', life: 4000});
        return;
      }
	
      if (file.size > 1000000) {
        this.$toast.add({severity:'error', summary: 'Erro', detail: 'O tamnho Máximo de Arquivo é de 1MB ', life: 4000});
        return;
      }
      this.file = file;
    },

	async onSubmit() {
      this.loading = true;
		let uuid = window.localStorage.getItem("id_ri")
      const formData = new FormData();
      formData.append("file", this.file);

      try {
        await api.patch("/users/avatar/" + uuid, formData);
        
		this.$toast.add({severity:'success', summary: 'Sucesso', detail: 'Sua Foto foi alterada com Sucesso!', life: 4000});
		this.hideDialogPass()
      } catch (err) {
        this.$toast.add({severity:'error', summary: 'Erro', detail: 'Não foi Possível alterar sua Foto ' + err, life: 4000});
		this.hideDialogPass()
      }
    },

}


}
</script>