<template>
  <EmbededReport :id="this.$route.params.id" />
</template>

<script>
import EmbededReport from "../../components/Embeded/EmbededReport.vue";

export default {
  name: "Report",
  components: {
    EmbededReport,
  },
};
</script>

<style>
</style>