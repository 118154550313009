<template>
  <div class="absolute flex flex-column -mt-50 py-2 ml-2">
      <Button v-tooltip="'Menu do Relatório'" :icon="icon" class="p-button-rounded p-button-success" @click="toggle(menu)" />
      <div class="flex flex-column bg-white overlay_menu mt-2" v-if="menu">
          <Button v-show="edit_reports" :label="this.config.viewMode == 'view' ? 'Editar Relatório' : 'Ver Relatório'" icon="pi pi-pencil" class="p-button-rounded p-button-success p-button-text mr-2 mb-2" v-on:click="toggleReportEditMode" />
          <Button v-if="!celular" label="Tela Cheia" icon="pi pi-window-maximize" class="p-button-rounded p-button-success p-button-text mr-2 mb-2" v-on:click="fullScreen" />
          <Button v-else icon="pi pi-window-maximize" class="p-button-rounded p-button-success p-button-text mr-2 mb-2" v-on:click="fullScreen" />
          <Button v-if="!celular" v-show="print_reports" label="Imprimir" icon="pi pi-print" class="p-button-rounded p-button-success p-button-text mr-2 mb-2" v-on:click="reportPrint" />
          <Button v-show="print_reports" v-else icon="pi pi-print" class="p-button-rounded p-button-success p-button-text mr-2 mb-2" v-on:click="reportPrint" />
          <Button v-if="!celular" label="Atualizar" icon="pi pi-refresh" class="p-button-rounded p-button-success p-button-text mr-2 mb-2" v-on:click="reportRefresh" />
          <Button v-else icon="pi pi-refresh" class="p-button-rounded p-button-success p-button-text mr-2 mb-2" v-on:click="reportRefresh" />
          <Button v-if="!isRunning && !celular" icon="pi pi-play" label="Play Modo TV" class="p-button-rounded p-button-success p-button-text mr-2" @click="modeTV = true" />
          <Button v-else-if="!celular" icon="pi pi-stop" label="Stop Modo TV" class="p-button-rounded p-button-success p-button-text mr-2" @click="stopInterval" />
      </div>
  </div>
	<div class="col-15"  v-show="view_reports">
    <div id="reportContainer"></div>
	</div>
  <Dialog v-model:visible="modeTV" :style="{width: '50vw'}" header="Ativando o modo TV" :modal="true">
    <span>Para ativar o modo TV você precisa definir o intervalo de Atualização.</span><br><br>
    <span class="mr-3"><strong>Selecione ao lado o intervalo desejado.</strong></span>
    <Dropdown v-model="selectedInterval" :options="optionsInterval" optionLabel="name" optionValue="value"></Dropdown>
    <template #footer>
      <Button label="Ativar" icon="pi pi-sync" class="p-button-text" @click="startInterval(selectedInterval)"/>
      <Button label="Cancelar" icon="pi pi-times" class="p-button-text" @click="modeTV = false"/>
    </template>
  </Dialog>
</template>
<script>
import router from '../../router';
import {api} from '../../service/services';
import * as pbi from "powerbi-client";
import * as pbimodels from "powerbi-models";
import { mapState } from "vuex";
export default {
    name: 'EmbededReport',
data(){
	return {
		config: {
			viewMode: "view",
			filters: false,
			nav: false,
		},
		report: {},
		filters: [],
		all_id: "",
		edit_reports: false,
    print_reports: true,
    celular: false,
    menu: false,
    modeTV: false,
    icon: "pi pi-bars",
    currentTime: null,
    intervalId: null,
    isRunning: false,
    optionsInterval:[
      {name: '1 Minuto', value: 1},
      {name: '2 Minutos', value: 2},
      {name: '3 Minutos', value: 3},
      {name: '4 Minutos', value: 4},
      {name: '5 Minutos', value: 5},
      {name: '6 Minutos', value: 6},
      {name: '7 Minutos', value: 7},
      {name: '8 Minutos', value: 8},
      {name: '9 Minutos', value: 9},
      {name: '10 Minutos', value: 10},
    ],
    selectedInterval: null
		};
  },
  props: {
    id: String,
  },
computed: mapState(["user"]),
async created() { 
	this.busca()
  this.layout()
	await this.getReport();
  await this.logs()
	},
	
methods:{
	busca(){
		const response = JSON.parse(localStorage.getItem("pra"))
		const data = response.filter(
			(arry) => "view_report".includes(arry)
		)
		const data1 = response.filter(
			(arry) => "edit_report".includes(arry)
		)
    const data2 = response.filter(
			(arry) => "print_report".includes(arry)
		)
		if (data.length > 0){
			this.view_reports = true
		} else {
			router.push("/error");
		}
		if (data1.length > 0){
			this.edit_reports = true
		}
    if (data2.length > 0){
			this.print_reports = true
		}
	},
  async getFilters() {
    const response = await api.get("filters")
    const data = response.data
    const dataUser = data.filter(
      (item) => item.id_user === window.localStorage.getItem("id_ri")
    )
    this.filters = dataUser
  },


  async getReport(){
    var models = pbimodels;
    await this.getFilters();
    const filters = [];
    this.filters.map((filter) => {
      const condicao = []
      filter.BodyFilter.forEach((i) =>{
        if (i.format === "Numeric") {
          i.value = parseInt(i.value)
        }
        condicao.push({
          operator: i.operator,
          value: i.value
        })
      })
      filters.push({
          $schema: "http://powerbi.com/product/schema#advanced",
          target: {
            table: filter.table,
            column: filter.column,
          },
          logicalOperator: filter.operator,
          conditions: condicao,
          filterType: models.FilterType.Advanced,
        });
    });
      
    const token = await api.get("token");
    const tmj = token.data
    const navFilter = await api.get("reports/"+ this.id)
    const dataNav = navFilter.data

    if(dataNav.nav === 1) {
      this.config.nav = true
    }
    
    if(dataNav.filters === 1) {
    this.config.filters = true
    }

      var config = {
        type: "report",
        id: this.id,
        embedUrl: "https://app.powerbi.com/reportEmbed?reportId=" + this.id,
        accessToken: tmj,
        tokenType: models.TokenType.Aad,
        permissions: models.Permissions.All,
        viewMode: 0,
        settings: {
          filterPaneEnabled: this.config.filters,
          navContentPaneEnabled: this.config.nav,
          bars: {
            actionBar: {
                visible: false
            }
          },
          panes: {
            bookmarks: {
                visible: false
            }
          },
          layoutType: models.LayoutType.Custom,
          customLayout: {
              displayOption: models.DisplayOption.FitToWidth,
          },
        },
        filters: filters,
      };
       

      var configMobile = {
        type: "report",
        id: this.id,
        embedUrl: "https://app.powerbi.com/reportEmbed?reportId=" + this.id,
        accessToken: tmj,
        tokenType: models.TokenType.Aad,
        permissions: models.Permissions.All,
        viewMode: 0,
        settings: {
          filterPaneEnabled: this.config.filters,
          navContentPaneEnabled: this.config.nav,
          bars: {
            actionBar: {
                visible: false
            }
          },
          panes: {
            bookmarks: {
                visible: false
            }
          },
          layoutType: models.LayoutType.MobilePortrait
        },
        filters: filters,
      };

      var reportContainer = document.getElementById("reportContainer");

      let powerbi = new pbi.service.Service(
        pbi.factories.hpmFactory,
        pbi.factories.wpmpFactory,
        pbi.factories.routerFactory
      );
  
      var layout = {}
      if(this.celular === true) {
        layout = configMobile
      }else{
        layout = config
      }

      powerbi.reset(reportContainer);
      this.report = powerbi.embed(reportContainer, layout);

  },

  fullScreen() {
    this.report.fullscreen();
    this.toggle(this.menu)
  },

  toggleReportEditMode() {
    this.toggle(this.menu)
    this.config.viewMode = this.config.viewMode == "view" ? "edit" : "view";
    this.report.switchMode(this.config.viewMode);
    var showFilterPane = this.config.viewMode == "edit";
    this.report.updateSettings({
      filterPaneEnabled: showFilterPane,
    });
  },
  async reportPrint() {
      try {
        await this.report.print();
      } 
      catch (errors) {
          console.log(errors);
      }
      this.toggle(this.menu)
    },
    async reportRefresh() {
      try {
        await this.report.refresh();
      } 
      catch (errors) {
          console.log(errors);
      }
      this.toggle(this.menu)
    },
    layout(){
      var largura = window.innerWidth
      if(largura < 750) {
        this.celular = true
        this.edit_reports = false
      }
    },
    toggle(event) {
        this.menu = !event
        if(event === true){
            this.icon = "pi pi-bars"
        } else {
            this.icon = "pi pi-times"
        }
    },
    async refreshAuto() {
      try {
        await this.report.refresh();
      } 
      catch (errors) {
          console.log(errors);
      }
    },

    startInterval(data) {
      const intervalTime = data * 60 * 1000
      this.currentTime = this.getCurrentTime();
      this.intervalId = setInterval(async () => {
        if (this.isRunning) {
          try {
            await this.refreshAuto()
          } catch (errors) {
            console.log(errors);
          }
          this.currentTime = this.getCurrentTime();
        }
      }, intervalTime);
      this.modeTV = false
      this.isRunning = true;
      this.selectedInterval = null
      this.fullScreen()
    },

    stopInterval() {
      clearInterval(this.intervalId);
      alert("Atualização desativada!")
      this.isRunning = false;
      this.toggle(this.menu)
    },
    toggleInterval() {
      if (this.isRunning) {
        this.stopInterval();
      } else {
        this.startInterval();
      }
    },
    getCurrentTime() {
      const now = new Date();
      return now.toLocaleTimeString();
    },

    async logs(){
      await api.post("logs", {
          company: process.env.VUE_APP_EMPRESA_API || "ri",
          user_id: this.$store.state.user.uuid,
          page: "Relatorios",
          action: "Acessou Relatorio",
          description: `O Usuário ${this.$store.state.user.name} acessou o relatório ${this.id}`
        })
    }

  }
}

</script>
<style scoped lang="scss">

 #reportContainer {
    height: 100vh;
  }
</style>