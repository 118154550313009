<template>
	<div class="col-15"  v-show="view_datasets">
		<Button v-if="!celular" label="Tela Cheia" icon="pi pi-window-maximize" class="p-button-rounded p-button-success p-button-text mr-2 mb-2" v-on:click="fullScreen" />
    <Button v-else icon="pi pi-window-maximize" class="p-button-rounded p-button-success p-button-text mr-2 mb-2" v-on:click="fullScreen" />
    <!-- <Button v-if="!celular" v-show="print_reports" label="Imprimir" icon="pi pi-print" class="p-button-rounded p-button-success p-button-text mr-2 mb-2" v-on:click="reportPrint" />
    <Button v-show="print_reports" v-else icon="pi pi-print" class="p-button-rounded p-button-success p-button-text mr-2 mb-2" v-on:click="reportPrint" /> -->
    <div id="datasetContainer"></div>
    {{props}}
	</div>
</template>
<script>
import router from '../../router';
import {api} from '../../service/services';
import * as pbi from "powerbi-client";
import * as pbimodels from "powerbi-models";
import { mapState } from "vuex";
export default {
    name: 'EmbededDataset',
data(){
	return {
    view_datasets: false,
		dataset: {},
    print_reports: true,
    celular: false,
		};
  },
  props: {
    id: String,
  },
computed: mapState(["user"]),
async created() { 
	this.busca()
  this.layout()
	await this.getDatasets();
	},
	
methods:{
	busca(){
		const response = JSON.parse(localStorage.getItem("pra"))
		const data = response.filter(
			(arry) => "view_dataset".includes(arry)
		)
		const data2 = response.filter(
			(arry) => "print_report".includes(arry)
		)
		if (data.length > 0){
			this.view_datasets = true
		} else {
			router.push("/error");
		}
    if (data2.length > 0){
			this.print_reports = true
		}
	},

  async getDatasets(){
    var models = pbimodels;
        const token = await api.get("token");
				const tmj = token.data

        var config = {
        datasetId: this.id,
        embedUrl: "https://app.powerbi.com/reportEmbed",
        accessToken: tmj,
        tokenType: models.TokenType.Aad,
      };

      var datasetContainer = document.getElementById("datasetContainer");

      let powerbi = new pbi.service.Service(
        pbi.factories.hpmFactory,
        pbi.factories.wpmpFactory,
        pbi.factories.routerFactory
      );

      powerbi.reset(datasetContainer);
      let report = powerbi.createReport(datasetContainer, config);

      report.on("saved", (event) => {
        // get ID and name of new report
        var newReportId = event.detail.reportObjectId;

        powerbi.reset(datasetContainer);

        let configSavedReport = {
          type: "report",
          id: newReportId,
          embedUrl:
            "https://app.powerbi.com/reportEmbed?reportId=" + newReportId,
          accessToken: tmj,
          tokenType: models.TokenType.Aad,
          permissions: models.Permissions.All,
          viewMode: models.ViewMode.Edit,
        };

       powerbi.embed(datasetContainer, configSavedReport);
      });
     
  },

  fullScreen() {
    this.dataset.fullscreen();
  },

  async reportPrint() {
      try {
        await this.dataset.print();
      } 
      catch (errors) {
          console.log(errors);
      }
    },
    async reportRefresh() {
      try {
        await this.dataset.refresh();
      } 
      catch (errors) {
          console.log(errors);
      }
    },
    layout(){
      var largura = window.innerWidth
      if(largura < 750) {
        this.celular = true
      }
    }
  }
}

</script>
<style scoped lang="scss">

 #datasetContainer {
    height: 100vh;
  }
</style>