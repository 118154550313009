<template>
	<div class="grid" v-show="view_roles">
		<div class="col-12">
			<div class="card">
				<Toast/>
				<Toolbar class="mb-4">
					<template v-slot:start>
						<div class="my-2">
							<Button label="Novo" icon="pi pi-plus" class="p-button-success mr-2" @click="openNew" />
							<Button label="Apagar" icon="pi pi-trash" class="p-button-danger" @click="deleteRolesDialog = true" :disabled="!selectedRoles || !selectedRoles.length" />
						</div>
					</template>

					<!-- <template v-slot:end>
						<FileUpload mode="basic" accept="image/*" :maxFileSize="1000000" label="Import" chooseLabel="Import" class="mr-2 inline-block" />
						<Button label="Export" icon="pi pi-upload" class="p-button-help" @click="exportCSV($event)"  />
					</template> -->
				</Toolbar>
				<div class="col-12" v-if="loading === true">
					<h4>Carregando Regras de Acesso... Aguarde!</h4>
					<lottie-animation
						:path="'animations/load_permission.json'"
						:loop="true"
						:autoPlay="true"
						:speed="1"
						:width="wid"
						:height="hei"
					/>
				</div>
				<DataTable v-else ref="dt" :value="roles" v-model:selection="selectedRoles" dataKey="id" :paginator="true" :rows="10" :filters="filters" :rowHover="true" class="p-datatable-gridlines" :loading="loading"
							paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" :rowsPerPageOptions="[5,10,25]"
							currentPageReportTemplate="Mostrando {first} de {last} no total de {totalRecords} Regras" responsiveLayout="scroll">
					<template #header>
						<div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
							<h5 class="m-0">Gerenciar Regras</h5>
							<span class="block mt-2 md:mt-0 p-input-icon-left">
                                <i class="pi pi-search" />
                                <InputText v-model="filters['global'].value" placeholder="Search..." />
                            </span>
						</div>
					</template>
					<template #empty>
                        Não existe Regras Cadastradas.
                    </template>
					<Column selectionMode="multiple" headerStyle="width: 3rem"></Column>
					<Column field="name" header="Nome" :sortable="true" headerStyle="width:20%; min-width:15rem;">
						<template #body="slotProps">
							<span class="p-column-title">Name</span>
							{{slotProps.data.name}}
						</template>
					</Column>
					<Column field="role" header="Nome da Regra" :sortable="true" headerStyle="width:20%; min-width:15rem;">
						<template #body="slotProps">
							<span class="p-column-title">Active</span>
							{{slotProps.data.role}}
						</template>
					</Column>
					<Column field="role" header="Categoria" :sortable="true" headerStyle="width:20%; min-width:15rem;">
						<template #body="slotProps">
							<span class="p-column-title">Categoria</span>
							{{slotProps.data.category}}
						</template>
					</Column>
					<Column field="createdAt" header="Criado em" :sortable="true" headerStyle="width:20%; min-width:15rem;">
						<template #body="slotProps">
							<span class="p-column-title">createdAt</span>
							{{slotProps.data.createdAt}}
						</template>
					</Column>
					<Column>
						<template #body="slotProps">
							<Button icon="pi pi-pencil" class="p-button-rounded p-button-success mr-2" @click="editRole(slotProps.data)" />
							<Button icon="pi pi-trash" class="p-button-rounded p-button-warning mt-2" @click="confirmDeleteRole(slotProps.data)" />
						</template>
					</Column>
				</DataTable>
				<Dialog v-model:visible="roleDialog" :style="{width: '600px'}" header="Nova Regra" :modal="true" class="p-fluid">
					<div class="field">
						<label for="name">Nome</label>
						<InputText id="name" v-model.trim="role.name" required="true" autofocus :class="{'p-invalid': submitted && !role.name}" />
					</div>
					<div class="field">
						<label for="name">Nome da Regra</label>
						<InputText id="role" v-model.trim="role.role" required="true" autofocus :class="{'p-invalid': submitted && !role.role}" />
					</div>
					<div class="field">
						<label for="name">Categoria da Regra</label>
						<Dropdown v-model="selectedCategory" :options="category" optionLabel="name" placeholder="Selecione uma Categoria" />
					</div>
					<template #footer>
						<Button label="Cancelar" icon="pi pi-times" class="p-button-text" @click="hideDialog"/>
						<Button label="Salvar" icon="pi pi-check" class="p-button-text" @click="saveRole" />
					</template>
				</Dialog>
				<Dialog v-model:visible="deleteRoleDialog" :style="{width: '450px'}" header="Confirmação" :modal="true">
					<div class="flex align-items-center justify-content-center">
						<i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
						<span v-if="role">Tem certeza de que deseja excluir a Regra <b>{{role.name}}</b>?</span>
					</div>
					<template #footer>
						<Button label="Não" icon="pi pi-times" class="p-button-text" @click="deleteRoleDialog = false"/>
						<Button label="Sim" icon="pi pi-check" class="p-button-text" @click="deleteRole" />
					</template>
				</Dialog>
				<Dialog v-model:visible="deleteRolesDialog" :style="{width: '450px'}" header="Confirmação" :modal="true">
					<div class="flex align-items-center justify-content-center">
						<i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
						<span v-if="role">Tem certeza de que deseja excluir as Regra selecionadas?</span>
					</div>
					<template #footer>
						<Button label="Não" icon="pi pi-times" class="p-button-text" @click="deleteRolesDialog = false"/>
						<Button label="Sim" icon="pi pi-check" class="p-button-text" @click="deleteSelectedRoles" />
					</template>
				</Dialog>
			</div>
		</div>
	</div>
</template>

<script>
import {FilterMatchMode} from 'primevue/api';
import {api} from '../../service/services';
import router from '../../router';
import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue";
export default {
    name: 'Roles',
	components: {
      LottieAnimation
  },
	data() {
		return {
			roles: null,
			roleDialog: false,
			deleteRoleDialog: false,
			deleteRolesDialog: false,
			role: {},
			selectedRoles: [],
			filters: {},
			submitted: false,
			view_roles: false,
			loading: false,
			wid: 500,
			hei: 600,
			category:[
				{code: 1, name: "Relatorio"},
				{code: 2, name: "Sistema"},
			],
			selectedCategory: null,
		}
	},
	Roleservice: null,
	async created() {
		this.layoutCelular()
		await this.initFilters()
		this.loading = true
		await this.busca();
		await this.getRole();
		this.loading = false
	},
	mounted() {
		this.getRole().then(data => this.products = data);
	},
	methods: {
		busca(){
			const response = JSON.parse(localStorage.getItem("pra"))
			const data = response.filter(
				(arry) => "view_role".includes(arry)
			)
			if (data.length > 0){
				this.view_roles = true
			} else {
				router.push("/error");
			}
		},
		layoutCelular(){
			var largura = window.innerWidth
				if(largura < 750) {
				this.wid = 300
				this.hei = 600
			}
		},
		editRole(role) {
			this.role = {...role};
			this.roleDialog = true;
			const category = this.category.filter(
				item => item.name === role.category
			)
			this.selectedCategory = category[0]
		},
		confirmDeleteRole(role) {
			this.role = role;
			this.deleteRoleDialog = true;
		},
		openNew() {
			this.role = {};
			this.submitted = false;
			this.roleDialog = true;
		},
		hideDialog() {
			this.roleDialog = false;
			this.submitted = false;
			this.loading = false
			this.role = {}
			this.roles = null
			this.selectedCategory = null
			this.getRole()
		},
		initFilters() {
            this.filters = {
                'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
            }
        },
		async getRole(){
			this.loading = true
			const response = await api.get("roles");
			const data = await response.data;
			this.roles = data
			this.loading = false
		},
		async saveRole() {
			this.submitted = true;
			this.loading = true
			try{
			if(this.role.id) {
				await api.put("roles/" + this.role.id, {
					name: this.role.name,
					role: this.role.role,
					category: this.selectedCategory.name
				})
				this.hideDialog()
				this.$toast.add({severity:'success', summary: 'Sucesso', detail: 'Regra Atualizada com Sucesso', life: 3000});
			}else {
				await api.post("roles", {
					name: this.role.name,
					role: this.role.role,
					category: this.selectedCategory.name
				});
				this.hideDialog()
				this.$toast.add({severity:'success', summary: 'Sucesso', detail: 'Regra Cadastrada com Sucesso', life: 3000});
			}
			this.roleDialog = false;
			this.hideDialog()
			}catch (err) {
				this.$toast.add({severity:'error', summary: 'Erro', detail: 'Regra não Cadastrada' + err, life: 3000});
				this.roleDialog = false;
				this.hideDialog()
			}
		},

		async deleteSelectedRoles() {
			this.deleteRolesDialog = false;
			this.loading = true
			try{
				let roles_id = [];
				let select = []
				const response = await api.get("permissions/pr");
				const data = response.data
				data.forEach((role) =>{
					roles_id.push(role.id_role)
				});
				this.selectedRoles.forEach((selected) =>{
					select.push(selected.id)
				});	
				const diff = select.filter(
					(arr1Item) => !roles_id.includes(arr1Item)
					);
					
				if(diff.length > 0) {
					this.selectedRoles.forEach(async (role) => {
					await api.delete("roles/" + role.id)
				})
				this.selectedRoles = null;
				this.hideDialog()
				this.$toast.add({severity:'success', summary: 'Sucesso', detail: 'Regras Apagadas', life: 3000});
				}else {
					this.deleteRolesDialog = false;
					this.selectedRoles = null;
					this.hideDialog()
					this.$toast.add({severity:'worn', summary: 'Informação', detail: 'As Regras ' + "" + ' não podem ser Apagadas por constar vinculado a Alguma Permissão!', life: 3000});
				}
			}catch (err) {
				this.deleteRolesDialog = false;
				this.selectedRoles = null;
				this.hideDialog()
				this.$toast.add({severity:'error', summary: 'Erro', detail: 'Regras não Apagadas' + err, life: 3000});
			}
		},

		async deleteRole() {
			this.loading = true
			this.deleteRoleDialog = false;
			try {
				await api.delete("roles/" + this.role.id)
				this.hideDialog()
				this.$toast.add({severity:'success', summary: 'Sucesso', detail: 'Regra Apagada', life: 3000});
			}catch (err) {
				this.deleteRoleDialog = false;
				this.hideDialog()
				this.$toast.add({severity:'error', summary: 'Erro', detail: 'Regra não Apagada', life: 3000});
			}
		},
	}

}
</script>