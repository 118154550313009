<template>
	<div class="grid" v-show="view_filters">
		<div class="col-12" >
			<div class="card">
				<Toast/>
				<Toolbar class="mb-4">
					<template v-slot:start>
						<div class="my-2">
							<Button label="Novo" icon="pi pi-plus" class="p-button-success mr-2" @click="openNew" />
							<Button label="Apagar" icon="pi pi-trash" class="p-button-danger" @click="deleteFiltrosDialog = true" :disabled="!selectedFiltros || !selectedFiltros.length" />
						</div>
					</template>

					<!-- <template v-slot:end>
						<FileUpload mode="basic" accept="image/*" :maxFileSize="1000000" label="Import" chooseLabel="Import" class="mr-2 inline-block" />
						<Button label="Export" icon="pi pi-upload" class="p-button-help" @click="exportCSV($event)"  />
					</template> -->
				</Toolbar>
				<div class="col-12" v-if="loading === true">
					<h4>Carregando Filtros... Aguarde!</h4>
					<lottie-animation
						:path="'animations/load_filter1.json'"
						:loop="true"
						:autoPlay="true"
						:speed="1"
						:width="wid"
						:height="hei"
					/>
				</div>
				<DataTable v-else ref="dt" :value="filtros" v-model:selection="selectedFiltros" dataKey="id" :paginator="true" :rows="10" :filters="filters" :rowHover="true" class="p-datatable-gridlines" :loading="load"
							paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" :rowsPerPageOptions="[5,10,25]"
							currentPageReportTemplate="Mostrando {first} de {last} no total de {totalRecords} Filtros" responsiveLayout="scroll">
					<template #header>
						<div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
							<h5 class="m-0">Gerenciar Filtros</h5>
							<span class="block mt-2 md:mt-0 p-input-icon-left">
                                <i class="pi pi-search" />
                                <InputText v-model="filters['global'].value" placeholder="Procurar..." />
                            </span>
						</div>
					</template>
					<template #empty>
                        Não existe Filtros Cadastrados.
                    </template>
					<Column selectionMode="multiple" headerStyle="width: 3rem"></Column>
					<Column field="user.name" header="Usuario" :sortable="true" headerStyle="width:12,5%; min-width:10rem;">
						<template #body="slotProps">
							<span class="p-column-title">Usuario</span>
							{{slotProps.data.user.name}}
						</template>
					</Column>
					<Column field="table" header="Tabela" :sortable="true" headerStyle="width:12,5%; min-width:10rem;">
						<template #body="slotProps">
							<span class="p-column-title">Tabela</span>
							{{slotProps.data.table}}
						</template>
					</Column>
					<Column field="column" header="Coluna" :sortable="true" headerStyle="width:12,5%; min-width:10rem;">
						<template #body="slotProps">
							<span class="p-column-title">Coluna</span>
							{{slotProps.data.column}}
						</template>
					</Column>
					<Column field="text" header="Operador" :sortable="true" headerStyle="width:12,5%; min-width:10rem;">
						<template #body="slotProps">
							<span class="p-column-title">Operador</span>
							{{slotProps.data.text}}
						</template>
					</Column>
					<Column field="format" header="Formato" :sortable="true" headerStyle="width:12,5%; min-width:10rem;">
						<template #body="slotProps">
							<span class="p-column-title">Formato</span>
							{{slotProps.data.format}}
						</template>
					</Column>
					<Column field="values" header="Valor" :sortable="true" headerStyle="width:12,5%; min-width:10rem;">
						<template #body="slotProps">
							<span class="p-column-title">Valor</span>
							{{slotProps.data.values}}
						</template>
					</Column>
					<Column field="createdAt" header="Criado em" :sortable="true" headerStyle="width:12,5%; min-width:10rem;">
						<template #body="slotProps">
							<span class="p-column-title">createdAt</span>
							{{slotProps.data.createdAt}}
						</template>
					</Column>
					<Column>
						<template #body="slotProps">
							<Button icon="pi pi-pencil" class="p-button-rounded p-button-success mr-2" @click="editFiltro(slotProps.data)" />
							<Button icon="pi pi-trash" class="p-button-rounded p-button-warning mt-2" @click="confirmDeleteFiltro(slotProps.data)" />
						</template>
					</Column>
				</DataTable>
				<Dialog v-model:visible="filtroDialog" :style="{width: '700px'}" :header=headerDialog :modal="true" class="p-fluid">
					<div class="border-round border-1 surface-border p-4" v-if="load === true">
						<div class="flex mb-3">
							<div>
								<Skeleton width="10rem" class="mb-2"></Skeleton>
								<Skeleton width="5rem" class="mb-2"></Skeleton>
								<Skeleton width="5rem" class="mb-2"></Skeleton>
								<Skeleton height=".5rem"></Skeleton>
							</div>
						</div>
						<Skeleton width="100%" height="150px"></Skeleton>
						<div class="flex justify-content-between mt-3">
							<Skeleton width="4rem" height="2rem"></Skeleton>
							<Skeleton width="4rem" height="2rem"></Skeleton>
						</div>
					</div>
					<div v-else>
					<div class="field" v-if="visibleNew">
						<label for="name">Usuario</label>
						<MultiSelect v-model="selectedAutoValue" :options="autoValue" optionLabel="name" placeholder="Selecione os Usuários" :filter="true">
							<template #value="slotProps">
								<div class="inline-flex align-items-center py-1 px-2 bg-primary text-primary border-round mr-2" v-for="option of slotProps.value" :key="option.code">
									<span style="width:18px; height: 12px"/>
									<div>{{option.name}}</div>
								</div>
								<template v-if="!slotProps.value || slotProps.value.length === 0">
									<div class="p-1">Selecione os Usuários</div>
								</template>
							</template>
							<template #option="slotProps">
								<div class="flex align-items-center">
									<span style="width:18px; height: 12px"/>
									<div>{{slotProps.option.name}}</div>
								</div>
							</template>
						</MultiSelect>
					</div>
					<div class="field" v-else>
						<label for="name">Usuario</label>
						<InputText :disabled="visible" id="id_user" v-model.trim="filtro.user.name" required="true" autofocus :class="{'p-invalid': submitted && !filtro.user.name}" />
					</div>
					<div class="field">
						<label for="name">Selecione uma Tabela</label>
						<InputText id="table" v-model.trim="filtro.table" required="true" autofocus :class="{'p-invalid': submitted && !filtro.table}" />
					</div>
					<div class="field">
						<label for="name">Selecione uma Coluna</label>
						<InputText id="column" v-model.trim="filtro.column" required="true" autofocus :class="{'p-invalid': submitted && !filtro.column}" />
					</div>
					<div class="field">
						<label for="name">Formato do Valor</label>
						<Dropdown :disabled="visible" id="name" v-model="dropdownFormat" :options="dropdownFormats" optionLabel="name" required="true" placeholder="Selecione" v-on:change="type" />
						<!-- <InputText id="format" v-model.trim="filtro.format" required="true" autofocus :class="{'p-invalid': submitted && !filtro.format}" /> -->
					</div>
					<div class="field">
						<label for="name">Operador de Junção dos FIltros</label>
						<Dropdown id="name" v-model="dropdownOperator" :options="dropdownOperators" optionLabel="name" required="true" placeholder="Selecione" v-on:change="type" />
						<!-- <InputText id="format" v-model.trim="filtro.format" required="true" autofocus :class="{'p-invalid': submitted && !filtro.format}" /> -->
					</div>
					<div class="flex ml-2 mb-0 pb-0 align-self-end">
						<div class="col-4">
							<label for="name">Operador</label>
						</div>
						<div class="col-4">
							<label for="name">Digite o valor</label>
						</div>
					</div>
					<div class="flex align-items-end mt-0 pt-0" v-for="(field, index) in fields" v-bind:key="index">
						<div class="field col-4">
							<Dropdown id="code" :key="index" v-model="field.dropdownValue" :options="dropdownValues" optionLabel="name" required="true" placeholder="Selecione" />
							<!-- <InputText id="operator" v-model.trim="filtro.operator" required="true" autofocus :class="{'p-invalid': submitted && !filtro.operator}" /> -->
						</div>
						<div class="field col-4" v-if="tipoTXT">
							<InputText :key="index" id="values" v-model.trim="field.value" required="true" autofocus :class="{'p-invalid': submitted && !filtro.values}" />
						</div>
						<div class="field col-4" v-else-if="tipoDATA">
							<Calendar :key="index" :showIcon="true" :showButtonBar="true" v-model="field.value"></Calendar>
						</div>
						<div class="field col-4" v-else-if="tipoINT">
							<InputNumber :key="index" v-model="field.value" showButtons mode="decimal"></InputNumber>
						</div>
						<div :key="index" class="field col-3" v-else> </div>
						<div class="col-1 pb-4" v-if="field.dropdownValue">
							<Button  icon="pi pi-minus" class="p-button-danger" @click="ExcluirField(field.id, index)"/>
						</div>
					</div>
					<div class="col-1 pb-4" >
						<Button  icon="pi pi-plus" class="p-button-success mr-2" @click="AddField"/>
					</div>
					</div>
					<template #footer>
						<Button label="Cancelar" icon="pi pi-times" class="p-button-text" @click="hideDialog"/>
						<Button label="Salvar" icon="pi pi-check" class="p-button-text" @click="saveFiltro" />
					</template>
				</Dialog>
				<Dialog v-model:visible="deleteFiltroDialog" :style="{width: '450px'}" header="Confirmação" :modal="true">
					<div class="flex align-items-center justify-content-center">
						<i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
						<span v-if="filtro">Tem certeza de que deseja excluir o Filtro deste Usuário <b>{{filtro.user.name}}</b>?</span>
					</div>
					<template #footer>
						<Button label="Não" icon="pi pi-times" class="p-button-text" @click="deleteFiltroDialog = false"/>
						<Button label="Sim" icon="pi pi-check" class="p-button-text" @click="deleteFiltro" />
					</template>
				</Dialog>
				<Dialog v-model:visible="deleteFiltrosDialog" :style="{width: '450px'}" header="Confirmação" :modal="true">
					<div class="flex align-items-center justify-content-center">
						<i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
						<span v-if="filtro">Tem certeza de que deseja excluir os Filtros selecionados?</span>
					</div>
					<template #footer>
						<Button label="Não" icon="pi pi-times" class="p-button-text" @click="deleteFiltrosDialog = false"/>
						<Button label="Sim" icon="pi pi-check" class="p-button-text" @click="deleteSelectedFiltros" />
					</template>
				</Dialog>
				
			</div>
		</div>
	</div>
</template>

<script>
import router from '../../router';
import {FilterMatchMode} from 'primevue/api';
import {api} from '../../service/services';
import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue";
export default {
    name: 'Filters',
	components: {
      LottieAnimation
  },
	data(){
		return {
			filtros: [],
			filtroDialog: false,
			deleteFiltroDialog: false,
			deleteFiltrosDialog: false,
			headerDialog: null,
			filtro: {},
			selectedFiltros: [],
			filters: {},
			submitted: false,
			view_filters: false,
			visible: false,
			visibleNew: false,
			selectedAutoValue: null,
			autoFilteredValue: [],
			autoValue: null,
			dropdownValues: [
					{name: 'igual a', code: "Is"},
					{name: 'diferente de', code: "IsNot"},
					{name: 'maior ou igual', code: "GreaterThanOrEqual"},
					{name: 'maior que', code: "GreaterThan"},
					{name: 'menor ou igual', code: "LessThanOrEqual"},
					{name: 'menor que', code: "LessThan"},
					{name: 'contem', code: "Contains"},
					{name: 'não contem', code: "DoesNotContain"},
					{name: 'começa com', code: "StartsWith"},
					{name: 'não começa com', code: "DoesNotStartWith"}
				],
			dropdownValue: null,
			dropdownFormats: [
					{name: 'Numerico'},
					{name: 'Texto'},
					{name: 'Data'}
				],
			dropdownFormat: null,
			dropdownOperators: [
				{name: 'E', code: "And"},
				{name: 'OU', code: "Or"},
			],
			dropdownOperator: null,
			tipoTXT: false,
			tipoINT: false,
			tipoDATA: false,
			loading: false,
			wid: 500,
			hei: 600,
			load: false,
			fields: [],
		}
	},

	async created() { 
		this.layoutCelular()
		this.initFilters()
		this.loading = true
		await this.busca();
		await this.getFiltro()
		await this.getUsers()
		this.loading = false
		},
	
	methods:{
		type(){
			if(this.dropdownFormat.name === "Texto"){
				//this.fields.value = null
				this.tipoTXT = true
				this.tipoINT = false
				this.tipoDATA = false
			}
			if(this.dropdownFormat.name === "Numerico"){
				//this.fields[index].value = null
				this.tipoTXT = false
				this.tipoINT = true
				this.tipoDATA = false
			}
			if(this.dropdownFormat.name === "Data"){
				//this.fields[index].value = null
				this.tipoTXT = false
				this.tipoINT = false
				this.tipoDATA = true
			}
		},

		AddField() {
			this.fields.push({
				dropdownValue: "",
				value: ""
				})	
		},
		async ExcluirField(id ,index) {
			if(id){
				await api.delete("filters/body/" + id)
			}
			var array = this.fields
			var valor = array[index]
			var posicao = array.indexOf(valor)
			if(posicao > -1) {
				array.splice(posicao,1)
			}
		},

		busca(){
			const response = JSON.parse(localStorage.getItem("pra"))
			const data = response.filter(
				(arry) => "view_filter".includes(arry)
			)
			if (data.length > 0){
				this.view_filters = true
			} else {
				router.push("/error");
			}
		},
		layoutCelular(){
			var largura = window.innerWidth
				if(largura < 750) {
				this.wid = 400
				this.hei = 600
			}
		},
		editFiltro(filtro) {
			this.fields = []
			this.filtro = {...filtro};
			this.fields = filtro.BodyFilter
			this.fields.map((i) => {
				i.dropdownValue = {name: i.text, code: i.operator}
			})
			this.filtroDialog = true;
			this.visible = true
			this.laod = true
			if(filtro.operator === "And"){
				this.dropdownOperator = {name: "E", code: filtro.operator}
			} else {
				this.dropdownOperator = {name: "OU", code: filtro.operator}
			}		 
			this.dropdownFormat = {name: filtro.format}
			if(filtro.format === "Texto"){
				this.tipoTXT = true
				this.tipoINT = false
				this.tipoDATA = false
			}
			if(filtro.format === "Numerico"){
				this.tipoTXT = false
				this.tipoINT = true
				this.tipoDATA = false
				this.inputNumberValue = Number(filtro.values)
			}
			if(filtro.format === "Data"){
				this.tipoTXT = false
				this.tipoINT = false
				this.tipoDATA = true
				var date = filtro.values
				var day = date.toLocaleString('en-US')
				var newData = day.replace(/(\d+[/])(\d+[/])/, '$2$1');
				var data = new Date(newData); 
				this.calendarValue = data
			}
			this.headerDialog = "Editar Filtro"
			this.load = false
		},
		confirmDeleteFiltro(filtro) {
			this.filtro = filtro;
			this.deleteFiltroDialog = true;
		},
		openNew() {
			this.filtro = {};
			this.submitted = false;
			this.filtroDialog = true;
			this.headerDialog = "Novo Filtro"
			this.dropdownValue = null
			this.dropdownFormat = null
			this.dropdownOperator = null
			this.inputNumberValue = null
			this.calendarValue = null
			this.tipoTXT = false,
			this.tipoINT = false,
			this.tipoDATA = false,
			this.visible = false
			this.visibleNew = true
			this.selectedAutoValue = null
			this.fields = []
			this.fields.push({
				dropdownValue: "",
				value: ""
				})
		},
		hideDialog() {
			this.filtroDialog = false;
			this.submitted = false;
			this.dropdownValue = null
			this.dropdownFormat = null
			this.dropdownOperator = null
			this.visible = false
			this.visibleNew = false
			this.filtro = {}
			this.inputNumberValue = null
			this.calendarValue = null
			this.selectedAutoValue = null
			//this.fields = []
		},
		initFilters() {
            this.filters = {
                'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
            }
        },
		async getFiltro(){
			const response = await api.get("filters");
			const data = await response.data;
			this.filtros = data
		},
		
		async getUsers(){
			const response = await api.get("users");
			const data = await response.data;
			this.autoValue = data
		},

		async saveFiltro() {
			this.fields.forEach((item) =>{
				if(item.dropdownValue.code === "GreaterThanOrEqual" && this.dropdownFormat.name === "Texto" ||
				item.dropdownValue.code === "GreaterThanOrEqual" && this.dropdownFormat.name === "Texto" ||
				item.dropdownValue.code === "LessThanOrEqual" && this.dropdownFormat.name === "Texto" ||
				item.dropdownValue.code === "LessThan" && this.dropdownFormat.name === "Texto" ||
				item.dropdownValue.code === "StartsWith" && this.dropdownFormat.name !== "Texto" ||
				item.dropdownValue.code === "DoesNotStartWith" && this.dropdownFormat.name !== "Texto" ){
					return this.$toast.add({severity:'error', summary: 'Erro', detail: 'Não pode ser Utilizado o Operador ' +  item.dropdownValue.name + ' com o formato ' + this.dropdownFormat.name, life: 3000});
				}
			})
			this.filtroDialog = false;
			this.submitted = true;
			this.load = true
			try{
				if(this.filtro.id) {
					await api.put("filters/" + this.filtro.id, {
						id_user: this.filtro.user.uuid,
						table: this.filtro.table,
						column: this.filtro.column,
						format: this.dropdownFormat.name,
						operator: this.dropdownOperator.code,
						text: "",
						values: ""
					})
					await this.fields.forEach((item) => {
						if(item.id){
							api.put("filters/body/" + item.id, {
								id_filter: this.filtro.id,
								text: item.dropdownValue.name,
								format: this.dropdownFormat.name,
								operator: item.dropdownValue.code,
								value: String(item.value)
							})
						} else {
							api.post("filters/body", {
								id_filter: this.filtro.id,
								text: item.dropdownValue.name,
								format: this.dropdownFormat.name,
								operator: item.dropdownValue.code,
								value: String(item.value)
							})
						}
					})
					this.$toast.add({severity:'success', summary: 'Sucesso', detail: 'Filtro Atualizado com Sucesso', life: 3000});
				}else {
				await this.selectedAutoValue.forEach(async (item) => {
					const response = await api.post("filters", {
							id_user: item.uuid,
							table: this.filtro.table,
							column: this.filtro.column,
							format: this.dropdownFormat.name,
							operator: this.dropdownOperator.code,
							text: "",
							values: ""
						});
						const data = response.data
						this.fields.forEach(async (item) => {
							await api.post("filters/body", {
								id_filter: data.id,
								text: item.dropdownValue.name,
								format: data.format,
								operator: item.dropdownValue.code,
								value: String(item.value)
							})
						})
						
				})
				this.$toast.add({severity:'success', summary: 'Sucesso', detail: 'Filtro Cadastrado com Sucesso', life: 3000});
				}
			}catch (err) {
				this.$toast.add({severity:'error', summary: 'Erro', detail: 'Filtro não Cadastrado' + err, life: 3000});
			}
			this.hideDialog()
			this.filtro = {}
			this.filtros = null
			await this.getFiltro()
			this.load = false
		},

		async deleteSelectedFiltros() {
			this.deleteFiltrosDialog = false;
			this.load = true
			try{
			let filtros_id = [];
			let select = []
			const response = await api.get("filters");
			const data = response.data
			data.forEach((filtro) =>{
				filtros_id.push(filtro.id_filtro)
			});
			this.selectedFiltros.forEach((selected) =>{
				select.push(selected.id)
			});	
			const diff = select.filter(
				(arr1Item) => !filtros_id.includes(arr1Item)
				);
				
			if(diff.length > 0) {
				await this.selectedFiltros.forEach(async (filtro) => {
					await api.delete("filters/" + filtro.id)
			})
			this.$toast.add({severity:'success', summary: 'Sucesso', detail: 'Filtros Apagados', life: 3000});
			}else {
				this.$toast.add({severity:'worn', summary: 'Informação', detail: 'Os Filtros ' + "" + ' não podem ser Apagados por constar vinculado a Algum Usuário!', life: 3000});
			}
			}catch (err) {
				this.$toast.add({severity:'error', summary: 'Erro', detail: 'Filtros Não Apagados' + err, life: 3000});
			}
			this.selectedFiltros = null;
			this.hideDialog()
			this.filtro = {}
			this.filtros = null
			await this.getFiltro()
			this.load = false
		},

		async deleteFiltro() {
			this.deleteFiltroDialog = false;
			this.load = true
			try {
				await api.delete("filters/" + this.filtro.id)
				this.$toast.add({severity:'success', summary: 'Sucesso', detail: 'Filtro Apagado', life: 3000});
			}catch (err) {
				this.$toast.add({severity:'error', summary: 'Erro', detail: 'Filtro não Apagado', life: 3000});
			}
			this.hideDialog()
			this.filtro = {}
			this.filtros = null
			await this.getFiltro()
			this.load = false
		},
	}
}
</script>