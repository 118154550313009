<template>
	<div class="grid" v-show="view_config_reports">
		<div class="col-12" >
			<div class="card">
				<Toast/>
				<Toolbar class="mb-4">
					<template v-slot:start>
						<div class="my-2">
							<Button v-if="!visible" label="Sincronizar" icon="pi pi-sync" class="p-button-success mr-2" @click="choiceSync" />
							<Button v-else :disabled="true" label="Sincronizando..." icon="pi pi-sync" class="p-button-success mr-2"  />
							<!-- <Button label="Inativar" icon="pi pi-trash" class="p-button-danger" @click="deleteReportsDialog = true" :disabled="!selectedReports || !selectedReports.length" /> -->
						</div>
					</template>

					<!-- <template v-slot:end>
						<FileUpload mode="basic" accept="image/*" :maxFileSize="1000000" label="Import" chooseLabel="Import" class="mr-2 inline-block" />
						<Button label="Export" icon="pi pi-upload" class="p-button-help" @click="exportCSV($event)"  />
					</template> -->
				</Toolbar>
				<DataTable ref="dt" :value="reports" v-model:selection="selectedReports" dataKey="id" :paginator="true" :rows="10" :filters="filters" :loading="loading" :rowHover="true" 
							paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" :rowsPerPageOptions="[5,10,25]"
							currentPageReportTemplate="Mostrando {first} de {last} no total de {totalRecords} Relatórios" responsiveLayout="scroll" class="p-datatable-gridlines">
					<template #header>
						<div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
							<h5 class="m-0">Gerenciar Relatórios</h5>
							<span class="block mt-2 md:mt-0 p-input-icon-left">
                                <i class="pi pi-search" />
                                <InputText v-model="filters['global'].value" placeholder="Procurar..." />
                            </span>
						</div>
					</template>
					<template #empty>
                        Não existe Relatórios Cadastrados.
                    </template>
					<Column selectionMode="multiple" headerStyle="width: 3rem"></Column>
					<Column field="namemenu" header="Nome" :sortable="true" headerStyle="width:25%; min-width:20rem;">
						<template #body="slotProps">
							<span class="p-column-title">Name</span>
							<router-link :to="{ name: 'Report', params: { id: slotProps.data.id }}">
								{{slotProps.data.namemenu}}
							</router-link>
						</template>
					</Column>
					<Column field="active" header="Status" :sortable="true" headerStyle="width:10%; min-width:10rem;">
						<template #body="slotProps">
							<span class="p-column-title">Active</span>
							<Tag v-if="slotProps.data.active === 1" class="mr-2" icon="pi pi-check" severity="success" value="Ativo"></Tag>
							<Tag v-else class="mr-2" icon="pi pi-times" severity="danger" value="Inativo"></Tag>
						</template>
					</Column>
					<Column field="phone" header="Celular" :sortable="true" headerStyle="width:10%; min-width:10rem;">
						<template #body="slotProps">
							<span class="p-column-title">Celular</span>
							<Tag v-if="slotProps.data.phone === 1" class="mr-2" icon="pi pi-check" severity="success" value="Phone"></Tag>
							<Tag v-else class="mr-2" icon="pi pi-times" severity="danger" value="Inativo"></Tag>
						</template>
					</Column>
					<Column field="filter" header="Filtros" :sortable="true" headerStyle="width:10%; min-width:10rem;">
						<template #body="slotProps">
							<span class="p-column-title">Filtros</span>
							<Tag v-if="slotProps.data.filters === 1" class="mr-2" icon="pi pi-check" severity="success" value="Filter"></Tag>
							<Tag v-else class="mr-2" icon="pi pi-times" severity="danger" value="Inativo"></Tag>
						</template>
					</Column>
					<Column field="nav" header="Navegação" :sortable="true" headerStyle="width:15%; min-width:10rem;">
						<template #body="slotProps">
							<span class="p-column-title">Navegação</span>
							<Tag v-if="slotProps.data.nav === 1" class="mr-2" icon="pi pi-check" severity="success" value="Nav"></Tag>
							<Tag v-else class="mr-2" icon="pi pi-times" severity="danger" value="Inativo"></Tag>
						</template>
					</Column>
					<Column field="createdAt" header="Criado em" :sortable="true" headerStyle="width:15%; min-width:15rem;">
						<template #body="slotProps">
							<span class="p-column-title">createdAt</span>
							{{slotProps.data.createdAt}}
						</template>
					</Column>
					<Column>
						<template #body="slotProps">
							<Button icon="pi pi-pencil" class="p-button-rounded p-button-success mr-2" @click="editReport(slotProps.data)" />
							<Button icon="pi pi-trash" class="p-button-rounded p-button-warning mr-2" @click="deleteDialog(slotProps.data)" />
							<Button icon="pi pi-eye" class="p-button-rounded p-button-primary mr-2" @click="openReport(slotProps.data)" />
						</template>
					</Column>
				</DataTable>
				<Dialog v-model:visible="reportDialog" :style="{width: '700px'}" header="Editar Relatório" :modal="true" class="p-fluid">
					<div class="field col-12 md:col-12">
						<label for="name">Nome do Relatório PBI</label>
						<InputText :disabled="visible" id="name" v-model.trim="report.name" required="true" :class="{'p-invalid': submitted && !report.name}" />
					</div>
					<div class="field col-12 md:col-12">
						<label for="namemenu">Nome do Relatório no Site</label>
						<InputText id="namemenu" v-model.trim="report.namemenu" required="true" autofocus :class="{'p-invalid': submitted && !report.namemenu}" />
					</div>
					<div class="flex w-full">
						<div class="flex flex-column w-4">
							<div class="field-checkbox mb-3 ml-3">
								<InputSwitch v-model="switchValue" />
								<label for="active">Ativo</label>
							</div>
							<div class="field-checkbox mb-3 ml-3">
								<InputSwitch v-model="switchPhone" />
								<label for="phone">Para Celular</label>
							</div>
							<div class="field-checkbox mb-3 ml-3">
								<InputSwitch v-model="switchFilters" />
								<label for="filter">Painel de Filtros</label>
							</div>
							<div class="field-checkbox mb-3 ml-3">
								<InputSwitch v-model="switchNav" />
								<label for="filter">Abas de Navegação</label>
							</div>
						</div>
					</div>
					<template #footer>
						<Button label="Cancelar" icon="pi pi-times" class="p-button-text" @click="hideEditReport"/>
						<Button label="Salvar" icon="pi pi-check" class="p-button-text" @click="saveReport" />
					</template>
				</Dialog>
				<Dialog v-model:visible="deleteReportsDialog" :style="{width: '600px'}" header="Confirmação" :modal="true">
					<div class="flex align-items-center justify-content-center">
						<i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
						<span v-if="report">A exclusão do Relatório <b>{{report.namemenu}}</b> só pode ser feita
									pelo site PowerBi Service! Por aqui só conseguimos Inativar o Relatório <b>{{report.namemenu}}</b>! <br>
									Deseja Inativar o Relatório <b>{{report.namemenu}}</b>? </span>
					</div>
					<template #footer>
						<Button label="Não" icon="pi pi-times" class="p-button-text" @click="hideDeleteDialog"/>
						<Button label="Sim" icon="pi pi-check" class="p-button-text" @click="deleteReport" />
					</template>
				</Dialog>
				<Dialog v-model:visible="viewReport" :style="{width: '90vw'}" :header="headerReportDialog" :modal="true">
					<ReportInDialog :reports="viewReportSelected"/>
					<template #footer>
						<Button label="Fechar" icon="pi pi-times" class="p-button-text" @click="hideEditReport"/>
					</template>
				</Dialog>
				<Dialog v-model:visible="syncChoice" :style="{width: '50vw'}" :header="'Escolha a Workspace que deseja Sincronizar!'" :modal="true">
					<MultiSelect class="w-full" v-model="selectedWorkspaces" :options="workspaces" optionLabel="name" placeholder="Selecione uma Workspace" />
					<template #footer>
						<Button label="Fechar" icon="pi pi-times" class="p-button-text" @click="closeChoiceSync"/>
						<Button label="Sincronizar" icon="pi pi-sync" class="p-button-text" @click="syncChoiceReports"/>
					</template>
				</Dialog>
			</div>
		</div>
	</div>
</template>

<script>
import router from '../../router';
import {api} from '../../service/services';
import {FilterMatchMode} from 'primevue/api';
import ReportInDialog from '../../components/Embeded/ReportInDialog.vue'
export default {
    name: 'ConfigReports',
	components:{
		ReportInDialog
	},
	data(){
		return {
			view_config_reports: false,
			deleteReportsDialog: false,
			selectedReports: false,
			reports: null,
			workspaces: {},
			selectedWorkspaces: null,
			report: {},
			filters: {},
			submitted: false,
			visible: false,
			switchValue: false,
			switchPhone: false,
			switchFilters: false,
			switchFinanceiro: false,
			reportDialog: false,
			loading: false,
			viewReport: false,
			headerReportDialog: "",
			viewReportSelected: [],
			syncChoice: false
		}
	},

	async created() { 
		await this.busca()
		this.initFilters()
		await this.getReport()
		},
	
	methods:{

		async busca(){
			const response = JSON.parse(localStorage.getItem("pra"))
			const data = response.filter(
				(arry) => "view_config_report".includes(arry)
			)
			if (data.length > 0){
				this.view_config_reports = true
			} else {
				router.push("/error");
			}
		},
		async syncChoiceReports(){
			try{
				this.loading = true
				const token = await api.get("token");
				const tmj = token.data
				
				this.selectedWorkspaces.forEach(async workspace => {
					await api.post("datasets/syncUnique", {
						tmj: tmj,
						workspace: workspace.id
					})

					await api.post("reports/syncUnique", {
						tmj: tmj,
						workspace: workspace.id
					})	
				});

				this.$toast.add({severity:'success', summary: 'Sucesso', detail: 'Sincronismo feito com Sucesso', life: 3000});
				this.loading = false
				this.closeChoiceSync()
			}catch (err) {
				this.loading = false
				this.$toast.add({severity:'error', summary: 'Erro', detail: 'Erro no Sincronismo ' + err, life: 3000});
			}
		},

		initFilters() {
            this.filters = {
                'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
            }
        },

		async choiceSync(){
			this.loading = true
			this.selectedWorkspaces = null
			const response = await api.get("workspaces");
			const data = await response.data;
			this.workspaces = data
			this.loading = false
			this.syncChoice = true
		},

		closeChoiceSync(){
			this.syncChoice = false
			this.workspaces = null
		},

		async getReport(){
			this.loading = true
			const response = await api.get("reports");
			const data = await response.data;
			this.reports = data
			this.loading = false
		},
		editReport(report){
			this.reportDialog = true,
			this.visible = true
			this.report = report
			if(report.active === 1){
				this.switchValue = true
			}
			if(report.phone === 1){
				this.switchPhone = true
			}
			if(report.filters === 1){
				this.switchFilters = true
			}
			if(report.nav === 1){
				this.switchNav = true
			}
		},
		openReport(report){
			this.viewReportSelected = [{report: report}]
			this.viewReport = true
			this.headerReportDialog = report.namemenu
		},

		hideEditReport(){
			this.reportDialog = false
			this.visible = false
			this.switchValue = false
			this.switchPhone = false
			this.switchFilters = false
			this.switchNav = false
			this.report = {}
			this.headerReportDialog = ""
			this.viewReport = false
			this.viewReportSelected = []
		},
		async saveReport(){
			this.submitted = true
			if(this.switchValue === true){
				this.report.active = 1
			}else{
				this.report.active = 0
			}
			if(this.switchPhone === true){
				this.report.phone = 1
			}else{
				this.report.phone = 0
			}
			if(this.switchFilters === true){
				this.report.filters = 1
			}else{
				this.report.filters = 0
			}
			if(this.switchNav === true){
				this.report.nav = 1
			}else{
				this.report.nav = 0
			}
			try{
				await api.put("reports/" + this.report.id, {
					namemenu: this.report.namemenu,
					active: this.report.active,
					phone: this.report.phone,
					filters: this.report.filters,
					nav: this.report.nav
				})
				this.hideEditReport()
				this.submitted = false
				this.reports = null
				this.getReport()
				this.$toast.add({severity:'success', summary: 'Sucesso', detail: 'Relatório Atualizado com Sucesso', life: 3000});
			}catch (err){
				this.hideEditReport()
				this.submitted = false
				this.reports = null
				this.getReport()
				this.$toast.add({severity:'error', summary: 'Erro', detail: 'Erro ao Atualizar dados do Relatório ' + err, life: 3000});
			} 
			
		},
		async deleteReport(){
			try{
				await api.put("reports/" + this.report.id, {
					namemenu: this.report.namemenu,
					active: 0,
					phone: this.report.phone,
					filters: this.report.filters,
					nav: this.report.nav
				})
				this.hideDeleteDialog()
				this.submitted = false
				this.reports = null
				this.getReport()
				this.$toast.add({severity:'success', summary: 'Sucesso', detail: 'Relatório Atualizado com Sucesso', life: 3000});
			}catch (err){
				this.hideDeleteDialog()
				this.submitted = false
				this.reports = null
				this.getReport()
				this.$toast.add({severity:'error', summary: 'Erro', detail: 'Erro ao Atualizar dados do Relatório ' + err, life: 3000});
			} 
			
		},

		deleteDialog(report){
			this.report = report
			this.deleteReportsDialog = true
		},

		hideDeleteDialog(){
			this.report = {}
			this.deleteReportsDialog = false
		}
	}
}
</script>