import axios from 'axios';
  
//const url = 'http://localhost:3333/api/v1';

const nomedaempresa = process.env.VUE_APP_EMPRESA_API
const url = "https://api"+nomedaempresa+".relatoriosinteligentes.com/api/v1";

const axiosInstance = axios.create({
  baseURL: url,
});

axiosInstance.interceptors.request.use(
  function (config) {
    const token = window.localStorage.token_ri;
    if (token) {
      config.headers.Authorization = token;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  },
);

export const api = {
  get(endpoint) {
    return axiosInstance.get(endpoint);
  },
  post(endpoint, body) {
    return axiosInstance.post(endpoint, body);
  },
  put(endpoint, body) {
    return axiosInstance.put(endpoint, body);
  },
  patch(endpoint, body) {
    return axiosInstance.patch(endpoint, body);
  },
  delete(endpoint) {
    return axiosInstance.delete(endpoint);
  },
  login(body) {
    return axios.post(url, body);
  },
  validateToken() {
    return axiosInstance.post(url + '/token/validate');
  },
  getUrl() {
    return url;
  },
  google(){
    const tagmanager = function(w,d,s,l,i) {
       w[l]=w[l]||[];w[l].push({
         'gtm.start': new Date().getTime(),event:'gtm.js'});
       var f=d.getElementsByTagName(s)[0],j=d
              .createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode
              .insertBefore(j,f);
       }(window,document,'script','dataLayer','GTM-MZ657XS')
       return tagmanager
    },
    
  async getUserCurrent(){
    const response = await api.get("users/" + window.localStorage.getItem("id_ri"))
    const data = response.data
    return data
    
  },

  async getClient(){
    const response = await api.get("client")
    const data = response.data
    return data
  },

 async banks(){
    const response = await axios.get("https://brasilapi.com.br/api/banks/v1")
    const data = response.data
    return data
  },
  
  async buscaEmpresa(cnpj){
    const response = await axios.get(`https://brasilapi.com.br/api/cnpj/v1/${cnpj}`)
    const data = response.data
    return data
  },

  async buscaCEP(cep){
    const response = await axios.get(`https://brasilapi.com.br/api/cep/v1/${cep}`)
    const data = response.data
    return data
  },

};

