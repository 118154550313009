<template>
  <div>
    <Toast ref="alert" />
    <div
      class="surface-ground flex align-items-center imgback justify-content-center min-h-screen min-w-screen overflow-hidden">
      <div class="flex flex-column align-items-center justify-content-center">
        <div
          style="border-radius: 56px; padding: 0.3rem; background: linear-gradient(180deg, #00ACA9 10%, rgba(41, 219, 101, 0) 30%)">
          <div class="w-full surface-card py-8 px-5 sm:px-8" style="border-radius: 53px">
            <div class="text-center mb-5">
              <img :src="this.urlimage" alt="Image" height="150" class="mb-3" />
            </div>

            <div>
              <label for="email1" class="block text-900 text-xl font-medium mb-2">Email</label>
              <InputText id="email1" type="text" placeholder="Digite seu E-mail" class="w-full md:w-30rem mb-5"
                style="padding: 1rem" v-model="user.email" v-on:keyup.enter="forgetPass" />

              <div class="flex align-items-center justify-content-between mb-5 gap-5">
                <a class="font-medium no-underline ml-2 text-right cursor-pointer"
                  style="color: var(--primary-color)" v-on:click="login">Voltar para o Login</a>
              </div>
              <Button v-if="!loading" label="Resetar Senha" class="w-full p-3 text-xl" style="background: #00ACA9" v-on:click="forgetPass"></Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { api } from '../service/services';
export default {
name: "Forgetpass",
  
  data() {
    return {
      loading: false,
      urlimage: api.getUrl() + "/images/client/" + process.env.VUE_APP_EMPRESA_IMAGE,
      user: {
        email: "",
      },
    };
  },
 
  methods: {
    login() {
      this.$router.replace({ name: "Home" });
    },

   async forgetPass() {
      try {
        this.loading = true;
        
         if (this.user.email === "") {
          let inputs = document.querySelectorAll("input");
          inputs[0].style.borderColor = "red";
          this.$toast.add({severity:'error', summary: 'Erro', detail: 'Preencha todos os campos', life: 3000});
          this.loading = false;
          return;
        }

        const response = await api.put("forgetpass", {
          email: this.user.email,
        });

        const data = await response.data;

        if (data.status === "error") {
          this.$toast.add({severity:'error', summary: 'Erro', detail: data.message, life: 3000});
          this.loading = false;
          return;
        }

        this.$toast.add({severity:'success', summary: 'Sucesso', detail: 'Senha alterada! Email enviado com nova Senha!', life: 3000});
        this.loading = true;
        this.user.email = ""
        this.login()
    
      } catch (err) {
        this.$toast.add({severity:'error', summary: 'Erro', detail: 'Erro ao efetuar o Login ' + err, life: 3000});
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped>
body{
  margin: 0;
}

.imgback {
  height: 100vh;
  width: 100%;
  margin: 0px;
  padding: 0px;
  background-image: url("../assets/background.jpeg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  background-attachment: scroll;
}


</style>