<template>
	<div class="grid" v-show="view_config_dataflows">
		<div class="col-12" >
			<div class="card">
				<Toast/>
				<Toolbar class="mb-4">
					<template v-slot:start>
						<div class="my-2">
							<Button v-if="!visible" label="Sincronizar" icon="pi pi-sync" class="p-button-success mr-2" @click="escolha" />
							<Button v-else :disabled="true" label="Sincronizando..." icon="pi pi-sync" class="p-button-success mr-2"  />
							<!-- <Button label="Inativar" icon="pi pi-trash" class="p-button-danger" @click="deleteDataflowsDialog = true" :disabled="!selectedDataflows || !selectedDataflows.length" /> -->
						</div>
					</template>

					<!-- <template v-slot:end>
						<FileUpload mode="basic" accept="image/*" :maxFileSize="1000000" label="Import" chooseLabel="Import" class="mr-2 inline-block" />
						<Button label="Export" icon="pi pi-upload" class="p-button-help" @click="exportCSV($event)"  />
					</template> -->
				</Toolbar>
				<DataTable ref="dt" :value="dataflows" v-model:selection="selectedDataflows" dataKey="id" :paginator="true" :rows="10" :filters="filters" :rowHover="true" class="p-datatable-gridlines" :loading="loading"
							paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" :rowsPerPageOptions="[5,10,25]"
							currentPageReportTemplate="Mostrando {first} de {last} no total de {totalRecords} Dataflows" responsiveLayout="scroll">
					<template #header>
						<div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
							<h5 class="m-0">Gerenciar Fluxo de Dados</h5>
							<span class="block mt-2 md:mt-0 p-input-icon-left">
                                <i class="pi pi-search" />
                                <InputText v-model="filters['global'].value" placeholder="Search..." />
                            </span>
						</div>
					</template>
					<template #empty>
                        Não existe Dataflows Cadastrados.
                    </template>
					<Column selectionMode="multiple" headerStyle="width: 3rem"></Column>
					<Column field="namemenu" header="Nome" :sortable="true" headerStyle="width:25%; min-width:20rem;">
						<template #body="slotProps">
							<span class="p-column-title">Name</span>
							{{slotProps.data.namemenu}}
						</template>
					</Column>
					<Column field="active" header="Status" :sortable="true" headerStyle="width:15%; min-width:10rem;">
						<template #body="slotProps">
							<span class="p-column-title">Active</span>
							<Tag v-if="slotProps.data.active === 1" class="mr-2" icon="pi pi-check" severity="success" value="Ativo"></Tag>
							<Tag v-else class="mr-2" icon="pi pi-times" severity="danger" value="Inativo"></Tag>
						</template>
					</Column>
					<Column field="createAt" header="Criado em" :sortable="true" headerStyle="width:25%; min-width:20rem;">
						<template #body="slotProps">
							<span class="p-column-title">createdAt</span>
							{{slotProps.data.createdAt}}
						</template>
					</Column>
					<Column>
						<template #body="slotProps">
							<Button icon="pi pi-pencil" class="p-button-rounded p-button-success mr-2" @click="editDataflow(slotProps.data)" />
							<Button icon="pi pi-trash" class="p-button-rounded p-button-warning mt-2" @click="deleteDialog(slotProps.data)" />
						</template>
					</Column>
				</DataTable>
				<Dialog v-model:visible="dataflowDialog" :style="{width: '700px'}" header="Editar Fluxo de Dados" :modal="true" class="p-fluid">
					<div class="field col-12 md:col-12">
						<label for="name">Nome do Fluxo de Dados PBI</label>
						<InputText :disabled="visible" id="name" v-model.trim="dataflow.name" required="true" :class="{'p-invalid': submitted && !dataflow.name}" />
					</div>
					<div class="field col-12 md:col-12">
						<label for="namemenu">Nome do Fluxo de Dados no Site</label>
						<InputText id="namemenu" v-model.trim="dataflow.namemenu" required="true" autofocus :class="{'p-invalid': submitted && !dataflow.namemenu}" />
					</div>
					<div class="field-checkbox mb-3">
					<div class="field-checkbox mb-3">
						<InputSwitch v-model="switchValue" />
						<label for="active">Ativo</label>
					</div>
					</div>
					<template #footer>
						<Button label="Cancelar" icon="pi pi-times" class="p-button-text" @click="hideEditDataflow"/>
						<Button label="Salvar" icon="pi pi-check" class="p-button-text" @click="saveDataflow" />
					</template>
				</Dialog>
				<Dialog v-model:visible="choiceWorkspace" :style="{width: '450px'}" header="Escolhendo a Workspace" :modal="true" class="p-fluid">
					<div class="field col-12 md:col-12">
						<label for="permissions">Selecione uma Workspace</label>
						<Dropdown id="id" v-model="dropdownValue" :options="dropdownValues" optionLabel="name" placeholder="Workspace Cadastradas" required="true" />
					</div>
					<template #footer>
						<Button label="Cancelar" icon="pi pi-times" class="p-button-text" @click="hideChoiceWorkspace"/>
						<Button label="Criar" icon="pi pi-check" class="p-button-text" @click="createDataflows" />
						<Button label="Sincronizar" icon="pi pi-sync" class="p-button-text" @click="syncDataflows" />
					</template>
				</Dialog>
				<Dialog v-model:visible="deleteDataflowsDialog" :style="{width: '600px'}" header="Confirmação" :modal="true">
					<div class="flex align-items-center justify-content-center">
						<i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
						<span v-if="dataflow">A exclusão do Fluxo de Dados <b>{{dataflow.namemenu}}</b> só pode ser feita
									pelo site PowerBi Service! Por aqui só conseguimos Inativar o Fluxo de Dados <b>{{dataflow.namemenu}}</b>! <br>
									Deseja Inativar o Fluxo de Dados <b>{{dataflow.namemenu}}</b>? </span>
					</div>
					<template #footer>
						<Button label="Não" icon="pi pi-times" class="p-button-text" @click="hideDeleteDialog"/>
						<Button label="Sim" icon="pi pi-check" class="p-button-text" @click="deleteDataflow" />
					</template>
				</Dialog>
			</div>
		</div>
	</div>
</template>

<script>
import router from '../../router';
import {api} from '../../service/services';
import {FilterMatchMode} from 'primevue/api';
export default {
    name: 'ConfigDataflows',
	data(){
		return {
			view_config_dataflows: false,
			deleteDataflowsDialog: false,
			selectedDataflows: false,
			dataflows: null,
			dataflow: {},
			submitted: false,
			dataflowDialog: false,
			visible: false,
			switchValue: false,
			choiceWorkspace: false,
			dropdownValues: [],
			dropdownValue: {},
			filters: {},
			loading: false,
		}
	},

	created() { 
		this.busca()
		this.initFilters()
		this.getDataflow()
		this.getWorkspace()
		},
	
	methods:{
		busca(){
			const response = JSON.parse(localStorage.getItem("pra"))
			const data = response.filter(
				(arry) => "view_config_dataflow".includes(arry)
			)
			if (data.length > 0){
				this.view_config_dataflows = true
			} else {
				router.push("/error");
			}
		},
		async syncDataflows(){
			try{
				const token = await api.get("token");
				const tmj = token.data

				await api.post("dataflows/sync", {
					tmj: tmj
				})
				this.submitted = false
				this.dataflows = null
				this.getDataflow()
				this.choiceWorkspace = false
				this.visible = false
				this.$toast.add({severity:'success', summary: 'Sucesso', detail: 'Sincronismo feito com Sucesso', life: 3000});
			}catch (err) {
				this.submitted = false
				this.visible = false
				this.choiceWorkspace = false
				this.$toast.add({severity:'error', summary: 'Erro', detail: 'Erro no Sincronismo ' + err, life: 3000});
			}
		},
		async createDataflows(){
			try{
				const token = await api.get("token");
				const tmj = token.data

				await api.post("dataflows", {
					workspace_id: this.dropdownValue.id ,
					tmj: tmj
				})
				this.submitted = false
				this.dataflows = null
				this.getDataflow()
				this.choiceWorkspace = false
				this.visible = false
				this.$toast.add({severity:'success', summary: 'Sucesso', detail: 'Sincronismo feito com Sucesso', life: 3000});
			}catch (err) {
				this.submitted = false
				this.choiceWorkspace = false
				this.visible = false
				this.$toast.add({severity:'error', summary: 'Erro', detail: 'Erro no Sincronismo ' + err, life: 3000});
			}

		},
		
		initFilters() {
            this.filters = {
                'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
            }
        },
		async getDataflow(){
			this.loading = true
			const response = await api.get("dataflows");
			const data = await response.data;
			this.dataflows = data
			this.loading = false
		},
		async getWorkspace(){
			const response = await api.get("workspaces");
			const data = await response.data;
			const ativo = data.filter(
				(item) => item.active === 1
			)
			this.dropdownValues = ativo
		},
		hideChoiceWorkspace(){
			this.submitted = false
			this.dropdownValue = {}
			this.choiceWorkspace = false
			this.visible = false
		},
		escolha(){
			this.visible = true
			this.choiceWorkspace = true
			const workspace = this.dropdownValues.filter(
				(item) => item.default === true
			)
			this.dropdownValue = workspace[0]
		},
		editDataflow(dataflow){
			this.dataflowDialog = true,
			this.submitted = true
			this.visible = true
			this.dataflow = dataflow
			if(dataflow.active === 1){
				this.switchValue = true
			}
		},
		hideEditDataflow(){
			this.dataflowDialog = false
			this.submitted = false
			this.switchValue = false
			this.visible = false
			this.dataflow = {}
		},
		async saveDataflow(){
			this.submitted = true
			if(this.switchValue === true){
				this.dataflow.active = 1
			}else{
				this.dataflow.active = 0
			}
			try{
				await api.put("dataflows/" + this.dataflow.id, {
					namemenu: this.dataflow.namemenu,
					active: this.dataflow.active
				})
				this.hideEditDataflow()
				this.submitted = false
				this.dataflows = null
				this.getDataflow()
				this.$toast.add({severity:'success', summary: 'Sucesso', detail: 'Fluxo de Dados Atualizado com Sucesso', life: 3000});
			}catch (err){
				this.hideEditDataflow()
				this.submitted = false
				this.dataflows = null
				this.getDataflow()
				this.$toast.add({severity:'error', summary: 'Erro', detail: 'Erro ao Atualizar dados do Fluxo de Dados ' + err, life: 3000});
			} 
			
		},
		async deleteDataflow(){
			try{
				await api.put("dataflows/" + this.dataflow.id, {
					namemenu: this.dataflow.namemenu,
					active: 0,
				})
				this.hideDeleteDialog()
				this.submitted = false
				this.dataflows = null
				this.getDataflow()
				this.$toast.add({severity:'success', summary: 'Sucesso', detail: 'Fluxo de Dados Atualizado com Sucesso', life: 3000});
			}catch (err){
				this.hideDeleteDialog()
				this.submitted = false
				this.dataflows = null
				this.getDataflow()
				this.$toast.add({severity:'error', summary: 'Erro', detail: 'Erro ao Atualizar dados do Fluxo de Dados ' + err, life: 3000});
			} 
			
		},

		deleteDialog(dataflow){
			this.dataflow = dataflow
			this.deleteDataflowsDialog = true
		},

		hideDeleteDialog(){
			this.dataflow = {}
			this.deleteDataflowsDialog = false
		}
	}
}
</script>